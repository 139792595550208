import React from 'react';

export const LoginFailedPage = (props: any) => {
    return (
        <div className="login-failed-page">
            <div className="login-failed-content">
                <img loading="lazy" className="login-photo-1" src="https://khicindekocvar.blob.core.windows.net/cdn/prod/footer-koc-logo.png" />
                <p className="title" id="transition-login-description">
                    {props.message}
                </p>
            </div>
        </div>
    );
};

export default LoginFailedPage;
