import { configureStore } from '@reduxjs/toolkit';

import { TypedUseSelectorHook, useSelector, useDispatch } from 'react-redux';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import localizationReducer from './features/localization/localizationSlice';
import profileReducer from './features/profile/profileSlice';
import usersReducer from './features/user/usersSlice';
import hrReducer from './features/hr/hrSlice';
import hrDetailsReducer from './features/hrDetail/hrDetailsSlice';

export const store = configureStore({
    reducer: {
        localization: localizationReducer,
        hr:hrReducer,
        hrDetails:hrDetailsReducer,
        profile: profileReducer,
        users: usersReducer,
    },
});
setupListeners(store.dispatch);


export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () =>  useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

