import React, { useEffect, useState } from 'react';
// import { isFirefox, isChrome, isEdge, isSafari } from 'react-device-detect';
import { useDispatch } from 'react-redux';

// import { useDispatch } from 'react-redux';
import NotSupportPage from '../../../pages/notSupportPage';
import { getLocalizationWords } from '../../../store/features/localization/localizationSlice';
// import { getLocalizationWords } from '../../../store/features/localization/localizationSlice';
import Cookies from '../../cookies';
import PrivateRoute from '../../private-route';
import Footer from './footer';
import Header from './header';

const Layout2 = ({ component: Component, ...rest }: any) => {
    const [windowSize, setWindowSize] = useState({ width: undefined });
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getLocalizationWords());
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
            });
        }
        setWindowSize({ width: window.innerWidth });
        window.addEventListener('resize', handleResize);
        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <PrivateRoute
            {...rest}
            render={props => (
                <>
                    {windowSize.width < 800 
                    ? (
                        <>
                            <NotSupportPage />
                        </>
                    ) : (
                        <>
                            <Header />
                            <Component {...props} />
                            <Cookies />
                            <Footer />
                        </>
                    )}
                </>
            )}
        />
    );
};

export default Layout2;
