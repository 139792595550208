import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import React from 'react';

import { get } from '../../../core/client';
import { useAppSelector } from '../../../store';

export default function ModalDialog(props: any) {
    const { words } = useAppSelector(state => state.localization);

    const handleClose = () => {
        props.setOpen(false);
    };

    const onClickSendMail = async () => {
        await get<any>(`/users/send-profile-mail?userId=` + props.userId);
        props.setOpen(false)
    };

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className="modal-06"
            open={props.open}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
            onClose={handleClose}>
            <Fade in={props.open}>
                <div className="modal-content">
                    <p className="title" id="transition-modal-description">
                        {props.message}
                    </p>
                    <div className="button-wrap">
                        <button className="button-01" type="button" onClick={handleClose}>
                        {words && words['WEB.No']}
                        </button>
                        <button className="button-02" onClick={() => onClickSendMail()} type="button">
                        {words && words['WEB.Yes']}
                        </button>
                    </div>
                </div>
            </Fade>
        </Modal>
    );
}
