import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import Spinner from '../../components/ui/spinner';
import { useAppSelector } from '../../store';
import { getLocalStorageItem } from '../../core/utility';
import { getCookieDetailText } from '../../store/features/user/usersSlice';

const CookieDetailText = () => {
    const dispatch = useDispatch();
    const { CookieDetailText }: any = useAppSelector(state => state.users);
    const culture = getLocalStorageItem('SelectedLanguage');

    useEffect(() => {
        dispatch(getCookieDetailText());
    }, [culture]);

    return CookieDetailText?.text ? (
        <div className="c-wrapper">
            <div dangerouslySetInnerHTML={{ __html: CookieDetailText?.text }}></div>
        </div>
    ) : (
        <div className="c-wrapper">
            <Spinner></Spinner>
        </div>
    );
};

export default CookieDetailText;