import React from 'react';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import { store } from '../src/store';
import App from './App';
import './_assets/styles/main.scss';
import './components/styles/Base.scss';
import './core/i18n';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,

    document.getElementById('root'),
);

serviceWorker.unregister();
