interface IToBeKoc {
    isHomePage?: boolean;
    isDetailPage?: boolean;
    details: IDetail
}

interface IDetail {
    loading?: boolean;
    items?: IDetailItems;
}

interface IDetailItems {
    loading?: boolean;
    name?: string;
    ikCatiLeftMenuWidgetMedias?: any[];
}
interface IAppStateReducer {
    iframeUrl?: string;
    imageData?: any[];
    activeImage?: number;
    openAppDetailModal?: boolean;
}

const appStateReducer = (prev: IAppStateReducer, next: Partial<IAppStateReducer>): IAppStateReducer => {
    return { ...prev, ...next };
};

export type { IToBeKoc };
export { appStateReducer };
