import React, { useEffect, useState, FC } from 'react';
import { isMacOs } from 'react-device-detect';

import { userId } from '../../_helpers/useful-functions';
import { IInformationContent } from '../../models/components/details/InformationContent';

const InformationContent: FC<IInformationContent> = ({ data, isToBeKoc }) => {
    const [windowSize, setWindowSize] = useState({ width: undefined });

    useEffect(() => {
        const contentContainerEl = document?.getElementById('information-content');
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
            });
        }
        setWindowSize({ width: window.innerWidth });
        window.addEventListener('resize', handleResize);
        handleResize();
        if (userId) {
            contentContainerEl.classList?.add('f-login');
        }

        document?.getElementById('root').addEventListener('scroll', onScrolling);

        return () => {
            document?.getElementById('root').removeEventListener('scroll', onScrolling);
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const onScrolling = e => {
        const contentContainerEl = document?.getElementById('information-content');
        if (contentContainerEl) {
            const informationImageEl = document?.getElementById('information-image');
            const currentScrollTopValue = e?.target?.scrollTop;

            if (userId) {
                informationImageEl.classList?.remove('add-position-absolute');
                contentContainerEl.classList?.remove('f-add-height');
                if (
                    currentScrollTopValue >= 200 &&
                    !informationImageEl.classList?.contains('add-position-absolute-login') &&
                    !contentContainerEl.classList?.contains('f-add-height-login')
                ) {
                    informationImageEl.classList?.add('add-position-absolute-login');
                    contentContainerEl.classList?.add('f-add-height-login');
                } else if (
                    currentScrollTopValue < 200 &&
                    informationImageEl.classList?.contains('add-position-absolute-login') &&
                    contentContainerEl.classList?.contains('f-add-height-login')
                ) {
                    informationImageEl.classList?.remove('add-position-absolute-login');
                    contentContainerEl.classList?.remove('f-add-height-login');
                }
            } else if (!userId) {
                informationImageEl.classList?.remove('add-position-absolute-login');
                contentContainerEl.classList?.remove('f-add-height-login');
                if (
                    currentScrollTopValue >= 150 &&
                    !informationImageEl.classList?.contains('add-position-absolute') &&
                    !contentContainerEl.classList?.contains('f-add-height')
                ) {
                    informationImageEl.classList?.add('add-position-absolute');
                    contentContainerEl.classList?.add('f-add-height');
                } else if (
                    currentScrollTopValue < 150 &&
                    informationImageEl.classList?.contains('add-position-absolute') &&
                    contentContainerEl.classList?.contains('f-add-height')
                ) {
                    informationImageEl.classList?.remove('add-position-absolute');
                    contentContainerEl.classList?.remove('f-add-height');
                }
            }
        }
    };

    return (
        <>
            {isToBeKoc && (
                <div className="section-04 f-to-be-Koc-section" id="information-content">
                    <img
                        loading="lazy"
                        className={userId ? `c-item-image active ${isMacOs ? 'macOs' : ''}` : `c-item-image f-no-login ${isMacOs ? 'macOs' : ''}`}
                        src={data?.item?.image}
                        id="information-image"
                    />
                </div>
            )}

            <div className={isToBeKoc ? 'section-07' : 'section-04'} id="information-content">
                {!isToBeKoc && (
                    <img
                        loading="lazy"
                        className={userId ? `c-item-image active ${isMacOs ? 'macOs' : ''}` : `c-item-image f-no-login ${isMacOs ? 'macOs' : ''}`}
                        src={windowSize.width < 768 ? data?.items?.mobileResponsiveImage : data?.items?.image}
                        id="information-image"
                    />
                )}
                <div className="c-wrapper">
                    <section
                        className={isMacOs ? 'c-container-01 isMacOs' : isToBeKoc ? 'c-container-01 f-wrapper-width' : 'c-container-01'}
                        id="content-container">
                        <div className="c-item-01">{isToBeKoc ? data?.item?.title : data?.items?.name}</div>
                        <div
                            dangerouslySetInnerHTML={{ __html: isToBeKoc ? data?.item?.body : data?.items?.body }}
                            className={isToBeKoc ? 'c-item-03 f-overflow-visible' : 'c-item-03'}></div>
                    </section>
                </div>
            </div>
        </>
    );
};

export default InformationContent;
