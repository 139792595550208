interface ITeams {
    id?: string;
    sportTeamId?: string;
    name?: string;
    isOther?: boolean;
    teamName?: string;
}

interface IProfile {
    setUserInformation?: React.Dispatch<React.SetStateAction<any>>;
    setIsCelebratingSpecialDay?: React.Dispatch<React.SetStateAction<boolean>>;
    isEdit: boolean;
    isTouched: boolean;
}

const teamsInitialState = {
    id: null,
    sportTeamId: null,
    name: '',
    isOther: false,
    teamName: '',
};

export type { ITeams, IProfile };

export {teamsInitialState}
