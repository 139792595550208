function trackButtonClick3() {
    gtag('event', 'ana_sayfa_ziyareti_web3_test',  {
        event_category: 'User Interaction',
        event_label: 'Button Clicked',
    });

    // window?.dataLayer?.push({
    //     event: appName,
    //     // pagePath: 'https://www.googleanalytics.dev/pancakes',
    //     // pageTitle: 'Pancake Event Signup',
    //     // visitorType: 'customer',
    // });
}

function gtag(arg0: string, arg1: string, arg2: { event_category: string; event_label: string }) {
    // throw new Error('Function not implemented.');

        window?.dataLayer?.push({
        event: 'web_test_test',
        // pagePath: 'https://www.googleanalytics.dev/pancakes',
        // pageTitle: 'Pancake Event Signup',
        // visitorType: 'customer',
    });
}

export { trackButtonClick3 };
