import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { get } from '../../../core/client';
import { LOCAL_STORAGE } from '../../../core/constants';
import { getLocalStorageItem, setLocalStorageItem } from '../../../core/utility';

const token = getLocalStorageItem(LOCAL_STORAGE.ACCESS_TOKEN);

export const getUserInfo: any = createAsyncThunk('user/userInfo', async () => {
    const userData = token && (await get<any>('users/user-profile-info-light'));
    return userData;
});

export const getUserClaims: any = createAsyncThunk('user/userClaims', async () => {
    const userClaims = await get<any>(`/users/claims`);
    return userClaims;
});

export const getUserKvkk: any = createAsyncThunk('user/userKvkk', async () => {
    const userKvkk = await get<any>(`/kvkk/get-kvkk?appId=2`);
    return userKvkk;
});

export const getKvkkDetailText: any = createAsyncThunk('kvkk/KvkkDetailText', async () => {
    const kvkkText = await get<any>(`/kvkk/get-kvkk-page`);
    return kvkkText;
});

export const getCookieDetailText: any = createAsyncThunk('cookie/CookieDetailText', async () => {
    const cookieText = await get<any>(`/kvkk/get-cookie-kvkk`);
    return cookieText;
});
export const getGlobalLoginId: any = createAsyncThunk('hr/getGlobalLoginId', async () => {
    const globalLoginId = await get<any>(`/hr/get-global-loginId`);
    return globalLoginId;
});
export const getPrivacyPreference: any = createAsyncThunk('hr/getPrivacyPreference', async () => {
    const privacyPreference = await get<any>(`hr/get-privacy-preference`);
    return privacyPreference;
});
//hr/get-privacy-preference

const usersSlice = createSlice({
    name: 'users',
    initialState: {
        info: {
            userInfo: null,
            userLoading: false,
        },
        myUserInfo: {
            token: '',
        },
        newUserInfo: {
            token: '',
        },
        userClaims: {
            loading: true,
            items: [],
        },
        Kvkk: {
            loading: true,
            items: [],
        },
        KvkkDetailText: {
            loading: true,
            text: '',
        },
        CookieDetailText: {
            loading: true,
            text: '',
            errorMessage: '',
        },
        cookiesOptions: {
            mandatory: true,
            googleAnalist: false,
            googleAdsense: false,
            facebookPixel: false,
        },
        openCookiesModal: false,
        openAccordionCookiesOptions: false,
        privacyPreference: {
            loading: true,
            items: [],
        },
        globalLogin: {
            loginId: null,
            loading: true,
        },
    },
    reducers: {
        setMyUserInfo(state) {
            const REFRESH_TOKEN = JSON.parse(localStorage.getItem('REFRESH_TOKEN'));
            state.myUserInfo.token = REFRESH_TOKEN;
        },
        setNewUserInfo(state, action) {
            state.newUserInfo.token = action.payload;
        },
        setLanguageUptade(state, action) {
            setLocalStorageItem('SelectedLanguage', action.payload);
        },
        setCookieOptions(state, action) {
            state.cookiesOptions = action.payload;
        },
        setOpenCookiesModal(state, action) {
            state.openCookiesModal = action.payload;
            setLocalStorageItem('OpenCookiesModal', action.payload);
        },
        setOpenAccordionCookiesOptions(state, action) {
            state.openAccordionCookiesOptions = action.payload;
        },
    },
    extraReducers: builder => {
        builder.addCase(getUserInfo.pending, (state, action: PayloadAction<any>) => {
            state.info.userLoading = true;
        });
        builder.addCase(getUserInfo.fulfilled, (state, action: PayloadAction<any>) => {
            state.info.userInfo = action?.payload?.isSuccessful ? action.payload.result : null;
            state.info.userLoading = false;
        });
        builder.addCase(getUserClaims.fulfilled, (state, action: PayloadAction<any>) => {
            state.userClaims.items = action.payload.result.items;
        });
        builder.addCase(getUserKvkk.fulfilled, (state, action: PayloadAction<any>) => {
            state.Kvkk.items = action.payload.result;
        });
        builder.addCase(getKvkkDetailText.fulfilled, (state, action: PayloadAction<any>) => {
            state.KvkkDetailText.text = action.payload.result.text;
        });
        builder.addCase(getCookieDetailText.fulfilled, (state, action: PayloadAction<any>) => {
            state.CookieDetailText.text = action.payload.result.text;
        });
        builder.addCase(getGlobalLoginId.fulfilled, (state, action: PayloadAction<any>) => {
            state.globalLogin.loginId = action.payload.result.loginId;
            state.globalLogin.loading = false;
        });
        builder.addCase(getPrivacyPreference.fulfilled, (state, action: PayloadAction<any>) => {
            state.privacyPreference.items = action?.payload?.result?.item;
            state.privacyPreference.loading = false;
        });
    },
});
export const { setMyUserInfo, setNewUserInfo, setLanguageUptade, setCookieOptions, setOpenCookiesModal, setOpenAccordionCookiesOptions } =
    usersSlice.actions;

export default usersSlice.reducer;
