import Switch from '@material-ui/core/Switch';
import React, { useState, useEffect } from 'react';

import { IIOSwitch } from '../../models/components/ios-button';
import { useAppSelector } from '../../store';
import { getCookieValue } from '../../core/utility';

const IOSSwitch = ({
    location,
    isMandatory,
    cookie,
    cookieData,
    ...props
}: IIOSwitch) => {
    const [isChecked, setIsChecked] = useState(false);
    const { googleAnalist, googleAdsense, facebookPixel } = useAppSelector(state => state.users.cookiesOptions);
   
    const isGoogleAnalistAccepted = getCookieValue('googleAnalistAccepted')?.toLowerCase() === 'true' ?? false;
    const isGoogleAdsenseAccepted = getCookieValue('googleAdsenseAccepted')?.toLowerCase() === 'true' ?? false;
    const isFacebookPixelAccepted = getCookieValue('facebookPixelAccepted')?.toLowerCase() === 'true' ?? false;

    useEffect(() => {
        if (location === 'on') {
            if (isMandatory) {
                setIsChecked(true);
            } else if (cookieData?.displayOrder === 3 && (isGoogleAnalistAccepted || googleAnalist)) {
                setIsChecked(true);
            } else if (cookieData?.displayOrder === 4 && (isFacebookPixelAccepted || facebookPixel) && (isGoogleAdsenseAccepted || googleAdsense)) {
                setIsChecked(true);
            } else {
                setIsChecked(false);
            }
        }
        if (location === 'in') {
            if (cookie?.name === 'googleAnalist') {
                setIsChecked(isGoogleAnalistAccepted || googleAnalist);
            } else if (cookie?.name === 'googleAdsense') {
                setIsChecked(isGoogleAdsenseAccepted || googleAdsense);
            } else if (cookie?.name === 'facebookPixel') {
                setIsChecked(isFacebookPixelAccepted || facebookPixel);
            } else {
                setIsChecked(false);
            }
        }
    }, [
        location,
        isMandatory,
        isGoogleAnalistAccepted,
        googleAnalist,
        isFacebookPixelAccepted,
        facebookPixel,
        isGoogleAdsenseAccepted,
        googleAdsense,
        cookie,
    ]);

    return <Switch checked={isChecked} disableRipple {...props} />;
};

export default IOSSwitch;
