import CircularProgress from '@material-ui/core/CircularProgress';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import AppDetailCards from '../../components/details/AppDetailCards';
import AppDetailCardsResponsive from '../../components/details/AppDetailCardsResponsive';
import AppDetailSlider from '../../components/details/AppDetailSlider';
import AppDetailSliderResponsive from '../../components/details/AppDetailSliderResponsive';
import ContinueCardDiscover from '../../components/details/ContinueCardDiscover';
import ContinueDiscoverCardResponsive from '../../components/details/ContinueDiscoverCardResponsive';
import InformationContent from '../../components/details/InformationContent';
import { getLocalStorageItem } from '../../core/utility';
import { useAppSelector } from '../../store';
import { setIsChangePage } from '../../store/features/hr/hrSlice';
import { getLeftMenuList, getCardDetail } from '../../store/features/hrDetail/hrDetailsSlice';

const CardDetail = (props: any) => {
    const dispatch = useDispatch();
    const { cardDetailList } = useAppSelector(state => state.hrDetails);
    const isLeadCard = cardDetailList?.items?.cards?.length > 0;
    const culture = getLocalStorageItem('SelectedLanguage');

    useEffect(() => {
        if (!props?.match?.params?.id) return;
        if (props?.match?.params?.id) {
            dispatch(getCardDetail(props?.match?.params?.id));
        }
    }, [props?.match?.params?.id, culture]);

    useEffect(() => {
        dispatch(getLeftMenuList());
    }, [culture]);

    useEffect(() => {
        dispatch(setIsChangePage(false));
    }, []);

    return (
        <>
            {cardDetailList.loading ? (
                <div className="spinner-02">
                    <CircularProgress size={100} />
                </div>
            ) : (
                <>
                    <InformationContent data={cardDetailList} />
                    <AppDetailSlider sliderData={cardDetailList?.items} />
                    {isLeadCard && <AppDetailCards cards={cardDetailList?.items?.cards} />}
                    <AppDetailSliderResponsive data={cardDetailList} />
                    {isLeadCard && <AppDetailCardsResponsive cards={cardDetailList?.items?.cards} />}
                    <ContinueCardDiscover data={cardDetailList?.items?.cardExplorer} />
                    <ContinueDiscoverCardResponsive data={cardDetailList?.items?.cardExplorer} />
                </>
            )}
        </>
    );
};

export default CardDetail;
