import React from 'react';

import history from '../../history';

export const MobileModal = () => {
    return (
        <div className="not-support-page">
            <div className="not-support-content">
                <img
                    loading="lazy"
                    className="not-support-photo-1"
                    src="https://khicindekocvar.blob.core.windows.net/cdn/prod/footer-koc-logo.png"
                    alt="koclogo"
                />
                {history?.location?.pathname === '/profile' ? (
                    <p className="description" id="transition-modal-description">
                        Profil bilgilerini kolayca paylaşabilmen için bilgisayar tarayıcından giriş yapmanı öneririz
                    </p>
                ) : (
                    <p className="description" id="transition-modal-description">
                        İçinde Koç Var uygulamasına şimdilik mobil uygulama üzerinden erişebilirsin.
                    </p>
                )}

                {history?.location?.pathname !== '/profile' && (
                    <div className="wrapper">
                        <a href="#!" className="c-item-02" target="_blank" rel="noreferrer">
                            <img loading="lazy" className="c-item-02" src="https://khicindekocvar.blob.core.windows.net/cdn/prod/android-store.png" />
                        </a>
                        <a href="#!" className="c-item-02" target="_blank" rel="noreferrer">
                            <img loading="lazy" className="c-item-02" src="https://khicindekocvar.blob.core.windows.net/cdn/prod/app-store.png" />
                        </a>
                    </div>
                )}
            </div>
        </div>
    );
};

export default MobileModal;
