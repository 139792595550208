interface IUserPet {
    userPetName: string;
    userPetId: string | null;
    userPetBreed: string;
    petBreedName: string;
    userPetBreedId: string | null;
    userPetBirthDate: Date | null;
    isOther: boolean;
    isSelected: boolean;
}

const initialUserPet: IUserPet = {
    userPetName: '',
    userPetId: null,
    userPetBreed: '',
    petBreedName: '',
    userPetBreedId: null,
    userPetBirthDate: null,
    isOther: false,
    isSelected: false,
};

export type { IUserPet };

export {initialUserPet}