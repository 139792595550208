const initialUserInfo = {
    birthDate: null,
    departmentName: '',
    email: '',
    gender: null,
    hasPet: null,
    hasChild: null,
    hasPetType: '',
    hasChildType: '',
    hiringDate: null,
    locationName: '',
    maritalStatus: 3,
    mobilePhoneNumber: '',
    name: '',
    photo: null,
    position: null,
    dateOfMarriage: null,
    spouseFirstname: '',
    spouseLastname: '',
    surname: '',
    userChildBirthDates: [],
    userPetBirthDates: [],
    childCountInfo: 0,
    petsCountInfo: 0,
    celebrateBirthdate: null,
};

export { initialUserInfo };
