import React, { useState, FC, useReducer } from 'react';
import { Link } from 'react-router-dom';
import { Navigation, Mousewheel, Keyboard, Autoplay, Pagination } from 'swiper';
import 'swiper/modules/autoplay/autoplay.scss';
import { Swiper, SwiperSlide } from 'swiper/react';

import huaweiStore from '../../_assets/img/huawei-store.png';
import AppDetailModal from './AppDetailModal';
import { appStateReducer } from '../../models/components/details/AppDetailSlider';
import { useHistory } from 'react-router-dom';

const AppDetailSliderResponsive: FC<any> = ({ data }) => {
    const [activeSlide, setActiveSlide] = useState(1);
    const [swiper, setSwiper] = useState(null);
    const [event, updateEvent] = useReducer(appStateReducer, { iframeUrl: null, openAppDetailModal: false });
    const history = useHistory();

    const handleNextClick = () => {
        if (swiper) {
            swiper.slideNext();
        }
    };

    const handlePrevClick = () => {
        if (swiper) {
            swiper.slidePrev();
        }
    };

    //! slider news pause - start begin..
    const sliderNews = document.querySelector('.comp-carousel-11');
    if (sliderNews) {
        const sliderNewsTop = sliderNews.getBoundingClientRect()?.top;
        if (sliderNewsTop < 280 && sliderNewsTop > -300 && !swiper?.autoplay?.running && data?.items?.ikCatiLeftMenuWidgetMedias?.length > 1) {
            swiper?.autoplay?.start();
        } else if ((sliderNewsTop >= 280 || sliderNewsTop <= -301) && swiper?.autoplay?.running) {
            swiper?.autoplay?.stop();
        }
    }
    //! slider news pause - start end..

    return (
        <div className="comp-carousel-11">
            <div className="c-wrapper">
                <Swiper
                    pagination={{
                        type: 'progressbar',
                    }}
                    simulateTouch={false}
                    modules={[Navigation, Mousewheel, Keyboard, Autoplay, Pagination]}
                    autoplay={{
                        delay: 5000,
                        disableOnInteraction: false,
                        stopOnLastSlide: false,
                    }}
                    loop
                    allowTouchMove={data?.items?.ikCatiLeftMenuWidgetMedias?.length > 1}
                    navigation={{ nextEl: 'button-next', prevEl: 'button-prev' }}
                    loopedSlides={data?.items?.ikCatiLeftMenuWidgetMedias?.length}
                    onSlideChange={(e: any) => {
                        setActiveSlide(e.realIndex + 1);
                    }}
                    onSwiper={(swiper_: any) => {
                        setSwiper(swiper_);
                    }}>
                    {data?.items?.ikCatiLeftMenuWidgetMedias?.length > 0 &&
                        data?.items?.ikCatiLeftMenuWidgetMedias?.map((item, index) => (
                            <SwiperSlide key={'news-' + index}>
                                <Link
                                    to={{
                                        pathname: `/${item?.webUrl}`,
                                        state: history?.location
                                    }} className="c-item">
                                    {item?.image.endsWith('.svg') ||
                                        item?.image.endsWith('.png') ||
                                        item?.image.endsWith('.jpeg') ||
                                        item?.image.endsWith('.jpg') ? (
                                        <img loading="lazy" className="c-item-01" src={item?.image} />
                                    ) : (
                                        <video className="c-item-01" controls={false} autoPlay={false}>
                                            <source src={item?.image} type="video/mp4" />
                                        </video>
                                    )}
                                    <button className="c-item-02">
                                        <svg width="71" height="71" viewBox="0 0 71 71" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="35.4444" cy="35.4444" r="34.4444" stroke="white" strokeWidth="2" />
                                            <path d="M46.3994 35.4444L27.0661 46.4L27.0661 24.4889L46.3994 35.4444Z" fill="white" />
                                        </svg>
                                    </button>

                                    <div className="c-item-03">
                                        <h2 className="c-item-03-A">{item?.title}</h2>
                                    </div>
                                </Link>
                            </SwiperSlide>
                        ))}

                    {data?.items?.ikCatiLeftMenuWidgetMedias?.length > 1 && (
                        <div className="c-item-04">
                            <div className="slider-app-fragtion-responsive">
                                <span className="c-item-01">{activeSlide < 10 ? '0' + activeSlide : activeSlide}</span>
                                <span className="c-item-02">/</span>
                                {data?.items?.ikCatiLeftMenuWidgetMedias?.length < 10
                                    ? '0' + (data?.items?.ikCatiLeftMenuWidgetMedias?.length || '')
                                    : data?.items?.ikCatiLeftMenuWidgetMedias?.length}
                            </div>
                            <div className="button-group">
                                <button onClick={handlePrevClick} className="c-item-01">
                                    <svg width="15" height="26" viewBox="0 0 15 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M14 24.25L2 13L14 1.75001" stroke="black" strokeWidth="2" strokeLinecap="round" />
                                    </svg>
                                </button>

                                <button onClick={handleNextClick} className="c-item-01">
                                    <svg width="15" height="26" viewBox="0 0 15 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 24.25L13 13L1.00001 1.75001" stroke="black" strokeWidth="2" strokeLinecap="round" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    )}
                </Swiper>
                <AppDetailModal event={event} updateEvent={updateEvent} />
                <div className="responsive-store-icon">
                    <div className="store-icon-item-01">
                        {data?.items?.application?.url && (
                            <Link className="c-item-01" target="_blank" rel="noreferrer" to={{ pathname: data?.items?.application?.url }}>
                                <span className="platform-icon"> {data?.items?.application?.buttonTitle}</span>
                            </Link>
                        )}
                        {data?.items?.application?.appStoreUrl && (
                            <Link target="_blank" rel="noreferrer" to={{ pathname: data?.items?.application?.appStoreUrl }}>
                                <img loading="lazy" className="c-item-02" src="https://khicindekocvar.blob.core.windows.net/cdn/prod/app-store.png" />
                            </Link>
                        )}
                    </div>
                    <div className="store-icon-item-02">
                        {data?.items?.application?.googlePlayUrl && (
                            <Link target="_blank" rel="noreferrer" to={{ pathname: data?.items?.application?.googlePlayUrl }}>
                                <img
                                    loading="lazy"
                                    className="c-item-03"
                                    src="https://khicindekocvar.blob.core.windows.net/cdn/prod/android-store.png"
                                />
                            </Link>
                        )}
                        {data?.items?.application?.huaweiAppUrl && (
                            <Link target="_blank" rel="noreferrer" to={{ pathname: data?.items?.application?.huaweiAppUrl }}>
                                <img loading="lazy" className="c-item-05" src={huaweiStore} alt="huaw" />
                            </Link>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AppDetailSliderResponsive;
