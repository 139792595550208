import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import CookieAccordion from '../../components/accordion-cookie';
import { getCookieValue } from '../../core/utility';
import { useAppSelector } from '../../store';
import { setCookieOptions, setOpenAccordionCookiesOptions, setOpenCookiesModal } from '../../store/features/user/usersSlice';

const Cookies = () => {
    const [expanded, setExpanded] = useState<string | false>(false);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [isAcceptCookies, setIsAcceptCookies] = useState<boolean>(false);
    const { cookiesOptions, openCookiesModal, openAccordionCookiesOptions, privacyPreference }: any = useAppSelector(state => state.users);
    const { words } = useAppSelector(state => state.localization);
    const dispatch = useDispatch();

    const isGoogleAnalistAccepted = getCookieValue('googleAnalistAccepted')?.toLowerCase() === 'true' ?? false;
    const isGoogleAdsenseAccepted = getCookieValue('googleAdsenseAccepted')?.toLowerCase() === 'true' ?? false;
    const isFacebookPixelAccepted = getCookieValue('facebookPixelAccepted')?.toLowerCase() === 'true' ?? false;
    const isMandatory = getCookieValue('mandatoryAccepted')?.toLowerCase() === 'true' ?? false;

    const handleExpanded = (panel: string) => (event: React.ChangeEvent<unknown>, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };
    //! delete cookies func.
    const deleteCookie = name => {
        document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    };
    //! create cookies func.
    const createCookiesNameValue = (name: string, value: string | boolean) => {
        document.cookie = name + `=${value}; Path=/; Expires=Thu, 01 Jan 2025 00:00:01 GMT;`;
    };

    useEffect(() => {
        //! localStorage tarafını setlemek için
        setOpenModal(!isMandatory);
    }, [isMandatory]);
    //!accept butonuna basıldıktan  cookie ye true/false değeri göndermek için
    useEffect(() => {
        if (isAcceptCookies) {
            //!mandatory cookie accepted!
            createCookiesNameValue('mandatoryAccepted', true);

            if (cookiesOptions?.googleAnalist || isGoogleAnalistAccepted) {
                createCookiesNameValue('googleAnalistAccepted', true);
            } else {
                createCookiesNameValue('googleAnalistAccepted', false);
            }

            if (cookiesOptions?.googleAdsense || isGoogleAdsenseAccepted) {
                createCookiesNameValue('googleAdsenseAccepted', true);
            } else {
                createCookiesNameValue('googleAdsenseAccepted', false);
            }
            if (cookiesOptions?.facebookPixel || isFacebookPixelAccepted) {
                createCookiesNameValue('facebookPixelAccepted', true);
            } else {
                createCookiesNameValue('facebookPixelAccepted', false);
            }
        }
    }, [isAcceptCookies]);

    useEffect(() => {
        if (isGoogleAnalistAccepted || (isAcceptCookies && cookiesOptions?.googleAnalist)) {
            const scriptGoogleAnalistic = document.createElement('script');
            scriptGoogleAnalistic.src = 'https://www.googletagmanager.com/gtag/js?id=G-ZJ7JDJMY6Z';
            scriptGoogleAnalistic.async = true;
            document?.head?.appendChild?.(scriptGoogleAnalistic);
            document?.body?.appendChild?.(scriptGoogleAnalistic);

            const scriptGoogleAnalisticInlineFunc = document.createElement('script');
            scriptGoogleAnalisticInlineFunc.innerHTML += `window.dataLayer = window.dataLayer || [];
        function gtag() {
            dataLayer.push(arguments);
        }
        gtag('js', new Date());
        gtag('config', 'G-ZJ7JDJMY6Z');`;
            scriptGoogleAnalisticInlineFunc.async = true;
            document?.head?.appendChild?.(scriptGoogleAnalisticInlineFunc);
            document?.body?.appendChild?.(scriptGoogleAnalisticInlineFunc);
            //! end Google tag (gtag.js) -->
        } else {
            deleteCookie('_ga');
            deleteCookie('_gat');
            deleteCookie('_gid');
            deleteCookie('_ga_ZJ7JDJMY6Z');
            deleteCookie('_ga_Y4X14ZCYCM');
        }

        //! <!-- Google adsense start -->
        if (isGoogleAdsenseAccepted || (isAcceptCookies && cookiesOptions?.googleAdsense)) {
            const scriptGoogleAdsense = document.createElement('script');
            scriptGoogleAdsense.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5554201037037514';
            scriptGoogleAdsense.async = true;
            scriptGoogleAdsense.crossOrigin = 'anonymous';
            document?.body?.appendChild?.(scriptGoogleAdsense);

            //! end Google adsense end -->
        } else {
            deleteCookie('__gpi');
            deleteCookie('__gads');
        }
        if (isFacebookPixelAccepted || (isAcceptCookies && cookiesOptions?.facebookPixel)) {
            //! Facebook Pixcel scriptini oluştur
            const scriptFacebookPixel = document.createElement('script');
            scriptFacebookPixel.innerHTML = `
                !function(f,b,e,v,n,t,s)
                {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window, document,'script',
                'https://connect.facebook.net/en_US/fbevents.js');
                fbq('init', '2246250615763475');
                fbq('track', 'PageView');
            `;
            //! Facebook Piksel için noscript elementini oluştur
            const noscriptFacebookPixel = document.createElement('noscript');
            const noscriptImageElement = document.createElement('img');
            noscriptImageElement.setAttribute('src', 'https://www.facebook.com/tr?id=2246250615763475&ev=PageView&noscript=1');
            noscriptFacebookPixel.appendChild(noscriptImageElement);
            //! Facebook Piksel script ve noscript elementlerini sayfaya ekle
            document.body.appendChild(scriptFacebookPixel);
            document.body.appendChild(noscriptFacebookPixel);
        } else {
            deleteCookie('_fbp');
            deleteCookie('fr');
        }
    }, [isFacebookPixelAccepted, isGoogleAnalistAccepted, isGoogleAdsenseAccepted, isAcceptCookies]);

    const handleAcceptCookies = (selection = '') => {
        if (selection === 'accept-optional-cookie') {
            createCookiesNameValue('googleAdsenseAccepted', true);
            createCookiesNameValue('facebookPixelAccepted', true);
            createCookiesNameValue('googleAnalistAccepted', true);
            dispatch(setCookieOptions({ ...cookiesOptions, googleAdsense: true, facebookPixel: true, googleAnalist: true }));
        } else if (selection === 'refuse-optional-cookie') {
            createCookiesNameValue('googleAdsenseAccepted', false);
            createCookiesNameValue('facebookPixelAccepted', false);
            createCookiesNameValue('googleAnalistAccepted', false);
            dispatch(setCookieOptions({ ...cookiesOptions, googleAdsense: false, facebookPixel: false, googleAnalist: false }));
        }
        createCookiesNameValue('mandatoryAccepted', true);
        setIsAcceptCookies(true);
        setExpanded(false);
        dispatch(setOpenCookiesModal(false));
    };

    return (
        <div style={{ overflowY: 'scroll' }}>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className="modal-07"
                open={openModal || openCookiesModal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 100,
                }}>
                <Fade in={openModal || openCookiesModal}>
                    <div className="c-wrapper">
                        <div className="modal-content">
                            <div className="c-table-container">
                                <div className="c-item-01" dangerouslySetInnerHTML={{ __html: privacyPreference?.items?.body }} />
                                {openAccordionCookiesOptions &&
                                    privacyPreference?.items?.privacyPreferenceDetailList?.map((cookiesData, index) => (
                                        <CookieAccordion
                                            key={index}
                                            cookiesData={cookiesData}
                                            index={index}
                                            expanded={expanded}
                                            handleExpanded={handleExpanded}
                                            createCookiesNameValue={createCookiesNameValue}
                                        />
                                    ))}
                                <div className="c-item-03">
                                    {!openAccordionCookiesOptions && (
                                        <button className="button-04" onClick={() => dispatch(setOpenAccordionCookiesOptions(true))}>
                                            {words && words['WEB.manageMyCookiePreferences']}
                                        </button>
                                    )}
                                    <button className="button-04" onClick={() => handleAcceptCookies('accept-optional-cookie')}>
                                        {words && words['WEB.acceptAllOptionalCookies']}
                                    </button>
                                    <button className="button-04" onClick={() => handleAcceptCookies('refuse-optional-cookie')}>
                                        {words && words['WEB.declineAllOptionalCookies']}
                                    </button>
                                    {openAccordionCookiesOptions && (
                                        <button onClick={() => handleAcceptCookies()} className="button-04">
                                            {words && words['WEB.saveSelections']}
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
};
export default Cookies;
