import { toastMessage } from '@kocsistem/oneframe-react-bundle';
import { postRequest } from '../../_helpers/fetch-wrapper';
import { LOCAL_STORAGE } from '../constants';

export const sampleFunction = () => {
    console.log('sampleFunction', 'file: core/utility/index.ts');
};

export const getLocalStorageItem = (key: string) => {
    const item: any = window.localStorage.getItem(key);
    return item !== 'undefined' ? JSON.parse(item) : null;
};

export const setILocalStorageItem = (key: string, value: any) => {
    window.localStorage.setItem(key, JSON.stringify(value));
};

export const removeLocalStorageItem = (key: string) => {
    window.localStorage.removeItem(key);
};

export const tokenIsExpired = (expiredTime: any) => {
    const currentTime: any = new Date().getTime() / 1000;
    return currentTime > expiredTime;
};

export const getActiveToken = (checkExpiration = false) => {
    const token: any = getLocalStorageItem(LOCAL_STORAGE.ACCESS_TOKEN);
    const expiredTime = getLocalStorageItem(LOCAL_STORAGE.EXPIRED_TIME);

    if (checkExpiration && token && tokenIsExpired(expiredTime)) return null;

    return token;
};

export const isAllTokenInvalid = () => {
    const isTokenExpired = getActiveToken(true) == null;
    const isRefreshTokenExpired = tokenIsExpired(
        getLocalStorageItem(LOCAL_STORAGE.REFRESH_TOKEN),
    );

    return isTokenExpired && isRefreshTokenExpired;
};

export const getApiBaseUrl = () => {
    return process.env.REACT_APP_API_URL;
};

export function isObject(item: any) {
    return item && typeof item === 'object' && !Array.isArray(item);
}

export default function deepmerge(
    target: any,
    source: any,
    options: any = { clone: true },
) {
    const output: any = options.clone ? { ...target } : target;

    if (isObject(target) && isObject(source)) {
        Object.keys(source).forEach(key => {
            if (key === '__proto__') {
                return;
            }

            if (isObject(source[key]) && key in target) {
                output[key] = deepmerge(target[key], source[key], options);
            } else {
                output[key] = source[key];
            }
        });
    }

    return output;
}

export const ByteArrayToFileDownload = (response: any) => {
    const binaryString = atob(response.result.fileByteArray);
    const binaryLen = binaryString.length;
    const bytes = new Uint8Array(binaryLen);

    for (let i = 0; i < binaryLen; i++) {
        const ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
    }

    const blob = new Blob([bytes], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
    const link = document.createElement('a');
    const url = window.URL.createObjectURL(blob);
    link.href = url;
    link.setAttribute(
        'download',
        response.result.fileName,
    );
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
}

export const getAuthApiBaseUrl = () => {
    return process.env.REACT_APP_API_URL;
};

export function getCookieValue(name) {
    const cookies = document.cookie.split(";").reduce((cookies, cookie) => {
        const [name, value] = cookie.split("=").map((c) => c.trim());
        cookies[name] = value;
        return cookies;
    }, {});

    return cookies[name];
}

export function getQueryParameter(variable) {
    const query = window.location.search.substring(1);
    const vars = query.split("&");

    for (let i = 0; i < vars.length; i++) {
        const pair = vars[i].split("=");

        if (decodeURIComponent(pair[0]) === variable) {
            return decodeURIComponent(pair[1]);
        }
    }

    return null;
}

export const isAuthenticated = () => getActiveToken(true) != null;

export function setCookie(
    name: string,
    value: string,
    expireInDays: number,
    domain: string,
) {
    let expires = "";

    if (expireInDays) {
        const date = new Date();
        date.setTime(date.getTime() + expireInDays * 24 * 60 * 60 * 1000);
        expires = "; expires=" + date.toUTCString();
    }

    document.cookie =
        name + "=" + (value || "") + expires + "; path=/; domain=" + domain;
}

export const setLocalStorageItem = (key: string, value: any) => {
    window.localStorage.setItem(key, JSON.stringify(value));
};

export function buildRequestHeader() {
    const headers: any = {};

    if (isAuthenticated()) {
        headers.Authorization = "Bearer " + getActiveToken(true);
    }

    return headers;
}

export function handleErrorType(errCode: number) {
    // todo: krax doesn't provide status code as expected. need to be checked.
    if (errCode && (errCode === 401 || errCode === 100)) {
        window.location.href = "/auth/login?ref=" + window.location.pathname;
    }

    if (errCode && errCode === 403) {
        toastMessage({
            messageType: "error",
            message: "Bu işleme yetkiniz bulunmamaktadır",
            position: "center",
            overlay: true,
            icon: "fad fa-exclamation-square",
            timeout: 2000,
        });
    }
}

export const tokenControl = async () => {
    const tokenExpiredTime = getLocalStorageItem(
        LOCAL_STORAGE.EXPIRED_TIME,
    );

    if (tokenExpiredTime && tokenIsExpired(tokenExpiredTime)) {
        const urlBase = () => getAuthApiBaseUrl();
        const urlSuffix = `/accounts/refresh`;

        const url = String(urlBase() + urlSuffix);
        const body = JSON.stringify({
            refreshToken: getLocalStorageItem(LOCAL_STORAGE.REFRESH_TOKEN),
            token: getLocalStorageItem(LOCAL_STORAGE.ACCESS_TOKEN),
        });

        const response: any = await postRequest(
            url,
            {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: body
            }
        );

        const result: any = await response;

        setLocalStorageItem(
            LOCAL_STORAGE.REFRESH_TOKEN,
            result.refreshToken,
        );
        setLocalStorageItem(LOCAL_STORAGE.ACCESS_TOKEN, result.token);
    }

    return new Promise((resolve) => {
        resolve(true);
    });
};