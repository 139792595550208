import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import Spinner from '../../components/ui/spinner';
import { getLocalStorageItem } from '../../core/utility';
import { useAppSelector } from '../../store';
import { getApplicationList, getNewsList } from '../../store/features/hr/hrSlice';
import { getLeftMenuList } from '../../store/features/hrDetail/hrDetailsSlice';
import AppCard from '../home/AppCard';
import New from './New';

const News = () => {
    const dispatch = useDispatch();
    const { appCardToOpen }: any = useAppSelector(state => state.hr);
    const culture = getLocalStorageItem('SelectedLanguage');
    const { words } = useAppSelector(state => state.localization);

    useEffect(() => {
        dispatch(getNewsList());
        dispatch(getLeftMenuList());
        dispatch(getApplicationList());
    }, [culture]);

    return words ? (
        <div id="page-wrapper">
            {appCardToOpen && <AppCard />}
            <New />
        </div>
    ) : (
        <Spinner />
    );
};

export default News;
