import React, { useEffect, useState, FC, useReducer } from 'react';
import {
    mobileVendor,
    browserVersion,
    browserName as browserNames,
    isAndroid,
    isIOS,
    mobileModel,
    isMobileOnly,
    osName,
    osVersion,
} from 'react-device-detect';
import { Link } from 'react-router-dom';
import { Navigation, Pagination, Autoplay, FreeMode, Virtual } from 'swiper';
import 'swiper/modules/free-mode/free-mode.scss';
import 'swiper/modules/virtual/virtual.scss';
import { Swiper, SwiperSlide } from 'swiper/react';

import huaweiStore from '../../_assets/img/huawei-store.png';
import { analiticsLog, isBrave, userId } from '../../_helpers/useful-functions';
import { post } from '../../core/client';
import { getLocalStorageItem } from '../../core/utility';
import { IAppDetailSlider, appStateReducer } from '../../models/components/details/AppDetailSlider';
import { useAppSelector } from '../../store';
import { useHistory } from 'react-router-dom';
import AppDetailModal from './AppDetailModal';

let sliderTimeout: any = null;

const AppDetailSlider: FC<IAppDetailSlider> = ({ sliderData }) => {
    const [swiper, setSwiper] = useState(null);
    const [activeSlide, setActiveSlide] = useState<number>(0);
    const [event, updateEvent] = useReducer(appStateReducer, { iframeUrl: null, openAppDetailModal: false, index: null });
    const { loginId } = useAppSelector(state => state.users.globalLogin);
    const allSlides = sliderData?.ikCatiLeftMenuWidgetMedias?.length;
    const history = useHistory();

    const handleNextClick = () => {
        if (swiper) {
            const activeSlide_ = activeSlide + 1;
            if (activeSlide_ > 0 && activeSlide_ < allSlides) {
                const bolum = activeSlide_ / 3; // 3/3=1 kalan=0
                const kalan = activeSlide_ % 3; //

                if (kalan === 0) {
                    const slideTo_ = bolum * 3;
                    swiper?.slideTo(slideTo_, 0, true);
                    setActiveSlide(slideTo_);
                } else {
                    const _slideTo = (parseInt(bolum.toString()) + 1) * 3;
                    swiper?.slideTo(_slideTo, 0, true);
                    setActiveSlide(_slideTo);
                }
            }

            const conditionLast3Slides =
                allSlides - 3 === activeSlide_ || allSlides - 2 === activeSlide_ || allSlides - 1 === activeSlide_ || allSlides === activeSlide_;
            if (conditionLast3Slides) {
                setActiveSlide(0);
                swiper?.slideTo(0, 0, true);
            }
        }
    };

    const handlePrevClick = () => {
        if (swiper) {
            const activeSlide_ = activeSlide + 1;
            if (activeSlide_ > 3) {
                const bolum = activeSlide_ / 3; //1.25 1.50 1.75 2
                const kalan = activeSlide_ % 3; //0,1,2,3

                if (kalan === 0) {
                    const slideTo_ = (bolum - 2) * 3;
                    swiper?.slideTo(slideTo_, 0, true);
                    setActiveSlide(slideTo_);
                } else {
                    const _slideTo = (parseInt(bolum.toString()) - 1) * 3;
                    swiper?.slideTo(_slideTo, 0, true);
                    setActiveSlide(_slideTo);
                }
            }

            if (activeSlide_ === 1) {
                const lastSlide = allSlides - 1;
                swiper?.slideTo(lastSlide, 0, true);
                setActiveSlide(lastSlide);
            }
        }
    };

    const handleOnMouseOver = (slideNumber: number) => {
        if (sliderTimeout !== null) {
            clearTimeout(sliderTimeout);
        }
        sliderTimeout = setTimeout(function () {
            if (slideNumber !== activeSlide) {
                if (swiper) {
                    setActiveSlide(slideNumber);
                }
            }
        }, 200);
    };

    useEffect(() => {
        const interval = setInterval(() => {
            if (allSlides === activeSlide + 1) {
                setActiveSlide(0);
                swiper?.slideTo(0, 0, true);
            } else {
                const bolum = (activeSlide + 1) / 4;
                const kalan = (activeSlide + 1) % 4;

                if (kalan === 0) {
                    const slideTo_ = bolum * 4;
                    swiper?.slideTo(slideTo_, 0, true);
                    setActiveSlide(slideTo_);
                } else {
                    setActiveSlide(prevCount => prevCount + 1);
                }
            }
        }, 5000);

        return () => {
            clearInterval(interval);
        };
    }, [activeSlide]);

    useEffect(() => {
        const swiperWrapper = document?.querySelector('.comp-carousel-07 .swiper-wrapper ');

        if (swiperWrapper && allSlides < 4) {
            swiperWrapper?.classList?.add('card-center');
        } else {
            swiperWrapper?.classList?.remove('card-center');
        }
    }, [allSlides]);

    const handleApplicationLogin = async applicationName => {
        const email = getLocalStorageItem('USER_EMAIL');
        const os = isIOS ? 1 : isAndroid ? 2 : 0; //! IOS=1, Android=2, Huawei=3
        const deviceName = isMobileOnly ? mobileVendor : null;
        const deviceModel = isMobileOnly ? mobileModel : null;
        const isMobile = isMobileOnly;
        const osNameWeb = osName;
        const osVersionWeb = osVersion;
        const browserName = isBrave() ? 'Brave' : browserNames;

        const applicationFormData = {
            email,
            applicationName,
            loginId,
            isMobile,
            os,
            deviceName,
            deviceModel,
            osVersion: null,
            version: null,
            deviceId: null,
            schema: null,
            //!add pls
            browserName,
            browserVersion,
            osNameWeb,
            osVersionWeb,
            //!
        };
        const endpoint = `/hr/click-application-link`;
        await post<any>(endpoint, applicationFormData, '');
        analiticsLog(applicationName);
    };

    const onClickIframe = (item, index) => {
        updateEvent({
            iframeUrl: item?.url,
            openAppDetailModal: item?.ikCatiLeftMenuWidgetId === '7915eab2-8906-47f5-8014-12f324b14010' && index === 0 ? false : true,
            index,
        });
    };

    return (
        <div className="comp-carousel-07" id="cardDetailSlider">
            <div className="c-wrapper">
                {allSlides > 0 && (
                    <Swiper
                        className="cardDetailSlider-swiper"
                        id="swiper-cardDetailSlider"
                        virtual={false}
                        grabCursor={false}
                        freeMode={false}
                        spaceBetween={0}
                        centeredSlides={false}
                        cssMode={true}
                        navigation={false}
                        pagination={false}
                        modules={[Navigation, Pagination, Autoplay, FreeMode, Virtual]}
                        slidesPerView={3}
                        allowTouchMove={false}
                        onSwiper={(swiper_: any) => {
                            setSwiper(swiper_);
                        }}>
                        {allSlides > 3 && (
                            <div className="c-container-pagination-arrows">
                                <button className="c-item-01" onClick={handlePrevClick}>
                                    <svg width="15" height="25" viewBox="0 0 15 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M14 23.75L2 12.5L14 1.25001" stroke="white" strokeWidth="2" strokeLinecap="round" />
                                    </svg>
                                </button>
                                <button className="c-item-02" onClick={handleNextClick}>
                                    <svg width="15" height="25" viewBox="0 0 15 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 23.75L13 12.5L1.00001 1.25001" stroke="white" strokeWidth="2" strokeLinecap="round" />
                                    </svg>
                                </button>
                            </div>
                        )}
                        <AppDetailModal event={event} updateEvent={updateEvent} />
                        {allSlides > 0 &&
                            sliderData?.ikCatiLeftMenuWidgetMedias?.map((item: any, index: number) => {
                                return (
                                    <SwiperSlide key={'SliderApps-' + index} onMouseOver={() => handleOnMouseOver(index)}>
                                        <Link
                                            to={{
                                                pathname: `/${item?.webUrl}`,
                                                state: history?.location
                                            }}
                                            onClick={() => onClickIframe(item, index)}
                                            className="c-container-01">
                                            <div className={activeSlide === index ? 'image-wrapper active' : 'image-wrapper'}>
                                                <img
                                                    loading="lazy"
                                                    className={activeSlide === index ? 'c-item-01 filter' : 'c-item-01'}
                                                    src={item?.image}
                                                />
                                                <svg
                                                    className="c-item-02"
                                                    width="110"
                                                    height="110"
                                                    viewBox="0 0 90 90"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        fill="#FFF"
                                                        fillRule="evenodd"
                                                        d="M45 4C22.393 4 4 22.393 4 45s18.393 41 41 41 41-18.393 41-41S67.607 4 45 4m0 86C20.187 90 0 69.813 0 45S20.187 0 45 0s45 20.187 45 45-20.187 45-45 45M32 27.387v34.9c0 1.06 1.136 1.728 2.056 1.208l30.902-17.45c.938-.53.938-1.885 0-2.414L34.056 26.18c-.92-.52-2.056.148-2.056 1.207"></path>
                                                </svg>
                                                <h6 className="c-item-03">{item?.title}</h6>
                                            </div>
                                        </Link>
                                    </SwiperSlide>
                                );
                            })}
                    </Swiper>
                )}
                <div className={allSlides === 0 ? 'store-icon add-mt-50' : 'store-icon'}>
                    {sliderData?.application?.url && (
                        <a
                            onClick={() => handleApplicationLogin(sliderData?.name)}
                            className="c-item-01"
                            target="_blank"
                            rel="noreferrer"
                            href={
                                sliderData?.application?.url?.includes('kocailem.com') && userId
                                    ? sliderData?.application?.url + '?loginId=' + loginId
                                    : sliderData?.application?.url
                            }>
                            {sliderData?.application?.buttonTitle}
                        </a>
                    )}
                    {sliderData?.application?.appStoreUrl && (
                        <Link target="_blank" rel="noreferrer" to={{ pathname: sliderData?.application?.appStoreUrl }}>
                            <img loading="lazy" className="c-item-02" src="https://khicindekocvar.blob.core.windows.net/cdn/prod/app-store.png" />
                        </Link>
                    )}
                    {sliderData?.application?.googlePlayUrl && (
                        <Link target="_blank" rel="noreferrer" to={{ pathname: sliderData?.application?.googlePlayUrl }}>
                            <img loading="lazy" className="c-item-03" src="https://khicindekocvar.blob.core.windows.net/cdn/prod/android-store.png" />
                        </Link>
                    )}
                    {sliderData?.application?.huaweiAppUrl && (
                        <Link target="_blank" rel="noreferrer" to={{ pathname: sliderData?.application?.huaweiAppUrl }}>
                            <img className="huawei-icon" src={huaweiStore} />
                        </Link>
                    )}
                </div>
            </div>
        </div>
    );
};

export default AppDetailSlider;
