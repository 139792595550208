import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { setTimeout } from 'timers';

import { scrollToTop } from '../../_helpers/useful-functions';
import { useAppSelector } from '../../store';
import { getLeftMenuListForDetail } from '../../store/features/hrDetail/hrDetailsSlice';
import { isMacOs } from 'react-device-detect';

const ContinueDiscover: FC<any> = ({ data }) => {
    const { words }: any = useAppSelector(state => state.localization);
    const [swiper, setSwiper] = useState<any>(null);
    const [leftMenuId, setLeftMenuId] = useState<any>('');
    const [slideClusterIndex, setSlideClusterIndex] = useState(0);
    const [activeSlideIndex, setActiveSlideIndex] = useState(0);
    const dispatch = useDispatch();

    const allSlides: number | null = data?.length;

    const initSwiper = () => {
        const sliderEl = document.getElementById('discover-owl');
        const swiperSlide = sliderEl.querySelector(`[data-index="0"]`);

        if (swiperSlide) {
            swiperSlide.dispatchEvent(new MouseEvent('mouseenter'));
            addSlideClassNames();
        }
    };

    const addSlideClassNames = (slideStatus = '') => {
        setTimeout(() => {
            const sliderEl = document.getElementById('discover-owl');
            const sliderPaginationContainer = document.querySelector('.c-container-pagination-numbers');
            sliderPaginationContainer?.querySelector('.active')?.classList?.remove('active');

            if (slideStatus === '') {
                const activeLeftSlideEl = sliderEl.querySelector('.swiper-slide-active');
                const passiveCenterSlideEl = activeLeftSlideEl?.nextElementSibling;
                const passiveRightSlideEl = passiveCenterSlideEl?.nextElementSibling;
                activeLeftSlideEl?.classList.add('f-active-left');
                passiveCenterSlideEl?.classList.add('f-passive-center');
                passiveRightSlideEl?.classList.add('f-passive-right');

                const sliderPaginationFirstChild = sliderPaginationContainer.querySelector('.pagination-number:nth-child(1)');
                sliderPaginationFirstChild?.classList.add('active');
            } else if (slideStatus === 'last') {
                const activeRightSlideEl = sliderEl.querySelector('.swiper-slide:last-child');
                const passiveCenterSlideEl = activeRightSlideEl.previousElementSibling;
                const passiveLeftSlideEl = passiveCenterSlideEl.previousElementSibling;
                activeRightSlideEl?.classList.add('f-active-right');
                passiveCenterSlideEl?.classList.add('f-passive-center');
                passiveLeftSlideEl?.classList.add('f-passive-left');

                const sliderPaginationThirdChild = sliderPaginationContainer.querySelector('.pagination-number:nth-child(3)');
                sliderPaginationThirdChild?.classList.add('active');
            } else if (slideStatus === 'beforeLast') {
                const activeCenterSlideEl = sliderEl.querySelector('.swiper-slide:nth-last-child(2)');
                const passiveRightSlideEl = activeCenterSlideEl?.nextElementSibling;
                const passiveLeftSlideEl = activeCenterSlideEl?.previousElementSibling;

                activeCenterSlideEl?.classList.add('f-active-center');
                passiveRightSlideEl?.classList.add('f-passive-right');
                passiveLeftSlideEl?.classList.add('f-passive-left');

                const sliderPaginationSecondChild = sliderPaginationContainer.querySelector('.pagination-number:nth-child(2)');
                sliderPaginationSecondChild?.classList.add('active');
            }
        }, 10);
    };

    const handleNextClick = () => {
        const sliderEl = document.getElementById('discover-owl');
        const numberOfSlides = sliderEl.querySelectorAll('.swiper-slide').length;
        let nextSlideIndex = slideClusterIndex * 3 + 3;
        let slideStatus = '';
        if (numberOfSlides > nextSlideIndex) {
            setSlideClusterIndex(slideClusterIndex + 1);

            const isLastSlide = numberOfSlides === nextSlideIndex + 1;
            const isBeforeLastSlide = numberOfSlides === nextSlideIndex + 2;

            if (isLastSlide) {
                slideStatus = 'last';
            } else if (isBeforeLastSlide) {
                slideStatus = 'beforeLast';
            }
        } else {
            nextSlideIndex = 0;
            setSlideClusterIndex(0);
        }

        setActiveSlideIndex(nextSlideIndex);

        swiper?.slideTo(nextSlideIndex, 0, true);
        const swiperSlide = sliderEl.querySelector(`[data-index="${nextSlideIndex}"]`);
        settingActiveSlide(swiperSlide, 'navNextClick', slideStatus);
    };

    const handlePrevClick = () => {
        const sliderEl = document.getElementById('discover-owl');
        let prevSlideIndex = 0;

        if (slideClusterIndex > 0) {
            prevSlideIndex = slideClusterIndex * 3 - 3;
            setSlideClusterIndex(slideClusterIndex - 1);
            setActiveSlideIndex(prevSlideIndex);
        } else if (slideClusterIndex === 0) {
            const numberOfSlides = sliderEl.querySelectorAll('.swiper-slide').length;
            prevSlideIndex = numberOfSlides - 1;
            const _slideClusterIndex = Math.ceil(numberOfSlides / 3) - 1;
            setSlideClusterIndex(_slideClusterIndex);
            setActiveSlideIndex(prevSlideIndex - 2);
        }

        swiper?.slideTo(prevSlideIndex, 0, true);
        const swiperSlide = sliderEl.querySelector(`[data-index="${prevSlideIndex}"]`);
        settingActiveSlide(swiperSlide, 'navPrevClick');
    };

    const handleOnMouseEnter = (e: any, index) => {
        e.stopPropagation();
        const isSwiperSlideImg = e?.target?.classList.contains('c-container-01');

        if (isSwiperSlideImg) {
            const swiperSlide = e?.target?.parentElement;
            const isActive = swiperSlide.getAttribute('class').indexOf('f-active-') > -1;

            if (isActive) return;

            settingActiveSlide(swiperSlide, 'mouseEnter');
            setActiveSlideIndex(index);
        }
    };

    const handlePaginationClick = e => {
        const sliderEl = document.getElementById('discover-owl');
        const selectedPageNumber = parseInt(e?.target?.text);
        const swiperSlide = sliderEl?.querySelector(`[data-index="${selectedPageNumber - 1}"]`);

        setActiveSlideIndex(selectedPageNumber - 1);
        settingActiveSlide(swiperSlide, 'paginationClick');
    };

    const settingActiveSlide = (swiperSlide, actionType, slideStatus = '') => {
        const sliderEl = document.getElementById('discover-owl');
        const sliderPaginationContainer = document.querySelector('.c-container-pagination-numbers');
        const sliderPaginationFirstChild = sliderPaginationContainer?.querySelector('.pagination-number:nth-child(1)');
        const sliderPaginationSecondChild = sliderPaginationContainer?.querySelector('.pagination-number:nth-child(2)');
        const sliderPaginationThirdChild = sliderPaginationContainer?.querySelector('.pagination-number:nth-child(3)');
        sliderPaginationContainer?.querySelector('.active')?.classList?.remove?.('active');

        const fActiveElements = sliderEl?.querySelectorAll("[class*='f-active']");
        const fPassiveElements = sliderEl?.querySelectorAll("[class*='f-passive']");
        const activeElement = sliderEl?.querySelector("[class*='f-active']");

        activeElement?.classList.add('active-display');

        for (let i = 0; i < fActiveElements.length; i++) {
            fActiveElements[i].classList.remove('f-active-left');
            fActiveElements[i].classList.remove('f-active-center');
            fActiveElements[i].classList.remove('f-active-right');
        }

        for (let i = 0; i < fPassiveElements.length; i++) {
            fPassiveElements[i].classList.remove('f-passive-left');
            fPassiveElements[i].classList.remove('f-passive-center');
            fPassiveElements[i].classList.remove('f-passive-right');
        }
        if (actionType === 'mouseEnter' || actionType === 'paginationClick') {
            if (swiperSlide?.classList.contains('swiper-slide-active')) {
                swiperSlide?.classList.add('f-active-left');
                swiperSlide?.nextElementSibling?.classList.add('f-passive-center');
                swiperSlide?.nextElementSibling?.nextElementSibling.classList.add('f-passive-right');
                sliderPaginationFirstChild?.classList.add('active');
            } else if (swiperSlide.classList.contains('swiper-slide-next')) {
                swiperSlide?.classList.add('f-active-center');
                swiperSlide?.previousElementSibling?.classList.add('f-passive-left');
                swiperSlide?.nextElementSibling?.classList.add('f-passive-right');
                sliderPaginationSecondChild?.classList.add('active');
            } else if (swiperSlide?.previousSibling?.classList.contains('swiper-slide-next')) {
                swiperSlide?.classList.add('f-active-right');
                swiperSlide?.previousElementSibling?.classList.add('f-passive-center');
                swiperSlide?.previousElementSibling?.previousElementSibling?.classList.add('f-passive-left');
                sliderPaginationThirdChild?.classList.add('active');
            }
        } else if (actionType === 'navNextClick' || actionType === 'navPrevClick') {
            addSlideClassNames(slideStatus);

            const activeLeftSlideEl = sliderEl?.querySelector('.swiper-slide-active');
            const firstPageNo = parseInt(activeLeftSlideEl?.getAttribute('data-index')) + 1;

            const passiveCenterSlideEl = activeLeftSlideEl?.nextElementSibling;
            const secondPageNo = parseInt(passiveCenterSlideEl?.getAttribute('data-index')) + 1;

            const passiveRightSlideEl = passiveCenterSlideEl?.nextElementSibling;
            const thirdPageNo = parseInt(passiveRightSlideEl?.getAttribute('data-index')) + 1;

            const sliderPaginationContainer = document.querySelector('.c-container-pagination-numbers');
            const sliderPaginationFirstChild = sliderPaginationContainer?.querySelector('.pagination-number:nth-child(1)');
            const sliderPaginationSecondChild = sliderPaginationContainer?.querySelector('.pagination-number:nth-child(2)');
            const sliderPaginationThirdChild = sliderPaginationContainer?.querySelector('.pagination-number:nth-child(3)');

            sliderPaginationFirstChild.innerHTML = firstPageNo.toString();
            sliderPaginationSecondChild.innerHTML = secondPageNo.toString();
            sliderPaginationThirdChild.innerHTML = thirdPageNo.toString();
        }
    };

    const handleClickLink = itemId => {
        scrollToTop();
        setLeftMenuId(itemId);
    };

    useEffect(() => {
        if (!leftMenuId) return;
        if (leftMenuId) {
            dispatch(getLeftMenuListForDetail(leftMenuId));
            scrollToTop();
        }
    }, [leftMenuId]);

    return (
        <div className={isMacOs ? 'comp-carousel-06 isMacOs' : 'comp-carousel-06'} id="discover-owl">
            <div className="c-wrapper">
                <div className="c-container">
                    <h6 className="c-item">{words && words['WEB.continueDiscover']}</h6>
                </div>
                <Swiper
                    className="allApplication-swiper"
                    id="swiper-discover"
                    onSlideChange={e => console.log(e)}
                    modules={[Autoplay]}
                    slidesPerView={3}
                    spaceBetween={0}
                    grabCursor={false}
                    allowTouchMove={false}
                    onSwiper={(swiper_: any) => {
                        setSwiper(swiper_);
                        initSwiper();
                    }}>
                    {allSlides > 3 && (
                        <div className="c-container-pagination-arrows">
                            <button className="c-item-01" onClick={handlePrevClick}>
                                <svg width="11" height="17" viewBox="0 0 11 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10 1L2 8.5L10 16" stroke="white" strokeWidth="2" strokeLinecap="round" />
                                </svg>
                            </button>
                            <button className="c-item-02" onClick={handleNextClick}>
                                <svg width="11" height="17" viewBox="0 0 11 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0.999999 16L9 8.5L1 1" stroke="white" strokeWidth="2" strokeLinecap="round" />
                                </svg>
                            </button>
                        </div>
                    )}

                    <div className="c-container-pagination-numbers">
                        <a onClick={e => handlePaginationClick(e)} className="pagination-number" href="#!">
                            1
                        </a>
                        <a onClick={e => handlePaginationClick(e)} className="pagination-number" href="#!">
                            2
                        </a>
                        <a onClick={e => handlePaginationClick(e)} className="pagination-number" href="#!">
                            3
                        </a>
                    </div>

                    {data?.length > 0 &&
                        data?.map((item: any, index: number) => (
                            <SwiperSlide data-index={index} onMouseEnter={e => handleOnMouseEnter(e, index)} key={'SliderApps-' + index}>
                                <Link
                                    to={
                                        item?.leftMenuType === 1
                                            ? `/${words && words['WEB.NEWS']}`
                                            : `/${words && words['WEB.appDetails']}/${item?.id}`
                                    }
                                    className={activeSlideIndex === index ? 'c-container-01 f-gradient' : 'c-container-01'}
                                    onClick={() => (item?.leftMenuType !== 1 ? handleClickLink(item?.id) : scrollToTop())}>
                                    {item?.image.endsWith('.svg') ||
                                    item?.image.endsWith('.png') ||
                                    item?.image.endsWith('.jpeg') ||
                                    item?.image.endsWith('.jpg') ? (
                                        <img
                                            loading="lazy"
                                            className={activeSlideIndex === index ? 'c-item-01 filter' : 'c-item-01'}
                                            src={item?.image}
                                        />
                                    ) : (
                                        <video className="c-item-01" controls={false} autoPlay={false}>
                                            <source src={item?.image} type="video/mp4" />
                                        </video>
                                    )}
                                    <h6 className="c-item-02">{item?.name}</h6>
                                    {activeSlideIndex === index && <p className="c-item-03">{words && words['WEB.learnMore']}</p>}
                                </Link>
                            </SwiperSlide>
                        ))}
                </Swiper>
            </div>
        </div>
    );
};

export default ContinueDiscover;
