function trackButtonClick2(applicationName = 'Ana Sayfa Ziyareti') {
    const turkishChars = {
        ç: 'c',
        ğ: 'g',
        ı: 'i',
        ö: 'o',
        ş: 's',
        ü: 'u',
    };

    const appName =
    applicationName
        .toLowerCase()
        .replace(/\s+/g, '_')
        .replace(/[çğıöşü]/g, char => turkishChars[char] || char) + '_test_web2_test';
    // gtag('event', 'button_click_test_web',null);
    window?.dataLayer?.push({
        event: appName,
        // pagePath: 'https://www.googleanalytics.dev/pancakes',
        // pageTitle: 'Pancake Event Signup',
        // visitorType: 'customer',
    });
}

export {trackButtonClick2}

// function gtag(arg0: string, arg1: string, arg2: { event_category?: string; event_label?: string; }) {
//     throw new Error("Function not implemented.");
// }
