import React, { useEffect } from 'react';
import { isFirefox, isEdge, isChrome, isSafari } from 'react-device-detect';
import { useDispatch } from 'react-redux';

import { useAppSelector } from '../../store';
// import MobileModal from '../mobileModal';
import { getLocalizationWords } from '../../store/features/localization/localizationSlice';

const NotSupportPage = () => {
    const { words } = useAppSelector(state => state.localization);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getLocalizationWords());
    }, []);

    return (
        <>
            {
                <div className={!(isFirefox || isEdge || isChrome || isSafari) ? 'not-support-page show' : 'not-support-page'}>
                    <div className="not-support-content">
                        <img
                            loading="lazy"
                            className="not-support-photo-1"
                            src="https://khicindekocvar.blob.core.windows.net/cdn/prod/footer-koc-logo.png"
                        />
                        {isFirefox || isEdge || isChrome || isSafari ? (
                            <p className="title" id="transition-modal-description">
                                {words && words['WEB.warningsIkvForPixels1']}
                                <br />
                                {words && words['WEB.warningsForPixels3']}
                                <br />
                                {words && words['WEB.warningsIkvForPixels2']}
                                <br />
                            </p>
                        ) : (
                            <>
                                <p className="title" id="transition-not-support-description">
                                    {words && words['WEB.warningsForBrowser']} <br />
                                    {words && words['WEB.warningsForBrowserToIKVPlatform']}
                                </p>
                            </>
                        )}
                    </div>
                </div>
            }
        </>
    );
};
export default NotSupportPage;
