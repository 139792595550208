import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import Spinner from '../../components/ui/spinner';
import { useAppSelector } from '../../store';
import { getKvkkDetailText } from '../../store/features/user/usersSlice';
import { getLocalStorageItem } from '../../core/utility';

const KvkkDetailText = () => {
    const dispatch = useDispatch();
    const { KvkkDetailText }: any = useAppSelector(state => state.users);
    const culture = getLocalStorageItem('SelectedLanguage');

    useEffect(() => {
        dispatch(getKvkkDetailText());
    }, [culture]);

    return KvkkDetailText?.text ? (
        <div className="c-wrapper">
            <div dangerouslySetInnerHTML={{ __html: KvkkDetailText?.text }}></div>
        </div>
    ) : (
        <div className="c-wrapper">
            <Spinner/>
        </div>
    );
};

export default KvkkDetailText;
