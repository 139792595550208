import { getRequest } from "../_helpers/fetch-wrapper";
import {
  buildRequestHeader,
  handleErrorType,
  getAuthApiBaseUrl,
  tokenControl,
} from "../core/utility";

export const getUserRoleClaims = async (): Promise<Array<string>> => {
  await tokenControl();

  const urlSuffix = `/roles/claims/user`;
  const url = String(getAuthApiBaseUrl() + urlSuffix);

  const userRoleClaimsResponse: Response = await getRequest(
    url,
    {
      headers: {
        ...buildRequestHeader(),
      },
    });

  const response: any = await userRoleClaimsResponse;

  if (response?.isSuccessful) {
    return response.result as Array<string>;
  } else {
    handleErrorType(userRoleClaimsResponse?.status);
  }

  return [];
};