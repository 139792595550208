import { NavigateBefore, NavigateNext } from '@material-ui/icons';
import en from 'date-fns/locale/en-US';
import tr from 'date-fns/locale/tr';
import React, { FC, useEffect, useReducer, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { ReactComponent as CalendarIcon } from '../../_assets/images/calendar.svg';
import { getDateFormat, isDate } from '../../_helpers/useful-functions';
import { IDatePicker, appStateReducer } from '../../models/components/custom-react-datepicker';
import { useAppSelector } from '../../store';
import { getLocalStorageItem } from '../../core/utility';

const CustomReactDatePicker: FC<IDatePicker> = ({ setState, stateDate, isTouched, name = null, isEdit }) => {
    const CURRENT_LANGUAGE = getLocalStorageItem('SelectedLanguage')
    registerLocale('tr-TR', tr);
    registerLocale('en-US', en);
    const { words } = useAppSelector(state => state.localization);
    const initialStateReducer = {
        selectedDate: null,
        showMonthYearPicker: false,
        showYearPicker: false,
        showDayPicker: true,
        openDatePicker: false,
        inputValue: null,
    };

    const [event, updateEvent] = useReducer(appStateReducer, initialStateReducer);
    const { selectedDate, showMonthYearPicker, showYearPicker, showDayPicker, openDatePicker, inputValue } = event;
    const [inputValueDate, setInputValueDate] = useState('');
    const [changedSelectedDate, setChangedSelectedDate] = useState(null);

    useEffect(() => {
        if (inputValueDate?.length >= 1 && inputValueDate?.length < 10) {
            updateEvent({ inputValue: inputValueDate, showDayPicker: false, openDatePicker: false });
            setState(prevState => ({
                ...prevState,
                [name]: '',
            }));
        } else if (inputValue === undefined || inputValueDate === undefined) {
            setState(prevState => ({
                ...prevState,
                [name]: changedSelectedDate,
            }));
            updateEvent({
                selectedDate,
                inputValue: selectedDate ? getDateFormat({ date_: selectedDate, seperator: '/', beginningDate: 'day' }) : null,
            });
        } else if (inputValueDate?.length === 10) {
            updateEvent({
                selectedDate: new Date(stateDate),
                inputValue: stateDate ? getDateFormat({ date_: stateDate, seperator: '/', beginningDate: 'day' }) : null,
            });
        }
        //! name control pet and celebrateDate
    }, [inputValueDate]);
    useEffect(() => {
        if (stateDate) {
            setInputValueDate(getDateFormat({ date_: stateDate, seperator: '/', beginningDate: 'day' }));
            setState(prevState => ({
                ...prevState,
                [name]: stateDate,
            }));
            setChangedSelectedDate(selectedDate);
        }
    }, [stateDate]);
    useEffect(() => {
        if (!showDayPicker && !openDatePicker && inputValueDate) {
            updateEvent({ openDatePicker: false });
        }
    }, [openDatePicker, showDayPicker]);

    const customHeader = ({ date, changeYear, changeMonth, prevMonthButtonDisabled, nextMonthButtonDisabled }) => {
        let date_ = null;

        if (isDate(date)) {
            date_ = date;
        } else {
            date_ = new Date(date);
        }
        const handleDecreaseDate = () => {
            if (showYearPicker) {
                changeYear(+date_?.getFullYear() - 12);
                updateEvent({ selectedDate: date_?.setFullYear(date_?.getFullYear() - 12) });
            } else if (showMonthYearPicker && !prevMonthButtonDisabled) {
                const _month = date_?.getMonth() === 0 ? 11 : +date_?.getMonth() - 1;
                changeMonth(_month);
                if (date_?.getMonth() === 0) {
                    date_ = new Date(date_.setFullYear(date_?.getFullYear() - 1));
                }
                date_ = new Date(date_.setMonth(_month));
                updateEvent({
                    selectedDate: date_,
                    inputValue: getDateFormat({ date_, seperator: '/', beginningDate: 'day' }),
                });
                setChangedSelectedDate(date_);
                setInputValueDate(getDateFormat({ date_, seperator: '/', beginningDate: 'day' }));
            } else if (showDayPicker) {
                changeMonth(date_?.getMonth() === 0 ? 11 : +date_?.getMonth() - 1);
                updateEvent({ selectedDate: date_?.setMonth(date_?.getMonth() - 1) });
            }
        };

        const handleIncreaseDate = () => {
            if (showYearPicker) {
                changeYear(+date_?.getFullYear() + 12);
                updateEvent({ selectedDate: date_?.setFullYear(date_?.getFullYear() + 12) });
            } else if (showMonthYearPicker && !nextMonthButtonDisabled) {
                const _month = date_?.getMonth() === 11 ? 0 : +date_?.getMonth() + 1;
                changeMonth(_month);
                if (date_?.getMonth() === 11) {
                    date_ = new Date(date_.setFullYear(date_?.getFullYear() + 1));
                }
                date_ = new Date(date_.setMonth(_month));
                updateEvent({
                    selectedDate: date_,
                    inputValue: getDateFormat({ date_, seperator: '/', beginningDate: 'day' }),
                });
                setChangedSelectedDate(date_);
                setInputValueDate(getDateFormat({ date_, seperator: '/', beginningDate: 'day' }));
            } else if (showDayPicker) {
                changeMonth(date_?.getMonth() === 11 ? 0 : +date_?.getMonth() + 1);
                updateEvent({ selectedDate: date_?.setMonth(date_?.getMonth() + 1) });
            }
        };

        const years = words && words['WEB.years'];
        const pickerMonth = date_ ? new Date(date_)?.toLocaleDateString(CURRENT_LANGUAGE, { month: 'long' }) : '';
        const pickerYear = date_ ? new Date(date_)?.getFullYear() : '';
        if (pickerYear?.toString()?.length > 4) {
            updateEvent({ selectedDate: date?.setFullYear(pickerYear.toString().substring(0, 4)) });
        }

        return (
            <div className="f-datepicker-custom-header">
                <a className="c-item-001" href="#!" onClick={handleDecreaseDate}>
                    <NavigateBefore />
                </a>

                {!showYearPicker && (
                    <span
                        className="c-item-002"
                        onClick={() => updateEvent({ showYearPicker: true, showDayPicker: false, showMonthYearPicker: false })}>
                        {pickerMonth} {pickerYear}
                    </span>
                )}

                {showYearPicker && <span className="c-item-002-year">{years}</span>}

                <a className="c-item-001" href="#!" onClick={handleIncreaseDate}>
                    <NavigateNext />
                </a>
            </div>
        );
    };

    const handleChangeRaw = e => {
        let inputVal = e?.target?.value;
        if (showYearPicker && !inputVal) {
            updateEvent({
                selectedDate,
                inputValue: getDateFormat({ date_: selectedDate, seperator: '/', beginningDate: 'day' }),
                showMonthYearPicker: true,
                showYearPicker: false,
                showDayPicker: false,
                openDatePicker: true,
            });
        } else if (showMonthYearPicker && !inputVal) {
            updateEvent({
                selectedDate,
                inputValue: getDateFormat({ date_: selectedDate, seperator: '/', beginningDate: 'day' }),
                showMonthYearPicker: false,
                showDayPicker: true,
                openDatePicker: true,
            });
        } else if (showDayPicker && !inputVal) {
            updateEvent({
                selectedDate,
                inputValue: getDateFormat({ date_: selectedDate, seperator: '/', beginningDate: 'day' }),
                showDayPicker: false,
                openDatePicker: false,
            });
        }

        if (inputVal?.length === 0) {
            updateEvent({ selectedDate: null, inputValue: null, openDatePicker: false });
        }
        //!datepicker closed..
        if (inputVal?.length >= 1) {
            updateEvent({ openDatePicker: false });
        }
        const splitted = inputVal?.split('/');

        //!rakam kontrolü
        inputVal = inputVal?.replace(/[^0-9/]/g, '');

        if (inputVal?.length === 4) {
            //! 12/  **0,1
            if (+splitted[1] >= 2) {
                splitted[1] = '1';
            }

            //!day
            if (+splitted[0] >= 3 && +splitted[1] > 2) {
                const newSplitted = splitted[0].split('');
                newSplitted[0] = 3;
                newSplitted[1] = 1;
                splitted[0] = newSplitted?.join('');
            } else if (+splitted[0] === 0 && +splitted[1] === 0) {
                const newSplitted = splitted[0].split('');
                newSplitted[0] = 0;
                newSplitted[1] = 1;
                splitted[0] = newSplitted?.join('');
            }
        } else if (inputVal?.length === 5) {
            //!month
            //! case1=> 12/1 **0,1,2   case2=> 12/0  **1,2,3,4,5,6,7,8,9
            if (+splitted[1] > 2) {
                const newSplitted = splitted[1].split('');

                if (+newSplitted[0] > 1 && +newSplitted[1] > 2) {
                    //20
                    newSplitted[0] = '1';
                    newSplitted[1] = '2';
                    splitted[1] = newSplitted?.join('');
                } else if (+newSplitted[0] === 0 && +newSplitted[1] === 0) {
                    //00
                    newSplitted[1] = '1';
                    splitted[1] = newSplitted?.join('');
                } else if (+newSplitted[0] === 1 && +newSplitted[1] > 2) {
                    //13
                    newSplitted[1] = '2';
                    splitted[1] = newSplitted?.join('');
                }
            }
            //!day
            if (+splitted[0][0] >= 3 && +splitted[0][1] > 2) {
                const newSplitted = splitted[0].split('');
                newSplitted[0] = 3;
                newSplitted[1] = 1;
                splitted[0] = newSplitted?.join('');
            } else if (+splitted[0] === 0 && +splitted[1] === 0) {
                const newSplitted = splitted[0].split('');
                newSplitted[0] = 0;
                newSplitted[1] = 1;
                splitted[0] = newSplitted?.join('');
            }
        } else if (inputVal?.length === 8) {
            //!month
            if (splitted[1] === '') {
                splitted[1] = '01';
            }

            if (Number.isNaN(+splitted[1])) {
                splitted[1] = '01';
            }

            //!day
            if (splitted[0] === '') {
                splitted[0] = '01';
            }

            if (Number.isNaN(+splitted[0])) {
                splitted[0] = '01';
            }
            if (Number.isNaN(+splitted[2])) {
                splitted[2] = '2000';
            }
        } else if (inputVal?.length === 9) {
            //!month
            if (+splitted[1] >= 12) {
                splitted[1] = '12';
            } else if (splitted[1].length > 0 && +splitted[1] < 10 && !splitted[1].includes('0')) {
                splitted[1] = '0' + inputVal[3];
            } else if (splitted[1].length === 1 && +splitted[1] < 10 && splitted[1].includes('0')) {
                splitted[1] = '01';
            } else if (splitted[0].length === 1 && splitted[1].length === 2 && splitted[1].length > 1) {
                splitted[0] = '01';
            }

            if (splitted[1].length >= 2 && +splitted[1] === 0) {
                splitted[1] = '01';
            }

            if (splitted[0]?.length === 2 && splitted[1]?.length === 0 && splitted[2]?.length === 0 && splitted[3]?.length > 0) {
                splitted.splice(1, 2, '01');
            }

            if (splitted[0]?.length === 0 && splitted[1]?.length === 0 && splitted[2]?.length > 0 && splitted[3]?.length > 0) {
                splitted.splice(0, 2, '01');
            }

            if (Number.isNaN(+splitted[1])) {
                splitted[1] = '01';
            }
            //!day
            if (+splitted[0] < 10 && +splitted[0] > 0 && !splitted[0].includes('0')) {
                splitted[0] = '0' + inputVal[0];
            } else if (+splitted[0] === 0) {
                splitted[0] = '01';
            }

            if (Number.isNaN(+splitted[0])) {
                splitted[0] = '01';
            }
            if (Number.isNaN(+splitted[2])) {
                splitted[2] = '2000';
            }
            //!year
            // if (splitted[0]?.length === 2 && splitted[1]?.length === 2 && splitted[2]?.length === 3) {
            //     if (+splitted?.[2][0] === 0) {
            //         splitted[2] = '2' + splitted[2];
            //     } else {
            //         splitted[2] = '1' + splitted[2];
            //     }
            // }
        } else if (inputVal?.length === 10) {
            //!year
            if (Number.isNaN(+splitted[2])) {
                splitted[2] = '2000';
            }

            //!controll first digit day '/'
            if (splitted[0]?.length === 0 && splitted[1].length > 0 && splitted[2].length > 0 && splitted[3].length > 0) {
                splitted?.shift();
                splitted[0] = '0' + splitted[0];
            }
            //!controll  second digit day '/'
            if (splitted[0]?.length === 1 && splitted[1].length === 0 && splitted[2].length > 0 && splitted[3].length > 0) {
                splitted?.splice(1, 1);
                splitted[0] += '1';
            }
            //!controll  first digit month '/'
            if (splitted[0]?.length === 2 && splitted[1].length === 0 && splitted[2].length === 1 && splitted[3].length > 0) {
                splitted?.splice(1, 1);
                splitted[1] = '01';
            }
            //!controll  second digit month '/'
            if (splitted[0]?.length === 2 && splitted[1].length === 1 && splitted[2].length === 0 && splitted[3].length > 0) {
                splitted?.splice(2, 1);
                splitted[1] += '1';
            }

            //!controll  first digit year '/'
            if (splitted[0]?.length === 2 && splitted[1].length === 2 && splitted[2].length === 0 && splitted[3].length > 0) {
                splitted?.splice(2, 1);
                if (+splitted?.[2]?.[0] === 0) {
                    splitted[2] = '2' + splitted[2];
                } else {
                    splitted[2] = '1' + splitted[2];
                }
            }

            //!controll  second digit year '/'
            if (splitted[0]?.length === 2 && splitted[1].length === 2 && splitted[2].length === 1 && splitted[3].length > 0) {
                if (+splitted?.[2] === 2) {
                    splitted[2] = splitted[2] + '0' + splitted[3];
                    splitted?.pop();
                } else if (+splitted?.[2] === 1) {
                    splitted[2] = splitted[2] + '9' + splitted[3];
                    splitted?.pop();
                }
            }
            //!controll  third digit year '/'
            if (splitted[0]?.length === 2 && splitted[1].length === 2 && splitted[2].length === 2 && splitted[3].length === 1) {
                if (+splitted?.[2][0] === 2) {
                    splitted[2] = splitted[2] + '2' + splitted[3];
                    splitted?.pop();
                } else if (+splitted?.[2][0] === 1) {
                    splitted[2] = splitted[2] + '9' + splitted[3];
                    splitted?.pop();
                }
            }

            const newSplitted = splitted[2].split(''); //2950

            if (splitted[1].length >= 2 && +splitted[1] === 0) {
                splitted[1] = '01';
            } else if (splitted[1].length > 2 && +splitted[1] > 0) {
                splitted[1] = splitted[1].substring(1);
            } else if (splitted[1].length === 2 && +splitted[1] > 12) {
                splitted[1] = '12';
            }

            if (splitted[0]?.length >= 2 && +splitted[0] === 0) {
                splitted[0] = '01';
            } else if (splitted[0]?.length > 2 && +splitted[0] > 0 && +splitted[0] < 31) {
                splitted[0] = splitted[0].substring(1);
            } else if (splitted[0]?.length > 2 && +splitted[0] > 0 && +splitted[0] > 31) {
                splitted[0] = '31';
            }

            if (splitted && +splitted[2][0] >= 2) {
                newSplitted[0] = '2';
                newSplitted[1] = '0';
                splitted[2] = newSplitted?.join('');
            }
            if (splitted && +splitted[2][0] === 0) {
                if (+newSplitted[1] < 9) {
                    newSplitted[0] = '1';
                    newSplitted[1] = '9';
                } else {
                    newSplitted[0] = '2';
                }
                splitted[2] = newSplitted?.join('');
            }
            if (splitted && +splitted[2][0] === 1) {
                if (+newSplitted[1] < 9) {
                    newSplitted[1] = '9';
                } else if (+newSplitted[1] == 9) {
                    newSplitted[0] = '1';
                }
                splitted[2] = newSplitted?.join('');
            }
            if (splitted && +splitted[2][0] === 2) {
                if (+newSplitted[1] >= 1) {
                    newSplitted[1] = '0';
                }
                splitted[2] = newSplitted?.join('');
            }
            if (splitted && +splitted[2][0] > 2) {
                newSplitted[0] = '2';
                newSplitted[1] = '0';

                splitted[2] = newSplitted?.join('');
            }
            if (splitted && +splitted[2][1] === 0) {
                newSplitted[0] = '2';
                splitted[2] = newSplitted?.join('');
            }
            if (splitted && +splitted[2][1] > 0) {
                newSplitted[0] = '1';
                splitted[2] = newSplitted?.join('');
            }

            //!month
            if (+splitted[1] > 12) {
                splitted[1] = '12';
            }

            if (Number.isNaN(+splitted[1])) {
                splitted[1] = '01';
            }
            //!day
            if (+splitted[0] > 31) {
                splitted[0] = '31';
            }

            if (Number.isNaN(+splitted[0])) {
                splitted[0] = '01';
            }
            inputVal = splitted.join('/');
        } else if (inputVal?.length >= 11) {
            const checkNumberVal = Number.isNaN(+splitted[0]) || Number.isNaN(+splitted[1]) || Number.isNaN(+splitted[2]);
            if (!checkNumberVal) {
                //!year control
                if (splitted[0]?.length === 2 && splitted[1]?.length === 2 && splitted[2]?.length > 4) {
                    if (+splitted[2][0] === 2 && +splitted[2] > 2050) {
                        splitted[2] = '2000';
                    } else if (+splitted[2][0] === 1 && +splitted[2] < 1900) {
                        splitted[2] = '1900';
                    }
                } else if (splitted[0]?.length === 2 && splitted[1]?.length >= 3 && splitted[2]?.length === 4) {
                    if (+splitted[1] > 12) {
                        splitted[1] = '12';
                    }
                } else if (splitted[0]?.length === 3 && splitted[1]?.length === 2 && splitted[2]?.length === 4) {
                    if (+splitted[0] > 31) {
                        splitted[0] = '31';
                    }
                }
            } else {
                inputVal = '';
            }
        }

        inputVal = splitted?.join('/');

        // //!regex set.
        const generalRegex = /^[0-9]+$/;
        const firstDigitRegex = /^[0-3]+$/;
        const secondDigitRegex = +inputVal?.charAt(0) === 0 ? /^[1-9]+$/ : +inputVal?.charAt(0) <= 2 ? /^[0-9]+$/ : /^[0-1]+$/;
        const thirdDigitRegex = /^[0-1]+$/;
        const forthDigitRegex = inputVal?.length === 5 && +splitted[1][0] === 1 ? /^[0-2]+$/ : /^[1-9]+$/;
        const yearFirstDigitRegex = /^[1-2]+$/;
        const yearSecondDigitRegex = inputVal?.length === 8 && +inputVal?.charAt(6) === 1 ? /^[9]+$/ : /^[0]+$/;
        const yearThirdDigitRegex = inputVal?.length === 9 && +inputVal?.charAt(6) === 1 ? /^[0-9]+$/ : /^[0-5]+$/;

        if (inputVal?.length === 1 && !firstDigitRegex.test(inputVal?.charAt(0))) {
            inputVal = inputVal.replace(inputVal?.charAt(0), '');
        } else if (inputVal?.length === 2 && !secondDigitRegex.test(inputVal?.charAt(1))) {
            inputVal = inputVal.replace(inputVal?.charAt(1), '');
        } else if (inputVal?.length === 4 && !thirdDigitRegex.test(inputVal?.charAt(3))) {
            inputVal = inputVal.replace(inputVal?.charAt(3), '');
        } else if (inputVal?.length === 5 && !forthDigitRegex.test(inputVal[4])) {
            inputVal = inputVal.substring(0, 4);
        } else if (inputVal?.length === 7 && !yearFirstDigitRegex.test(inputVal?.charAt(6))) {
            inputVal = inputVal.substring(0, 6);
        } else if (inputVal?.length === 8 && !yearSecondDigitRegex.test(inputVal?.charAt(7))) {
            inputVal = inputVal.substring(0, 7);
        } else if (inputVal?.length === 9 && !yearThirdDigitRegex.test(inputVal?.charAt(8))) {
            inputVal = inputVal.substring(0, 8);
        } else if (inputVal?.length === 10 && !generalRegex.test(inputVal?.charAt(9))) {
            inputVal = inputVal.substring(0, 9);
        }

        if (inputVal?.length === 2 && !inputVal?.includes('/')) {
            //! 15
            inputVal += '/'; //! 15/
        } else if (inputVal?.length > 2 && inputVal?.length < 5) {
            //! 15/1
            inputVal = inputVal.replace('/', '');
            inputVal = inputVal.slice(0, 2) + '/' + inputVal.slice(2);
        } else if (inputVal?.length === 5) {
            //! 15/12
            inputVal = inputVal.replace('/', ''); //2512
            inputVal = inputVal.slice(0, 2) + '/' + inputVal.slice(2) + '/'; //! 15/12/
        } else if (inputVal?.length > 5 && inputVal?.length < 10) {
            //! 15/12/2            //! 12/35/

            inputVal = inputVal.replace(/[/]/g, ''); //! 15122
            inputVal = inputVal.slice(0, 2) + '/' + inputVal.slice(2, 4) + '/' + inputVal.slice(4); //! 15/12/2002
        } else if (inputVal?.length > 11) {
            //! 15/12/20201
            inputVal = inputVal.substring(0, 11); //! 15/12/2020
        }
        setInputValueDate(inputVal);
        updateEvent({ inputValue: inputVal });

        const day_ = inputVal?.substring(0, 2);
        const month_ = inputVal?.substring(3, 5);
        const year_ = inputVal?.substring(6, 10);
        //!state update
        if (inputVal === undefined && !showYearPicker && showMonthYearPicker) {
            updateEvent({ showMonthYearPicker: false, showDayPicker: true, openDatePicker: true });
        }
        if (Number.isNaN(+year_) || Number.isNaN(+month_) || Number.isNaN(+day_)) {
            setState(prevState => ({
                ...prevState,
                [name]: '',
            }));
        } else if ((name === 'userPetBirthDate' || name === 'celebrateBirthdate' || name === 'userChildBirthDate') && inputVal?.length === 0) {
            setState(prevState => ({
                ...prevState,
                [name]: null,
            }));
        } else if (inputVal?.length < 10 || inputVal?.length > 10) {
            setState(prevState => ({
                ...prevState,
                [name]: '',
            }));
        } else if (inputVal && inputVal?.length === 10 && !Number.isNaN(+year_) && !Number.isNaN(+month_) && !Number.isNaN(+day_)) {
            //!send  date picker  format

            const formattedDate = new Date(year_, month_ - 1, day_);
            setState(prevState => ({
                ...prevState,
                [name]: formattedDate,
            }));
            updateEvent({ selectedDate: formattedDate });
        } else if (inputVal === undefined) {
            updateEvent({ inputValue: inputVal });
        }
    };

    const onDateChange = (date: Date | null) => {
        setChangedSelectedDate(date);
        const inputValueDate_ = date?.getDate()?.toString();
        if ((inputValue === null || inputValue === undefined) && date) {
            updateEvent({ selectedDate: date, inputValue: inputValueDate_ });
        } else if ((inputValue?.length === 0 || !inputValue) && date) {
            updateEvent({ selectedDate: date, showDayPicker: false });
        }

        if (showYearPicker) {
            updateEvent({
                showYearPicker: false,
                showDayPicker: false,
                openDatePicker: true,
                showMonthYearPicker: true,
                inputValue: date ? getDateFormat({ date_: date, beginningDate: 'day', seperator: '/' }) : null,
                selectedDate: date,
            });
        } else if (showMonthYearPicker) {
            updateEvent({
                showMonthYearPicker: false,
                openDatePicker: true,
                showYearPicker: false,
                showDayPicker: true,
                inputValue: date ? getDateFormat({ date_: date, beginningDate: 'day', seperator: '/' }) : null,
                selectedDate: date,
            });
        } else if (showDayPicker) {
            updateEvent({
                showDayPicker: false,
                showYearPicker: false,
                showMonthYearPicker: false,
                openDatePicker: false,
                inputValue: inputValueDate,
                selectedDate: date,
            });
        }
    };

    const handleOnInputClick = () => {
        updateEvent({
            openDatePicker: true,
            showDayPicker: inputValueDate && inputValueDate?.length <= 10 ? true : false,
        });
    };

    return (
        <>
            <div
                id="f-datepicker"
                className={
                    (name === 'celebrateBirthdate' || name === 'userPetBirthDate') &&
                        inputValueDate &&
                        inputValueDate?.length >= 1 &&
                        inputValueDate?.length !== 10 &&
                        isTouched
                        ? 'datepicker-02 f-birthdate-error'
                        : !(name === 'userPetBirthDate' || name === 'celebrateBirthdate') &&
                            isTouched &&
                            (!inputValueDate || (inputValueDate?.length >= 1 && inputValueDate?.length !== 10))
                            ? 'datepicker-02 error'
                            : 'datepicker-02'
                }>
                <DatePicker
                    value={inputValueDate}
                    renderCustomHeader={customHeader}
                    onInputClick={handleOnInputClick}
                    isClearable
                    open={openDatePicker}
                    showPopperArrow={false}
                    locale={CURRENT_LANGUAGE}
                    selected={changedSelectedDate}
                    onChange={onDateChange}
                    onChangeRaw={e => handleChangeRaw(e)}
                    placeholderText={words && words['WEB.noInformationShared']}
                    showMonthYearPicker={showMonthYearPicker}
                    showYearPicker={showYearPicker}
                    showFullMonthYearPicker={showMonthYearPicker}
                    dateFormatCalendar="MMMM yyyy"
                    dateFormat="dd/MM/yyyy"
                    onClickOutside={() => updateEvent({ openDatePicker: false, showDayPicker: false })}
                    yearItemNumber={12}
                    showIcon
                    icon={<CalendarIcon onClick={() => updateEvent({ openDatePicker: isEdit, showDayPicker: isEdit })} />}
                />
                <span
                    className={
                        ((name === 'celebrateBirthdate' || name === 'userPetBirthDate') &&
                            inputValue &&
                            inputValueDate?.length >= 1 &&
                            inputValueDate?.length !== 10 &&
                            isTouched) ||
                            (!(name === 'celebrateBirthdate' || name === 'userPetBirthDate') &&
                                ((inputValue && inputValueDate?.length >= 1 && inputValueDate?.length !== 10) || !inputValueDate) &&
                                isTouched)
                            ? 'validation-error-message-02 error'
                            : 'validation-error-message-02'
                    }>
                    {words && words['WEB.dateFormatText']}
                </span>
            </div>
        </>
    );
};

export default CustomReactDatePicker;
