import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import React from 'react';
import { isMacOs } from 'react-device-detect';
import { EmailShareButton } from 'react-share';

import linkedin from '../../_assets/img/linkedn.png';
import { useAppSelector } from '../../store';

const NewsShareModal = ({ newsOpenModal, newsModalData, closeModal }: any) => {
    const { words } = useAppSelector(state => state.localization);

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className="modal-08"
            open={newsOpenModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}>
            <Fade in={newsOpenModal}>
                <div className={isMacOs ? 'modal-08-content isMacOs' : 'modal-08-content'}>
                    <div className="c-wrapper">
                        <div className="modal-item">
                            <button onClick={() => closeModal()} className="close-icon">
                                <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M17.4882 18.8672L32.8551 34.2341L34.9764 32.1128L19.6096 16.7459L34.2341 2.12138L32.1128 5.7195e-05L17.4882 14.6246L2.86366 0L0.742336 2.12132L15.3669 16.7459L0 32.1128L2.12132 34.2341L17.4882 18.8672Z"
                                        fill="black"
                                    />
                                </svg>
                            </button>

                            <div className="c-item-01">
                                <img loading="lazy" className="c-item-01-A" src={newsModalData?.image || newsModalData?.imageUrl} />
                            </div>

                            <div className="c-item-02">
                                <div className="c-item-02-A">{newsModalData?.header}</div>

                                <div className="c-item-02-B">
                                    <svg
                                        className="c-unit-01"
                                        width="160"
                                        height="1"
                                        viewBox="0 0 160 1"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <line x1="-4.37114e-08" y1="0.5" x2="160" y2="0.499986" stroke="#C1C1C1" />
                                    </svg>

                                    <span className="c-unit-02">{words && words['WEB.SHARE']}</span>

                                    <svg
                                        className="c-unit-03"
                                        width="160"
                                        height="1"
                                        viewBox="0 0 160 1"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <line x1="-4.37114e-08" y1="0.5" x2="160" y2="0.499986" stroke="#C1C1C1" />
                                    </svg>
                                </div>

                                <div className="c-item-02-C">
                                    <div className="c-unit-01">
                                        <EmailShareButton
                                            subject="İçinde Koç Var'da bu haberi görmelisin!"
                                            body={`Merhaba!

İçinde Koç Var'da bu haberi görmelisin!

${newsModalData?.header}

`}
                                            url={`${newsModalData?.shareUrl}`}
                                            className="c-unit-01-A">
                                            <svg width="36" height="33" viewBox="0 0 36 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M35.3254 17.3957C35.3264 17.26 35.2923 17.1263 35.2264 17.0076C35.1605 16.8889 35.065 16.7892 34.9493 16.7182H34.9452L34.9304 16.71L22.6934 9.46633C22.6406 9.43063 22.5857 9.398 22.5291 9.36861C22.2963 9.24847 22.038 9.18579 21.776 9.18579C21.514 9.18579 21.2558 9.24847 21.0229 9.36861C20.9663 9.39801 20.9115 9.43064 20.8587 9.46633L8.62169 16.71L8.60692 16.7182C8.23292 16.9508 8.11826 17.4425 8.35083 17.8165C8.41936 17.9267 8.51374 18.0185 8.6258 18.084L20.8628 25.3276C20.9158 25.363 20.9707 25.3956 21.0271 25.4254C21.2599 25.5455 21.5181 25.6082 21.7802 25.6082C22.0422 25.6082 22.3004 25.5455 22.5333 25.4254C22.5897 25.3956 22.6445 25.363 22.6975 25.3276L34.9345 18.084C35.0546 18.014 35.1541 17.9134 35.2227 17.7925C35.2914 17.6716 35.3268 17.5347 35.3254 17.3957Z"
                                                    fill="#0A2767"
                                                />

                                                <path
                                                    d="M10.0519 12.5592H18.0823V19.9203H10.0519V12.5592ZM33.6865 5.07658V1.70934C33.7058 0.867456 33.0394 0.169099 32.1976 0.148926H11.3585C10.5166 0.169099 9.85026 0.867456 9.86955 1.70934V5.07658L22.1887 8.36167L33.6865 5.07658Z"
                                                    fill="#0364B8"
                                                />

                                                <path d="M9.86914 5.07666H18.0819V12.4681H9.86914V5.07666Z" fill="#0078D4" />

                                                <path
                                                    d="M26.2909 5.07666H18.0781V12.4681L26.2909 19.8596H33.6824V12.4681L26.2909 5.07666Z"
                                                    fill="#28A8EA"
                                                />

                                                <path d="M18.0781 12.468H26.2909V19.8595H18.0781V12.468Z" fill="#0078D4" />

                                                <path d="M18.0781 19.8596H26.2909V27.2511H18.0781V19.8596Z" fill="#0364B8" />

                                                <path d="M10.0488 19.9204H18.0793V26.6122H10.0488V19.9204Z" fill="#14447D" />

                                                <path d="M26.293 19.8596H33.6844V27.2511H26.293V19.8596Z" fill="#0078D4" />

                                                <path
                                                    d="M34.9525 18.0388L34.9369 18.047L22.6999 24.9293C22.6465 24.9622 22.5923 24.9934 22.5356 25.0213C22.3278 25.1203 22.1021 25.1761 21.872 25.1855L21.2035 24.7946C21.147 24.7663 21.0922 24.7347 21.0393 24.7002L8.63802 17.6224H8.63227L8.22656 17.3958V31.3279C8.23289 32.2574 8.99132 33.0059 9.92084 33H33.6606C33.6746 33 33.6869 32.9934 33.7017 32.9934C33.898 32.9808 34.0916 32.9405 34.2766 32.8735C34.3565 32.8396 34.4337 32.7995 34.5073 32.7536C34.5624 32.7224 34.6568 32.6542 34.6568 32.6542C34.8643 32.5007 35.0331 32.3008 35.1497 32.0706C35.2663 31.8403 35.3276 31.586 35.3286 31.3279V17.3958C35.3285 17.5266 35.2936 17.6552 35.2275 17.7681C35.1614 17.8811 35.0665 17.9745 34.9525 18.0388Z"
                                                    fill="url(#paint0_linear_4195_3058)"
                                                />

                                                <path
                                                    opacity="0.5"
                                                    d="M34.6687 17.3408V18.1949L21.8732 27.0047L8.62604 17.6282C8.62604 17.626 8.62517 17.624 8.62363 17.6224C8.62209 17.6209 8.62 17.62 8.61782 17.62L7.40234 16.8891V16.2731L7.90332 16.2649L8.96276 16.8726L8.98739 16.8809L9.07772 16.9384C9.07772 16.9384 21.5282 24.0424 21.5611 24.0588L22.0374 24.338C22.0785 24.3216 22.1196 24.3052 22.1688 24.2887C22.1935 24.2723 34.529 17.3325 34.529 17.3325L34.6687 17.3408Z"
                                                    fill="#0A2767"
                                                />

                                                <path
                                                    d="M34.9525 18.0388L34.9369 18.0478L22.6999 24.9301C22.6465 24.963 22.5923 24.9942 22.5356 25.0221C22.3011 25.1367 22.0435 25.1962 21.7825 25.1962C21.5215 25.1962 21.2639 25.1367 21.0294 25.0221C20.9731 24.9942 20.9183 24.9635 20.8651 24.9301L8.62816 18.0478L8.61339 18.0388C8.49738 17.9759 8.40032 17.8831 8.3323 17.77C8.26427 17.6569 8.22777 17.5277 8.22656 17.3958V31.3279C8.23244 32.2572 8.99061 33.0059 9.91996 33H33.6352C34.5645 33.0059 35.3227 32.2573 35.3286 31.3279C35.3286 31.3279 35.3286 31.3279 35.3286 31.3279V17.3958C35.3285 17.5266 35.2936 17.6552 35.2275 17.7681C35.1614 17.8811 35.0665 17.9745 34.9525 18.0388Z"
                                                    fill="#1490DF"
                                                />

                                                <path
                                                    opacity="0.1"
                                                    d="M22.8757 24.8281L22.6926 24.9308C22.6395 24.9646 22.5847 24.9956 22.5283 25.0236C22.3266 25.1226 22.1072 25.1807 21.8828 25.1944L26.5386 30.7002L34.6602 32.6573C34.8828 32.4892 35.0598 32.2682 35.1752 32.0143L22.8757 24.8281Z"
                                                    fill="black"
                                                />

                                                <path
                                                    opacity="0.05"
                                                    d="M23.7052 24.3618L22.6926 24.931C22.6395 24.9648 22.5847 24.9957 22.5283 25.0238C22.3266 25.1228 22.1072 25.1809 21.8828 25.1946L24.0641 31.2088L34.6627 32.655C34.8686 32.5004 35.0358 32.3 35.1509 32.0697C35.2661 31.8393 35.3261 31.5854 35.3263 31.3279V31.148L23.7052 24.3618Z"
                                                    fill="black"
                                                />

                                                <path
                                                    d="M9.94302 33H33.6327C33.9972 33.0018 34.3527 32.8867 34.647 32.6715L21.2027 24.7963C21.1462 24.7679 21.0914 24.7364 21.0384 24.7018L8.63719 17.6241H8.63144L8.22656 17.3958V31.2802C8.22564 32.2291 8.99412 32.9991 9.94302 33C9.94301 33 9.94301 33 9.94302 33Z"
                                                    fill="#28A8EA"
                                                />

                                                <path
                                                    opacity="0.1"
                                                    d="M19.7244 8.22463V25.7424C19.723 26.3566 19.3495 26.9087 18.7799 27.1386C18.6035 27.2144 18.4135 27.2535 18.2215 27.2536H8.22656V7.54051H9.86912V6.71924H18.2215C19.0512 6.72238 19.7226 7.39493 19.7244 8.22463Z"
                                                    fill="black"
                                                />

                                                <path
                                                    opacity="0.2"
                                                    d="M18.9031 9.04574V26.5635C18.9052 26.7619 18.8631 26.9583 18.7799 27.1384C18.5519 27.7006 18.0069 28.0694 17.4002 28.0722H8.22656V7.54035H17.4002C17.6385 7.53797 17.8734 7.59739 18.0819 7.71283C18.5853 7.96646 18.903 8.48202 18.9031 9.04574Z"
                                                    fill="black"
                                                />

                                                <path
                                                    opacity="0.2"
                                                    d="M18.9031 9.04574V24.921C18.8991 25.7503 18.2295 26.4225 17.4002 26.4297H8.22656V7.54035H17.4002C17.6385 7.53797 17.8734 7.59739 18.0819 7.71283C18.5853 7.96646 18.903 8.48202 18.9031 9.04574Z"
                                                    fill="black"
                                                />

                                                <path
                                                    opacity="0.2"
                                                    d="M18.0819 9.04568V24.9209C18.081 25.7515 17.4095 26.4255 16.5789 26.4296H8.22656V7.54028H16.5789C17.4094 7.54073 18.0823 8.21436 18.0818 9.04487C18.0819 9.04513 18.0819 9.0454 18.0819 9.04568Z"
                                                    fill="black"
                                                />

                                                <path
                                                    d="M1.51711 7.54028H16.5744C17.4058 7.54028 18.0798 8.21428 18.0798 9.04568V24.1029C18.0798 24.9343 17.4058 25.6083 16.5744 25.6083H1.51711C0.685698 25.6083 0.0117188 24.9343 0.0117188 24.1029V9.04568C0.0117188 8.21428 0.685717 7.54028 1.51711 7.54028Z"
                                                    fill="url(#paint1_linear_4195_3058)"
                                                />

                                                <path
                                                    d="M4.71771 13.8543C5.08873 13.0638 5.68763 12.4022 6.43747 11.9547C7.26788 11.4793 8.21338 11.2422 9.16986 11.2697C10.0564 11.2505 10.9311 11.4752 11.6986 11.9193C12.4201 12.3497 13.0013 12.9802 13.3715 13.7344C13.7747 14.5654 13.9756 15.48 13.9579 16.4035C13.9774 17.3686 13.7707 18.3249 13.3543 19.1958C12.9752 19.977 12.3756 20.63 11.6296 21.0741C10.8325 21.5318 9.92531 21.7625 9.00643 21.741C8.10099 21.7628 7.20695 21.5355 6.42187 21.0839C5.69405 20.6531 5.1056 20.0218 4.72676 19.2656C4.32122 18.4466 4.11788 17.5423 4.1338 16.6285C4.11691 15.6716 4.31652 14.7232 4.71771 13.8543ZM6.5508 18.3138C6.74862 18.8135 7.08408 19.247 7.51826 19.5638C7.96048 19.8729 8.49003 20.0323 9.0294 20.0188C9.60381 20.0415 10.17 19.8766 10.6424 19.549C11.0711 19.2332 11.3977 18.7986 11.5819 18.299C11.7878 17.7411 11.8895 17.1501 11.8817 16.5554C11.8881 15.9551 11.7925 15.3581 11.5992 14.7897C11.4284 14.2766 11.1123 13.8241 10.6892 13.4872C10.2286 13.1441 9.66396 12.97 9.09017 12.9944C8.53912 12.9801 7.99772 13.1408 7.54372 13.4535C7.1022 13.7716 6.76037 14.2089 6.55819 14.7141C6.10969 15.8723 6.10734 17.1557 6.55161 18.3154L6.5508 18.3138Z"
                                                    fill="white"
                                                />

                                                <path d="M26.293 5.07666H33.6844V12.4681H26.293V5.07666Z" fill="#50D9FF" />

                                                <defs>
                                                    <linearGradient
                                                        id="paint0_linear_4195_3058"
                                                        x1="21.7776"
                                                        y1="17.3958"
                                                        x2="21.7776"
                                                        y2="33"
                                                        gradientUnits="userSpaceOnUse">
                                                        <stop stopColor="#35B8F1" />

                                                        <stop offset="1" stopColor="#28A8EA" />
                                                    </linearGradient>

                                                    <linearGradient
                                                        id="paint1_linear_4195_3058"
                                                        x1="3.15051"
                                                        y1="6.36399"
                                                        x2="14.941"
                                                        y2="26.7846"
                                                        gradientUnits="userSpaceOnUse">
                                                        <stop stopColor="#1784D9" />

                                                        <stop offset="0.5" stopColor="#107AD5" />

                                                        <stop offset="1" stopColor="#0A63C9" />
                                                    </linearGradient>
                                                </defs>
                                            </svg>
                                        </EmailShareButton>

                                        <span className="c-unit-01-B">OUTLOOK</span>
                                    </div>

                                    <div className="c-unit-01">
                                        <a
                                            target="_blank"
                                            rel="noreferrer"
                                            className="c-unit-01-A"
                                            href={`https://api.whatsapp.com/send/?text=${newsModalData?.header} - ${newsModalData?.shareUrl}`}>
                                            <svg width="33" height="34" viewBox="0 0 33 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    fillRule="evenodd"
                                                    clipRule="evenodd"
                                                    d="M28.1917 4.8185C25.09 1.71279 20.9647 0.0015757 16.5693 0C7.51217 0 0.141026 7.37037 0.137874 16.4299C0.136298 19.326 0.893426 22.1528 2.33126 24.644L0 33.1591L8.7105 30.8743C11.1103 32.1838 13.8126 32.8731 16.5622 32.874H16.5693C25.6249 32.874 32.9969 25.5028 33 16.4433C33.0016 12.0526 31.2943 7.92501 28.1917 4.81929V4.8185ZM16.5693 30.0991H16.5638C14.1136 30.0984 11.7099 29.4397 9.61263 28.1957L9.11389 27.8995L3.94478 29.2553L5.32431 24.2154L4.99971 23.6986C3.63279 21.5242 2.91032 19.0108 2.9119 16.4307C2.91505 8.90116 9.0414 2.77481 16.5749 2.77481C20.2226 2.77639 23.6514 4.19847 26.23 6.78026C28.8086 9.36128 30.2275 12.7932 30.226 16.4417C30.2228 23.972 24.0965 30.0984 16.5693 30.0984V30.0991ZM24.0602 19.8712C23.6497 19.6656 21.6313 18.6729 21.2547 18.5358C20.8781 18.3987 20.6047 18.3302 20.3313 18.7414C20.058 19.1527 19.2709 20.0777 19.0314 20.351C18.7919 20.6252 18.5523 20.6591 18.1418 20.4534C17.7314 20.2478 16.4086 19.8145 14.84 18.416C13.6197 17.3272 12.7955 15.9832 12.556 15.5719C12.3165 15.1607 12.5308 14.9385 12.7356 14.7344C12.92 14.5501 13.1461 14.2546 13.3517 14.0151C13.5574 13.7756 13.6251 13.6039 13.7622 13.3304C13.8993 13.0563 13.8308 12.8168 13.7284 12.6111C13.6259 12.4055 12.805 10.3846 12.4623 9.56294C12.129 8.7625 11.7903 8.87122 11.5389 8.85783C11.2994 8.84601 11.026 8.84365 10.7519 8.84365C10.4777 8.84365 10.0333 8.94607 9.65672 9.35733C9.28015 9.76855 8.21967 10.762 8.21967 12.7821C8.21967 14.8021 9.69063 16.7553 9.89624 17.0295C10.1019 17.3036 12.7915 21.4501 16.9097 23.2291C17.889 23.6521 18.654 23.905 19.2504 24.0941C20.2337 24.4069 21.1286 24.3628 21.8361 24.2572C22.6248 24.139 24.2651 23.2637 24.607 22.3049C24.9489 21.3461 24.9489 20.5235 24.8465 20.3526C24.7441 20.1816 24.47 20.0784 24.0595 19.8728L24.0602 19.8712Z"
                                                    fill="#25D366"
                                                />
                                            </svg>
                                        </a>

                                        <span className="c-unit-01-B">WHATSAPP</span>
                                    </div>

                                    <div className="c-unit-01">
                                        <a
                                            target="_blank"
                                            rel="noreferrer"
                                            className="c-unit-01-A"
                                            href={`https://www.linkedin.com/sharing/share-offsite/?url=${newsModalData?.shareUrl}`}>
                                            <img className="linkedinIcon" src={linkedin} />
                                        </a>

                                        <span className="c-unit-01-B">LINKEDIN</span>
                                    </div>

                                    <div className="c-unit-01">
                                        <a
                                            target="_blank"
                                            rel="noreferrer"
                                            className="c-unit-01-A"
                                            href={`https://twitter.com/intent/tweet?url=${newsModalData?.shareUrl}`}>
                                            <svg
                                                fill="#010101"
                                                version="1.1"
                                                id="Layer_1"
                                                xmlns="http://www.w3.org/2000/svg"
                                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                                x="0px"
                                                y="0px"
                                                width={'35px'}
                                                height={'35px'}
                                                viewBox="0 0 36 30"
                                                xmlSpace="preserve">
                                                <g>
                                                    <path
                                                        className="st0"
                                                        d="M26.97,0.86h4.74L21.27,12.82l12.34,16.23h-9.68l-7.59-9.77L7.8,29.14H3.05l11.2-12.81L2.39,0.86h9.87
		l6.83,9.01L26.97,0.86z M25.26,26.2h2.66L10.74,3.61H7.89L25.26,26.2z"
                                                    />
                                                </g>
                                            </svg>
                                        </a>

                                        <span className="c-unit-01-B">X</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fade>
        </Modal>
    );
};

export default NewsShareModal;
