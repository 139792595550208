import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import React, { FC } from 'react';

import { IAppDetailModal } from '../../models/components/details/AppDetailModal';

const AppDetailModal: FC<IAppDetailModal> = ({ updateEvent, event }) => {
    const { iframeUrl, openAppDetailModal } = event;
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className="modal-05"
            onClose={() => updateEvent({ openAppDetailModal: false })}
            open={openAppDetailModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}>
            <Fade in={openAppDetailModal}>
                <>
                    <div className="modal-content-05">
                        <button className="search-close-icon" onClick={() => updateEvent({ openAppDetailModal: false })}>
                            <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="48" height="48" rx="24" fill="black" />
                                <g clipPath="url(#clip0_4028_10926)">
                                    <path
                                        d="M31 18.41L29.59 17L24 22.59L18.41 17L17 18.41L22.59 24L17 29.59L18.41 31L24 25.41L29.59 31L31 29.59L25.41 24L31 18.41Z"
                                        fill="white"
                                    />
                                </g>
                                <defs>
                                    <clipPath id="clip0_4028_10926">
                                        <rect width="24" height="24" fill="white" transform="translate(12 12)" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </button>
                        <div className="iframe-content" dangerouslySetInnerHTML={{ __html: iframeUrl }}></div>
                    </div>
                </>
            </Fade>
        </Modal>
    );
};

export default AppDetailModal;
