import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Navigation, Mousewheel, Keyboard, Autoplay, Pagination } from 'swiper';
import 'swiper/modules/autoplay/autoplay.scss';
import { Swiper, SwiperSlide } from 'swiper/react';

import { scrollToTop } from '../../_helpers/useful-functions';
import { useAppSelector } from '../../store';

const ResponsiveSliderApps = () => {
    const [activeSlide, setActiveSlide] = useState(1);
    const { applications }: any = useAppSelector(state => state.hr);
    const { words } = useAppSelector(state => state.localization);
    const [swiper, setSwiper] = useState(null);

    const handleNextClick = () => {
        if (swiper) {
            swiper.slideNext();
        }
    };

    const handlePrevClick = () => {
        if (swiper) {
            swiper.slidePrev();
        }
    };

    //! slider news pause - start begin..
    const sliderNews = document.querySelector('.comp-carousel-12');
    if (sliderNews) {
        const sliderNewsTop = sliderNews.getBoundingClientRect()?.top;
        if (sliderNewsTop < 280 && sliderNewsTop > -300 && !swiper?.autoplay?.running) {
            swiper?.autoplay?.start();
        } else if ((sliderNewsTop >= 280 || sliderNewsTop <= -301) && swiper?.autoplay?.running) {
            swiper?.autoplay?.stop();
        }
    }
    //! slider news pause - start end..

    return (
        <div className="comp-carousel-12">
            <div className="c-wrapper">
                <div className="c-item-text">
                    <h6 className="c-item-title">{words && words['WEB.DigitalApps']}</h6>
                    <p className="c-item-desc">{words && words['WEB.KocAppsTextBody']}</p>
                </div>
                <Swiper
                    className="swiper-app"
                    pagination={{
                        type: 'progressbar',
                    }}
                    simulateTouch={false}
                    modules={[Navigation, Mousewheel, Keyboard, Autoplay, Pagination]}
                    autoplay={{
                        delay: 5000,
                        disableOnInteraction: false,
                        stopOnLastSlide: false,
                    }}
                    loop
                    lazy
                    navigation={{ nextEl: 'button-next', prevEl: 'button-prev' }}
                    loopedSlides={applications?.items?.length}
                    onSlideChange={(e: any) => {
                        setActiveSlide(e.realIndex + 1);
                    }}
                    onSwiper={(swiper_: any) => {
                        setSwiper(swiper_);
                    }}>
                    {applications?.items?.length > 0 &&
                        applications?.items?.map((item, index) => (
                            <SwiperSlide key={'news-' + index}>
                                <Link
                                    onClick={() => scrollToTop()}
                                    to={item?.cardId ? { pathname: `/${words && words['WEB.cardDetails']}/${item?.cardId}` } : { pathname: '/' }}
                                    className="c-item">
                                    {item?.imageUrl.endsWith('.svg') ||
                                    item?.imageUrl.endsWith('.png') ||
                                    item?.imageUrl.endsWith('.jpeg') ||
                                    item?.imageUrl.endsWith('.jpg') ? (
                                        <img loading="lazy" className="c-item-01" src={item?.imageUrl} />
                                    ) : (
                                        <video className="c-item-01" controls={false} autoPlay={false}>
                                            <source src={item?.imageUrl} type="video/mp4" />
                                        </video>
                                    )}
                                    {item?.iconUrl2 && <img className="c-item-02" src={item?.iconUrl2} />}
                                    <div className="c-item-03">
                                        <h2 className="c-item-03-A">{item?.header}</h2>
                                        <p className="c-item-03-B">{item?.digitalPlatformBody}</p>
                                    </div>
                                </Link>
                            </SwiperSlide>
                        ))}
                    <div className="c-item-04">
                        <div className="slider-app-fragtion">
                            <span className="c-item-01">{activeSlide < 10 ? '0' + activeSlide : activeSlide}</span>
                            <span className="c-item-02">/</span>
                            {applications?.items?.length < 10 ? '0' + (applications?.items?.length || '') : applications?.items?.length}
                        </div>
                        <div className="button-group-app">
                            <button onClick={handlePrevClick} className="c-item-01">
                                <svg width="15" height="26" viewBox="0 0 15 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M14 24.25L2 13L14 1.75001" stroke="black" strokeWidth="2" strokeLinecap="round" />
                                </svg>
                            </button>

                            <button onClick={handleNextClick} className="c-item-01">
                                <svg width="15" height="26" viewBox="0 0 15 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1 24.25L13 13L1.00001 1.75001" stroke="black" strokeWidth="2" strokeLinecap="round" />
                                </svg>
                            </button>
                        </div>
                    </div>
                </Swiper>
            </div>
        </div>
    );
};

export default ResponsiveSliderApps;
