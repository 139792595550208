import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { userId } from '../../../_helpers/useful-functions';
import { get } from '../../../core/client';
import { getLocalStorageItem } from '../../../core/utility';
import { ILocalizationWords } from './model';

export const getLocalizationWords: any = createAsyncThunk('localization/getLocalizationWords', async () => {
    const culture = getLocalStorageItem('SelectedLanguage') ?? 'tr-TR';
    const URL = `localization/list?Version=0&Platform=WEB&Culture=${culture}&UpdateDbLanguage=${true}${
        !userId ? '&SecureCode=A1CD8C128C2D3139E32A4D4A59843' : ''
    }`;
    const localizationWords = await get<any>(URL);
    return localizationWords;
});

const localizationSlice = createSlice({
    name: 'localization',
    initialState: {
        words: {},
        loading: true,
        languageInitialData: [
            { culture: 'tr-TR', name: 'Türkçe', cultureName: 'TR' },
            { culture: 'en-US', name: 'İngilizce', cultureName: 'EN' },
        ],
        currentLanguage: 'tr-TR',
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getLocalizationWords.fulfilled, (state, action: PayloadAction<ILocalizationWords>) => {
            if (action.payload.isSuccessful) {
                const words = {};
                for (let i = 0; i < action.payload.result.list.length; i++) {
                    const element: any = action.payload.result.list[i];
                    words[element.key] = element.value;
                }
                state.words = words;
                state.loading = false;
            }
        });
    },
});

export default localizationSlice.reducer;
