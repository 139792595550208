import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';

import { scrollToTop } from '../../_helpers/useful-functions';
import { get } from '../../core/client';
import { getLocalStorageItem } from '../../core/utility';
import { getLeftMenuList } from '../../store/features/hrDetail/hrDetailsSlice';
import NewsShareModal from './NewsShareModal';
import { isMacOs } from 'react-device-detect';

const NewsDetail = (props: any) => {
    const [newsDetailData, setNewsDetailData] = useState(null);
    const [isNewsOpenModal, setIsNewsOpenModal] = useState<boolean>(false);
    const dispatch = useDispatch();
    const culture = getLocalStorageItem('SelectedLanguage');

    useEffect(() => {
        scrollToTop();
        dispatch(getLeftMenuList());
        if (props?.match?.params?.newId) {
            getNewsDetail(props?.match?.params?.newId);
        }
    }, [culture]);

    const getNewsDetail = async newId_ => {
       
        await get<any>(`/hr/get-news-detail-with-id?id=` + newId_).then(res => {
            if (res.isSuccessful) {
                setNewsDetailData(res?.result?.item);
            }
        });
    };

    return (
        <>
            <NewsShareModal newsModalData={newsDetailData} closeModal={() => setIsNewsOpenModal(false)} newsOpenModal={isNewsOpenModal} />
            <div className={ isMacOs ? "news-detail-01 isMacOs" : "news-detail-01" } id="the-news-details">
                <Helmet>
                    <title>içinde Koç Var</title>
                    <meta name="description" content={`${newsDetailData?.shortText}`} />
                    <meta property="og:url" content={`${window?.location?.href}`} />
                    <meta property="og:type" content="website" />
                    <meta property="og:title" content={`${newsDetailData?.shortText}`} />
                    <meta property="og:description" content={`${newsDetailData?.header}`} />
                    <meta property="twitter:image" content={`${newsDetailData?.imageUrl}`} />
                    <meta property="og:image:secure_url" content={`${newsDetailData?.imageUrl}`} />
                    <meta property="og:image" content={`${newsDetailData?.imageUrl}`} />
                </Helmet>
                <div className="c-wrapper">
                    <img loading="lazy" className="c-item-01" src={newsDetailData?.imageUrl} />
                    <img loading="lazy" className="c-item-01-responsive" src={newsDetailData?.imageUrl} />
                    <div className="c-item">
                        <div className="c-item-02">
                            <h6 className="c-unit-01">{newsDetailData?.header}</h6>
                            <div className="c-unit-02">
                                <svg
                                    onClick={() => setIsNewsOpenModal(true)}
                                    width="44"
                                    height="44"
                                    viewBox="0 0 44 44"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M31 16H27.8C26.1198 16 25.2798 16 24.638 16.327C24.0735 16.6146 23.6146 17.0735 23.327 17.638C23 18.2798 23 19.1198 23 20.8V22M31 16L28 13M31 16L28 19M20 13H17.8C16.1198 13 15.2798 13 14.638 13.327C14.0735 13.6146 13.6146 14.0735 13.327 14.638C13 15.2798 13 16.1198 13 17.8V26.2C13 27.8802 13 28.7202 13.327 29.362C13.6146 29.9265 14.0735 30.3854 14.638 30.673C15.2798 31 16.1198 31 17.8 31H26.2C27.8802 31 28.7202 31 29.362 30.673C29.9265 30.3854 30.3854 29.9265 30.673 29.362C31 28.7202 31 27.8802 31 26.2V24"
                                        stroke="white"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            </div>
                        </div>
                        <div className="c-item-03" dangerouslySetInnerHTML={{ __html: newsDetailData?.text }} />
                    </div>
                </div>
            </div>
        </>
    );
};

export default NewsDetail;
