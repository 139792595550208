import React from 'react';
import { isMacOs } from 'react-device-detect';
import { useDispatch } from 'react-redux';

import footerResponsive from '../../../_assets/img/footer-koc-logo.svg';
import { userId } from '../../../_helpers/useful-functions';
import { useAppSelector } from '../../../store';
import { setOpenAccordionCookiesOptions, setOpenCookiesModal } from '../../../store/features/user/usersSlice';
import WeatherWidget from '../../weather';

const Footer = () => {
    const { footerList }: any = useAppSelector(state => state.hr);
    const { words } = useAppSelector(state => state.localization);
    const contactList = footerList?.items?.contacts;
    const dispatch = useDispatch();

    const createCookiesNameValue = (name: string, value: string | boolean) => {
        document.cookie = name + `=${value}; Path=/; Expires=Thu, 01 Jan 2025 00:00:01 GMT;`;
    };

    const handleClickOpenCookieModal = _index => {
        if (_index === 1) {
            dispatch(setOpenCookiesModal(true));
            dispatch(setOpenAccordionCookiesOptions(false));
            createCookiesNameValue('mandatoryAccepted', false);
        }
    };

    return (
        <footer className={isMacOs ? 'footer-01 isMacOs' : 'footer-01'}>
            <div className="c-wrapper">
                <a href="https://www.koc.com.tr/" target="_blank" rel="noreferrer" className="c-container-01">
                    <img
                        loading="lazy"
                        className="footer-logo"
                        src="https://khicindekocvar.blob.core.windows.net/cdn/prod/footer-left-koc-logo.jpg"
                        alt="logo"
                    />
                    <img
                        loading="lazy"
                        className="mobil-footer-logo"
                        src="https://khicindekocvar.blob.core.windows.net/cdn/prod/footer-left-koc-logo.jpg"
                        alt="logo"
                    />
                </a>
                <div className="c-container-02">
                    <h6 className="c-item-01">{words && words['WEB.corporation']}</h6>
                    {footerList?.items?.corporation?.length > 0 &&
                        footerList?.items?.corporation?.map((item, index) => (
                            <a
                                key={'corporation-' + item?.title}
                                onClick={() => handleClickOpenCookieModal(index)}
                                className="c-item-02"
                                target={index === 0 ? '_blank' : ''}
                                href={
                                    index === 0 ? 'https://khicindekocvar.blob.core.windows.net/cdn/prod/KH_icindeKocVar_Aydinlatma_Metni.pdf' : '#!'
                                }
                                rel="noreferrer">
                                {item?.title}
                            </a>
                        ))}
                    {userId && (
                        <div id="weather-d-none">
                            <WeatherWidget />
                        </div>
                    )}
                </div>
                {userId && (
                    <div className="c-container-03">
                        <h6 className="c-item-01">{words && words['WEB.contactUs']}</h6>

                        <a
                            className="c-item-02"
                            href={'tel:' + contactList?.find(x => x?.key === 'WEB.Telephone')?.value}
                            target="_blank"
                            rel="noreferrer">
                            {contactList?.find(x => x?.key === 'WEB.Telephone')?.title}
                        </a>

                        <a className="c-item-02" href={contactList?.find(x => x?.key === 'WEB.Whatsapp')?.value} target="_blank" rel="noreferrer">
                            {contactList?.find(x => x?.key === 'WEB.Whatsapp')?.title}
                        </a>
                        <a
                            className="c-item-02"
                            href={'mailto:' + contactList?.find(x => x?.key === 'WEB.Mail')?.value}
                            target="_blank"
                            rel="noreferrer">
                            {contactList?.find(x => x?.key === 'WEB.Mail')?.title}
                        </a>
                    </div>
                )}
                <div className="c-container-04">
                    <h6 className="c-item-01">{words && words['WEB.followUs']}</h6>
                    <p className="c-item-02">
                        {footerList?.items?.followUs?.length > 0 && footerList?.items?.followUs?.find((x: any) => x?.key === 'Web.FollowUs')?.title}
                    </p>
                </div>
                <div className="c-container-05">
                    <div className="c-item-01">
                        {footerList?.items?.socialMedia?.length > 0 &&
                            footerList?.items?.socialMedia?.map(item => (
                                <a key={item?.key} href={item?.value} className="c-item" target="_blank" rel="noreferrer">
                                    <img loading="lazy" className="c-item-01" src={item?.iconUrl} alt={item.key} />
                                </a>
                            ))}
                    </div>

                    <div className="c-item-02">
                        <img
                            loading="lazy"
                            className="footer-logo-right"
                            src="https://khicindekocvar.blob.core.windows.net/cdn/prod/footer-koc-logo.png"
                            alt="logo"
                        />
                    </div>
                </div>
                <img loading="lazy" className="mobil-footer-logo-bottom" src={footerResponsive} alt="logo" />
            </div>
        </footer>
    );
};

export default Footer;
