import { TextField } from '@material-ui/core';
import React, { FC } from 'react';

import CustomReactDatePicker from '../../components/custom-react-datepicker';
import { IMaritalStatus } from '../../models/profile/maritalStatus';
import { useAppSelector } from '../../store';

const MaritalStatus: FC<IMaritalStatus> = ({ isTouched, userInfo, isEdit, handleChange, setUserInfo }) => {
    const { words } = useAppSelector(state => state.localization);

    return (
        <>
            <div className="c-item-02-A-02">
                <div className={isTouched && (userInfo?.spouseFirstname === '' || !userInfo?.spouseFirstname) ? 'c-unit-01 error' : 'c-unit-01'}>
                    <label className="label-01">{words && words['WEB.spouseName']}</label>
                    <TextField
                        disabled={!isEdit}
                        className={
                            isTouched && (userInfo?.spouseFirstname === '' || !userInfo?.spouseFirstname) ? 'textfield-01 error' : 'textfield-01'
                        }
                        id="partnerName"
                        name="spouseFirstname"
                        onChange={handleChange}
                        placeholder={words && words['WEB.noInformationShared']}
                        value={userInfo.spouseFirstname}
                    />
                    {isTouched && (userInfo?.spouseFirstname === '' || !userInfo?.spouseFirstname) && (
                        <span className="validation-error-message-01">*{words && words['WEB.requiredField']}</span>
                    )}
                </div>
                <div className={isTouched && (userInfo?.spouseLastname === '' || !userInfo?.spouseLastname) ? 'c-unit-01 error' : 'c-unit-01'}>
                    <label className="label-01">{words && words['WEB.spouseLastName']}</label>
                    <TextField
                        disabled={!isEdit}
                        className={
                            isTouched && (userInfo?.spouseLastname === '' || !userInfo?.spouseLastname) ? 'textfield-01 error' : 'textfield-01'
                        }
                        id="spouseLastname"
                        name="spouseLastname"
                        onChange={handleChange}
                        placeholder={words && words['WEB.noInformationShared']}
                        value={userInfo.spouseLastname}
                    />
                    {isTouched && (userInfo?.spouseLastname === '' || !userInfo?.spouseLastname) && (
                        <span className="validation-error-message-01">*{words && words['WEB.requiredField']}</span>
                    )}
                </div>
                <div className={isTouched && userInfo.dateOfMarriage === '' ? 'c-unit-01 error' : 'c-unit-01'}>
                    <label className="label-01">{words && words['WEB.marriedDate']}</label>
                    <CustomReactDatePicker
                        stateDate={userInfo?.dateOfMarriage}
                        name="dateOfMarriage"
                        setState={setUserInfo}
                        isEdit={isEdit}
                        isTouched={isTouched}
                    />

                    {isTouched && userInfo?.dateOfMarriage === null && (
                        <span className="validation-error-message-01">*{words && words['WEB.requiredField']}</span>
                    )}
                </div>
            </div>
        </>
    );
};

export default MaritalStatus;
