interface IAppDetailSlider {
    sliderData: IAppDetailSliderData;
}

interface IApplication {
    androidApplicationId?: string;
    androidApplicationURLScheme?: string;
    appStoreUrl?: string;
    authorizationDetail?: string;
    body?: string;
    buttonTitle?: string;
    cardId?: string;
    digitalPlatformBody?: string;
    displayOrder?: number;
    googlePlayUrl?: string;
    header?: string;
    huaweiAppUrl?: string;
    huaweiApplicationId?: string;
    huaweiApplicationURLScheme?: string;
    iconUrl?: string;
    iconUrl2?: string;
    id?: string;
    imageUrl?: string;
    iosApplicationId?: string;
    iosApplicationURLScheme?: string;
    isAuthorization?: boolean;
    isFavorite?: boolean;
    mobilIcon?: string;
    mobilIcon2?: string;
    url?: string;
    urlAdd?: boolean;
}

interface ICardExplorer {
    applicationId?: string;
    body?: string;
    cardType?: number;
    displayOrder?: number;
    icon?: string;
    id?: string;
    image?: string;
    name?: string;
    title?: string;
}

interface IHRCatiLeftMenuWidgetMedias {
    body?: string;
    displayOrder?: number;
    id?: string;
    ikCatiLeftMenuWidgetId?: string;
    image?: string;
    title?: string;
    url?: string;
    urlVideo?: string;
}

interface IAppDetailSliderData {
    application?: IApplication;
    body?: string;
    cardExplorer?: ICardExplorer[];
    cards?: ICardExplorer[];
    displayOrder?: number;
    icon?: string;
    id?: string;
    ikCatiLeftMenuId?: string;
    ikCatiLeftMenuWidgetMedias?: IHRCatiLeftMenuWidgetMedias[];
    image?: string;
    mobileResponsiveImage?: string;
    name?: string;
    smallImage?: string;
    title?: string;
    url?: string;
    widgetType?: number;
}

interface IAppStateReducer {
    iframeUrl?: string;
    openAppDetailModal?: boolean;
    index?:number
}

const appStateReducer = (prev: IAppStateReducer, next: Partial<IAppStateReducer>): IAppStateReducer => {
    return { ...prev, ...next };
};

export type { IAppDetailSlider, IApplication, ICardExplorer };
export  { appStateReducer };
