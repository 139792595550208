import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import { useDispatch } from 'react-redux';

import { trackButtonClick2 } from '../../../_helpers/trackButtonClick2';
import { trackButtonClick3 } from '../../../_helpers/trackButtonClick3';
import { trackButtonClick4 } from '../../../_helpers/trackButtonClick4';
import { trackButtonClick5 } from '../../../_helpers/trackButtonClick5';
import { analiticsLog, userId } from '../../../_helpers/useful-functions';
import PrivateRoute from '../../../components/private-route';
import { getLocalStorageItem, setLocalStorageItem } from '../../../core/utility';
import Cookies from '../../../pages/cookies-accordion-modal';
import NotSupportPage from '../../../pages/notSupportPage';
import { useAppSelector } from '../../../store';
import { getBannerList, getFooterList, getLandingPageList } from '../../../store/features/hr/hrSlice';
import { getLocalizationWords } from '../../../store/features/localization/localizationSlice';
import { getGlobalLoginId, getPrivacyPreference, getUserInfo } from '../../../store/features/user/usersSlice';
import Spinner from '../../ui/spinner';
import AsideMenu from './asideMenu';
import Footer from './footer';
import Header from './header';

const Layout1 = ({ component: Component, ...rest }: any) => {
    const [windowSize, setWindowSize] = useState({ width: undefined });
    const { userInfo } = useAppSelector(state => state.users.info);
    const { landingPageList } = useAppSelector(state => state.hr);
    const { loading } = useAppSelector(state => state.localization);
    const culture = getLocalStorageItem('SelectedLanguage');

    const dispatch = useDispatch();
    const isRetry = getLocalStorageItem('isRetry');

    //! analitcs..

    useEffect(() => {
        // Google Analytics'i başlatın ve takip kodunu ayarlayın
        ReactGA.initialize('G-ZJ7JDJMY6Z');

        // Sayfa görüntülenmesini izlemek isterseniz
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    const trackButtonClick = () => {
        // Düğmeye tıklama olayını gönderin
        ReactGA.event({
            category: 'User_Interaction_web3',
            action: 'Button_Clicked_web3',
            label: 'ana_sayfa_ziyareti_web3', // İsteğe bağlı
        });
    };
    //! analitcs..
    useEffect(() => {
        if (landingPageList?.col1?.length > 0) {
            setTimeout(() => {
                dispatch(getBannerList());
                dispatch(getFooterList());
            }, 3000);
        }
    }, [landingPageList?.col1?.length > 0, culture]);

    useEffect(() => {
        analiticsLog();
        trackButtonClick();
        trackButtonClick2();
        trackButtonClick3();
        trackButtonClick4();
        trackButtonClick5();
        dispatch(getLocalizationWords());
        if (userId) {
            dispatch(getGlobalLoginId());
        }
    }, []);

    useEffect(() => {
        dispatch(getPrivacyPreference());
        if (!userId) {
            dispatch(getBannerList());
            dispatch(getFooterList());
        } else {
            dispatch(getUserInfo());
            dispatch(getLandingPageList());
        }
    }, [culture]);

    useEffect(() => {
        if (userInfo?.userLanguage && userInfo?.userLanguage !== 'null' && !isRetry) {
            setLocalStorageItem('SelectedLanguage', userInfo?.userLanguage);
            dispatch(getLocalizationWords());
            setLocalStorageItem('isRetry', true);
        }
    }, [userInfo?.userLanguage]);

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
            });
        }
        setWindowSize({ width: window.innerWidth });
        window.addEventListener('resize', handleResize);
        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <PrivateRoute
            {...rest}
            render={props => (
                <>
                    {(windowSize.width < 840 && userId) ||
                        (!userId && windowSize.width > 600 && windowSize.width < 800) ? (
                        <>
                            <NotSupportPage />
                        </>
                    ) : loading ? (
                        <Spinner type="03" />
                    ) : (
                        <div id="page-wrapper">
                            <Header {...props} />
                            <AsideMenu />
                            <Component {...props} />
                            <Cookies />
                            <Footer />
                        </div>
                    )}
                </>
            )}
        />
    );
};

export default Layout1;
