import { getLocalStorageItem } from '../core/utility';
import { IDateFormat } from '../models/helpers/useful-funtions';

const scrollToTop = (behavior: any = 'auto') => {
    let scrollElement: any = document.getElementById('root');
    const isHome = scrollElement?.classList?.contains('home');

    if (isHome) {
        scrollElement = window;
    }

    scrollElement?.scroll({
        top: 0,
        left: 0,
        behavior,
    });
};

const getDateFormat = ({ date_, seperator = '-', beginningDate = 'year' }: IDateFormat) => {
    if (!date_) return null;
    if (date_ === undefined) return null;
    if (new Date(date_).getFullYear() < 1900) return 'Invalid Date';

    const month = new Date(date_).getMonth() + 1;
    const year = new Date(date_).getFullYear();
    const day = new Date(date_).getDate();
    if (beginningDate === 'year') {
        return year + seperator + (month < 10 ? '0' + month : month) + seperator + (day < 10 ? '0' + day : day);
    } else {
        return (day < 10 ? '0' + day : day) + seperator + (month < 10 ? '0' + month : month) + seperator + year;
    }
};

const isValidDate = (date: string) => {
    const dateformat = /^(0?[1-9]|[1-2][0-9]|3[01])[/](0?[1-9]|1[0-2])/;

    // Matching the date through regular expression
    if (date.match(dateformat)) {
        const operator = date.split('/');

        // Extract the string into month, date and year
        let datepart = [];
        if (operator.length > 1) {
            datepart = date.split('/');
        }
        const day = parseInt(datepart[0]);
        const month = parseInt(datepart[1]);
        const year = parseInt(datepart[2]);

        // Create a list of days of a month
        const ListofDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
        if (month === 1 || month > 2) {
            if (day > ListofDays[month - 1]) {
                //to check if the date is out of range
                console.log('Invalid date');
                return false;
            }
        } else if (month === 2) {
            let leapYear = false;
            if ((!(year % 4) && year % 100) || !(year % 400)) leapYear = true;
            if (leapYear === false && day >= 29) {
                console.log('Invalid date');
                return false;
            } else if (leapYear === true && day > 29) {
                console.log('Invalid date format!');
                return false;
            }
        }
    } else {
        return false;
    }
    return true;
};

//! all cookies listed..
const getAllCookies = () => {
    const cookies = document.cookie.split(';');

    const cookieList = cookies.map(cookie => {
        const [name, value] = cookie.trim().split('=');
        return { name, value };
    });

    return cookieList;
};

//! check cookies name
const cookieNameToCheck = cookieName => {
    const cookies = document.cookie.split(';');
    const cookieExists = cookies.some(cookie => {
        const [name] = cookie.trim().split('=');
        return name === cookieName;
    });
    return cookieExists;
};

//! userId
const userId = getLocalStorageItem('USER_ID');

//! find brave browser
const isBrave = () => {
    let newVariable: any = null;
    newVariable = window.navigator;
    if (newVariable?.brave?.isBrave?.name === 'isBrave') {
        return true;
    } else {
        return false;
    }
};

//! googleAnalistic Log
declare global {
    interface Window {
        dataLayer: any;
    }
}

const turkishChars = {
    ç: 'c',
    ğ: 'g',
    ı: 'i',
    ö: 'o',
    ş: 's',
    ü: 'u',
};

const analiticsLog = (applicationName = 'Ana Sayfa Ziyareti') => {
    //applicationName ==> Koç Ailem==>koc_ailem_web
    const appName =
        applicationName
            .toLowerCase()
            .replace(/\s+/g, '_')
            .replace(/[çğıöşü]/g, char => turkishChars[char] || char) + '_web';

    window?.dataLayer?.push({
        event: appName,
        // pagePath: 'https://www.googleanalytics.dev/pancakes',
        // pageTitle: 'Pancake Event Signup',
        // visitorType: 'customer',
    });
};

function isDate(obje: any): obje is Date {
    return obje instanceof Date && !isNaN(obje.getTime());
}

function onlyNumberRegex(value) {
    //! only number regex
    const numberDesign = /^\d*$/;

    // Girilen değer regex deseniyle eşleşirse, input değerini kabul et
    if (numberDesign.test(value)) {
        console.log('Geçerli sayı girişi: ' + value);
    } else {
        console.log('Geçersiz giriş! Sadece sayı giriniz.');
        // İsteğe bağlı olarak geçersiz girişi temizleme veya uyarı mesajı gösterme işlemleri yapılabilir.
    }
}

export { isDate, onlyNumberRegex, userId, cookieNameToCheck, getAllCookies, isValidDate, getDateFormat, scrollToTop, isBrave, analiticsLog };
