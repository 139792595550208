import { Popper, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useState, useEffect } from 'react';

import { ReactComponent as SelectArrowIcon } from '../../_assets/img/select-arrow.svg';
import CustomReactDatePicker from '../../components/custom-react-datepicker';
import { IUserPet, initialUserPet } from '../../models/profile/userPets';
import { useAppSelector } from '../../store';



const UserPets = (props: any) => {
    const [state, setState] = useState<IUserPet>(initialUserPet);
    const { userPetOptions } = useAppSelector(state => state.profile);
    const { words } = useAppSelector(state => state.localization);


    useEffect(() => {
        if (state !== props.data && state !== initialUserPet) {
            props.updateUserPets(state, props.index);
        }
    }, [state]);
    
    useEffect(() => {
        if (
            state.userPetBreed === (words && words['WEB.fish']) ||
            state.userPetBreed === (words && words['WEB.turtle']) ||
            state.userPetBreed === (words && words['WEB.rabbit'])
        ) {
            setState({ ...state, isSelected: true });
        }
    }, [state.userPetBreed]);

    useEffect(() => {
        if (userPetOptions?.items.length > 0 && userPetOptions?.items?.find(x => x.isOther)?.id === state?.userPetBreedId) {
            setState({ ...state, isOther: true });
        } else {
            setState({ ...state, isOther: false });
        }
    }, [state.userPetBreedId]);

    useEffect(() => {
        if (props.data) {
            setState(props.data);
        } else {
            setState(initialUserPet);
        }
    }, []);

    const CustomPopper = function (props) {
        return <Popper {...props} className="dropdown-01" y-placement="bottom" />;
    };

    return (
        <>
            <div className="c-micro-unit-01 ">
                <label className="label-01">{props.index + 1 + '. ' + (words && words['WEB.nameOfPet'])}</label>
                <TextField
                    disabled={!props.isEdit}
                    className={!state.isSelected && props.isTouched && state.userPetName === '' ? 'textfield-01 error' : 'textfield-01'}
                    name="userPetName"
                    placeholder={words && words['WEB.noInformationShared']}
                    onChange={e => setState({ ...state, userPetName: e.target.value })}
                    value={state.userPetName}
                />
                {props.isTouched && !state.userPetName && !state.isSelected && (
                    <span className="validation-error-message-01">*{words && words['WEB.requiredField']}</span>
                )}
            </div>
            <div className="c-micro-unit-01 ">
                <label className="label-01">{words && words['WEB.birtdate']}</label>
                <CustomReactDatePicker
                     name="userPetBirthDate"
                     stateDate={state?.userPetBirthDate}
                     isEdit={props?.isEdit}
                     setState={setState}
                     isTouched={props?.isTouched}
                />
            </div>
            <div className="c-micro-unit-01">
                <label className="label-01">{words && words['WEB.petBreed']}</label>
                <Autocomplete
                    disabled={!props.isEdit}
                    noOptionsText={words && words['WEB.resultNotFounded']}
                    fullWidth={true}
                    PopperComponent={CustomPopper}
                    closeIcon=""
                    id="Pet-count"
                    value={{id: state?.userPetBreedId, name: state?.userPetBreed}}
                    onChange={(e: any, val: any) =>
                        setState({
                            ...state,
                            userPetBreedId: val?.id,
                            userPetBreed: val?.name,
                            isSelected:
                                val.name === (words && words['WEB.fish']) ||
                                val.name === (words && words['WEB.turtle']) ||
                                val.name === (words && words['WEB.rabbit'])
                                    ? true
                                    : false,
                        })
                    }
                    getOptionLabel={(option: any) => (option && option?.name ? option?.name : '')}
                    options={userPetOptions?.items.length > 0 ? userPetOptions?.items : []}
                    className={props.isTouched && state.userPetBreedId === null ? 'autocomplete-01 error' : 'autocomplete-01'}
                    renderInput={params => (
                        <div ref={params.InputProps.ref} className="render-container">
                            <input
                                {...params.inputProps}
                                className="render-input"
                                placeholder={words && words['WEB.choose']}
                            />
                            {props.isEdit && <SelectArrowIcon className="red-arrow" />}
                        </div>
                    )}
                />

                {props.isTouched && !state.userPetBreedId && (
                    <span className="validation-error-message-01">*{words && words['WEB.requiredField']}</span>
                )}
            </div>

            {userPetOptions?.items.length > 0 && userPetOptions?.items?.find(x => x.isOther)?.id === state?.userPetBreedId && (
                <div className={props.isTouched && !state.petBreedName ? 'c-unit-01 form-group-02 error' : 'c-unit-01 form-group-02'}>
                    <label className="label-01">{words && words['WEB.OTHER']}</label>
                    <TextField
                        disabled={!props.isEdit}
                        placeholder={words && words['WEB.noInformationShared']}
                        className={props.isTouched && !state.petBreedName ? 'textfield-01 error' : 'textfield-01'}
                        id="userPed-textfield"
                        value={state.petBreedName}
                        onChange={(e: any) => setState({ ...state, petBreedName: e.target.value })}
                    />
                    {props.isTouched && !state.petBreedName && (
                        <span className="validation-error-message-01">*{words && words['WEB.requiredField']}</span>
                    )}
                </div>
            )}
        </>
    );
};

export default UserPets;
