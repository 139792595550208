import { ScrollPageTop } from '@kocsistem/oneframe-react-bundle';
import React, { useEffect } from 'react';
import { isMobile, isTablet, isMacOs } from 'react-device-detect';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import Layout1 from './components/layouts/layout1';
import Layout2 from './components/layouts/layout2';
import MobileStore from './components/responsive-store-dowland';
import { getStoredLanguage } from './core/utility/translate';
import hashHistory from './history';
import LanguageContext from './language-context';
import CookiekDetailText from './pages/Cookie';
import KvkkDetail from './pages/Kvkk';
import Login from './pages/account/login';
import Logout from './pages/account/logout';
import AppDetail from './pages/appDetail';
import AuthWarning from './pages/authWarning';
import CardDetail from './pages/cardDetail';
import CardDetailKoc from './pages/cardDetailKoc';
import Home from './pages/home';
import News from './pages/news';
import NewsDetail from './pages/news/NewsDetail';
import Profile from './pages/profile';
import KocIframe from './pages/koc-iframe';
import PrivateCourse from './pages/privateCourse';

const App = () => {
    const [language, setLanguage] = React.useState(getStoredLanguage());

    useEffect(() => {
        if (!isMobile && !isTablet) {
            document.querySelector('body').classList.add('not-touch-device');
        }
        if (!isMacOs) {
            document.querySelector('body').classList.add('not-mac-device');
        }
    }, []);

    return (
        <>
            <LanguageContext.Provider value={{ language, setLanguage }}>
                <BrowserRouter history={hashHistory}>
                    <ScrollPageTop scrollElement="root">
                        <Switch>
                            <Layout1 exact path="/" component={Home} />
                            <Layout2 exact path="/profile" component={Profile} />
                            <Layout1 exact path={'/uygulamaDetay/:id'} component={AppDetail} />
                            <Layout1 exact path={'/appDetail/:id'} component={AppDetail} />
                            <Layout1 exact path={'/icerikDetay/:id'} component={CardDetail} />
                            <Layout1 exact path={'/cardDetail/:id'} component={CardDetail} />
                            <Layout1 exact path="/toBeKoc" component={CardDetailKoc} />
                            <Layout1 exact path="/kocluOlmak" component={CardDetailKoc} />
                            <Route exact path={'/kvkk'} component={KvkkDetail} />
                            <Route exact path={'/legal-informations'} component={KvkkDetail} />
                            <Layout1 exact path={'/News'} component={News} />
                            <Layout1 exact path={'/Haberler'} component={News} />
                            <Layout1 exact path={'/haberDetay/:newId'} component={NewsDetail} />
                            <Layout1 exact path={'/newsDetail/:newId'} component={NewsDetail} />
                            <Layout1 exact path={'/ozelders'} component={PrivateCourse} />
                            <Layout1 exact path={'/grouplessons'} component={PrivateCourse} />
                            <Route exact path={'/cerez'} component={CookiekDetailText} />
                            <Route exact path={'/cookie'} component={CookiekDetailText} />
                            <Route exact path="/authWarning" component={AuthWarning} />
                            <Route exact path="/accounts/login" component={Login} />
                            <Route exact path="/accounts/logout" component={Logout} />
                            <Route exact path="/mobileStore" component={MobileStore} />
                            <Route exact path="/:id" component={KocIframe} />
                        </Switch>
                    </ScrollPageTop>
                </BrowserRouter>
            </LanguageContext.Provider>
        </>
    );
};

export default App;
