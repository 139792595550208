import { Accordion, AccordionDetails, AccordionSummary, FormControl, FormControlLabel, RadioGroup, Radio, Popper } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useState, useEffect, FC } from 'react';

import { ReactComponent as SelectArrowIcon } from '../../_assets/img/select-arrow.svg';
import CustomReactDatePicker from '../../components/custom-react-datepicker';
import { initialUserInfo } from '../../models/profile/personalInfo';
import { IProfile } from '../../models/profile/teams';
import { useAppSelector } from '../../store';
import MaritalStatus from './maritalStatus';
import UserChild from './userChild';
import UserPets from './userPets';

const PersonalInfo: FC<IProfile> = ({ setUserInformation, isEdit, isTouched }) => {
    const { words } = useAppSelector(state => state.localization);

    const [userInfo, setUserInfo] = useState<any>(initialUserInfo);
    const [childCount, setChildCount] = useState<any>();
    const [petsCount, setPetsCount] = useState<number>(0);
    const [countOptions, setCountOptions] = useState<any>([]);
    const { profileUserInfo } = useAppSelector(state => state.profile.profileUserData);

    useEffect(() => {
        setCountOptions([
            { value: '0', label: words ? words['WEB.choose'] : '' },
            { value: '1', label: '1' },
            { value: '2', label: '2' },
            { value: '3', label: '3' },
            { value: '4', label: '4' },
            { value: '5', label: '5' },
            { value: '6', label: '6' },
            { value: '7', label: '7' },
            { value: '8', label: '8' },
        ]);
    }, [words]);

    const handleChange = (e: any) => {
        isEdit &&
            setUserInfo(prevState => ({
                ...prevState,
                [e.target.name]: e.target.value === undefined ? e.target.checked : e.target.value,
            }));
    };

    useEffect(() => {
        setUserInfo(profileUserInfo);
        setChildCount(profileUserInfo?.userChildBirthDates?.length);
        setPetsCount(profileUserInfo?.userPetBirthDates?.length);
    }, [profileUserInfo]);

    const updateUserPets = async (data, index) => {
        // Önce userInfo nesnesini kopyalayın
        const updatedUserInfo = { ...userInfo };

        // Kopyalanan nesne içinde userPetBirthDates dizisini kopyalayın
        const updatedUserPetBirthDates = [...updatedUserInfo.userPetBirthDates];

        // İlgili öğeyi güncelleyin
        updatedUserPetBirthDates[index] = data;

        // Kopyalanan nesne içinde userPetBirthDates'i güncelleyin
        updatedUserInfo.userPetBirthDates = updatedUserPetBirthDates;

        // userInfo nesnesini güncelleyin
        setUserInfo(updatedUserInfo);
    };

    const updateUserChildren = async (data, index) => {
        // Önce userInfo nesnesini kopyalayın
        const updatedUserInfo = { ...userInfo };

        // Kopyalanan nesne içinde userChildBirthDates dizisini kopyalayın
        const updatedUserChildBirthDates = [...updatedUserInfo.userChildBirthDates];

        // İlgili öğeyi güncelleyin
        updatedUserChildBirthDates[index] = data;

        // Kopyalanan nesne içinde userChildBirthDates'i güncelleyin
        updatedUserInfo.userChildBirthDates = updatedUserChildBirthDates;

        // userInfo nesnesini güncelleyin
        setUserInfo(updatedUserInfo);
    };

    useEffect(() => {
        const childrenArray = [];

        for (let i = 0; i < childCount; i++) {
            if (i < userInfo?.userChildBirthDates.length) {
                childrenArray.push(userInfo?.userChildBirthDates[i]);
            } else {
                childrenArray.push(null);
            }
        }
        setUserInfo({
            ...userInfo,
            userChildBirthDates: childrenArray,
            childCountInfo: childCount,
        });
    }, [childCount]);

    useEffect(() => {
        const petsArray = [];

        for (let i = 0; i < petsCount; i++) {
            if (i < userInfo?.userPetBirthDates.length) {
                petsArray.push(userInfo?.userPetBirthDates[i]);
            } else {
                petsArray.push(null);
            }
        }
        setUserInfo({
            ...userInfo,
            userPetBirthDates: petsArray,
            petsCountInfo: petsCount,
        });
    }, [petsCount]);

    useEffect(() => {
        setUserInformation(userInfo);
    }, [userInfo]);

    const CustomPopper = props => {
        return <Popper {...props} className="dropdown-01" placement="bottom" />;
    };

    const celebratedBirthdayText: string = isEdit ? words && words['WEB.celebratedMyBirthday'] : words && words['WEB.celebratedBirthday'];

    return (
        <div className="c-item">
            <Accordion defaultExpanded={true}>
                <AccordionSummary expandIcon="" aria-controls="panel1a-content" id="panel1a-header">
                    <div className="c-item-01">
                        <h6 className="c-item-01-A">{words && words['WEB.personalFamilyInformations']}</h6>
                        <div className="c-item-01-B">
                            <svg className="c-unit-01" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M9.29183 3.29196C9.38473 3.19883 9.49508 3.12494 9.61657 3.07453C9.73806 3.02412 9.8683 2.99817 9.99983 2.99817C10.1314 2.99817 10.2616 3.02412 10.3831 3.07453C10.5046 3.12494 10.6149 3.19883 10.7078 3.29196L22.7078 15.292C22.801 15.3848 22.8748 15.4952 22.9253 15.6167C22.9757 15.7382 23.0016 15.8684 23.0016 16C23.0016 16.1315 22.9757 16.2617 22.9253 16.3832C22.8748 16.5047 22.801 16.6151 22.7078 16.708L10.7078 28.708C10.5201 28.8957 10.2654 29.0012 9.99983 29.0012C9.73428 29.0012 9.47961 28.8957 9.29183 28.708C9.10406 28.5202 8.99857 28.2655 8.99857 28C8.99857 27.7344 9.10406 27.4797 9.29183 27.292L20.5858 16L9.29183 4.70796C9.19871 4.61507 9.12482 4.50471 9.07441 4.38322C9.024 4.26173 8.99805 4.13149 8.99805 3.99996C8.99805 3.86842 9.024 3.73818 9.07441 3.61669C9.12482 3.4952 9.19871 3.38485 9.29183 3.29196Z"
                                    fill="#2e333e"
                                />
                            </svg>

                            <svg className="c-unit-02" width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M3.29183 9.79196C3.38473 9.69883 3.49508 9.62494 3.61657 9.57453C3.73806 9.52412 3.8683 9.49817 3.99983 9.49817C4.13137 9.49817 4.26161 9.52412 4.3831 9.57453C4.50459 9.62494 4.61494 9.69883 4.70783 9.79196L15.9998 21.086L27.2918 9.79196C27.3848 9.69898 27.4952 9.62523 27.6167 9.57491C27.7381 9.52459 27.8683 9.49869 27.9998 9.49869C28.1313 9.49869 28.2615 9.52459 28.383 9.57491C28.5045 9.62523 28.6149 9.69898 28.7078 9.79196C28.8008 9.88493 28.8746 9.99531 28.9249 10.1168C28.9752 10.2383 29.0011 10.3685 29.0011 10.5C29.0011 10.6314 28.9752 10.7616 28.9249 10.8831C28.8746 11.0046 28.8008 11.115 28.7078 11.208L16.7078 23.208C16.6149 23.3011 16.5046 23.375 16.3831 23.4254C16.2616 23.4758 16.1314 23.5017 15.9998 23.5017C15.8683 23.5017 15.7381 23.4758 15.6166 23.4254C15.4951 23.375 15.3847 23.3011 15.2918 23.208L3.29183 11.208C3.19871 11.1151 3.12482 11.0047 3.07441 10.8832C3.024 10.7617 2.99805 10.6315 2.99805 10.5C2.99805 10.3684 3.024 10.2382 3.07441 10.1167C3.12482 9.9952 3.19871 9.88485 3.29183 9.79196Z"
                                    fill="#2e333e"
                                />
                            </svg>
                        </div>
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <div className="c-item-02">
                        <div className="c-item-02-A">
                            <div className="form-group-01 c-item-02-A-01 celebration">
                                <FormControl component="fieldset" className="celebrate-birthday-group">
                                    <label className="label-01">{celebratedBirthdayText}</label>
                                    <CustomReactDatePicker
                                        name="celebrateBirthdate"
                                        setState={setUserInfo}
                                        stateDate={userInfo?.celebrateBirthdate}
                                        isEdit={isEdit}
                                        isTouched={isTouched}
                                    />
                                </FormControl>
                            </div>
                            <div className="form-group-01 c-item-02-A-01">
                                <FormControl component="fieldset">
                                    <label className="label-01">
                                        {isEdit ? words && words['WEB.yourMaritialStatus'] : words && words['WEB.MaritialStatus']}
                                    </label>
                                    <RadioGroup
                                        className="radio-button-group-01"
                                        aria-label="maritalStatus"
                                        name="maritalStatus"
                                        value={userInfo.maritalStatus ? userInfo?.maritalStatus?.toString() : '3'}
                                        onChange={handleChange}>
                                        <FormControlLabel
                                            className="radio-button-status"
                                            value="1"
                                            control={<Radio className="radio-button-icon" />}
                                            label={words && words['WEB.married']}
                                        />
                                        <FormControlLabel
                                            className="radio-button-status"
                                            value="2"
                                            control={<Radio className="radio-button-icon" />}
                                            label={words && words['WEB.single']}
                                        />
                                        <FormControlLabel
                                            className="radio-button-status"
                                            value="3"
                                            control={<Radio className="radio-button-icon" />}
                                            label={words && words['WEB.dontShare']}
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </div>
                            {userInfo?.maritalStatus && userInfo?.maritalStatus?.toString() === '1' && (
                                <MaritalStatus
                                    setUserInfo={setUserInfo}
                                    isTouched={isTouched}
                                    userInfo={userInfo}
                                    isEdit={isEdit}
                                    handleChange={handleChange}
                                />
                            )}

                            <>
                                <div className="form-group-01 c-item-02-A-03">
                                    <FormControl component="fieldset">
                                        <label className="label-01">
                                            {isEdit ? words && words['WEB.doYouHaveChild'] : words && words['WEB.hasChild']}
                                        </label>
                                        <RadioGroup
                                            className="radio-button-group-01"
                                            aria-label="hasPet"
                                            name="has-child"
                                            value={userInfo?.hasChildType ? userInfo?.hasChildType : '3'}
                                            onChange={e =>
                                                isEdit &&
                                                setUserInfo(prevState => ({
                                                    ...prevState,
                                                    hasChildType: e.target.value,
                                                }))
                                            }>
                                            <FormControlLabel
                                                className="radio-button-status"
                                                value="1"
                                                control={<Radio className="radio-button-icon" />}
                                                label={words && words['WEB.yes']}
                                            />
                                            <FormControlLabel
                                                className="radio-button-status"
                                                value="2"
                                                control={<Radio className="radio-button-icon" />}
                                                label={words && words['WEB.NO']}
                                            />
                                            <FormControlLabel
                                                className="radio-button-status"
                                                value="3"
                                                control={<Radio className="radio-button-icon" />}
                                                label={words && words['WEB.dontShare']}
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                    {userInfo?.hasChildType === '1' && (
                                        <div className="c-unit-01 form-group-02">
                                            <label className="label-01">{words && words['WEB.numberOfChildren']}</label>
                                            <Autocomplete
                                                noOptionsText={words && words['WEB.resultNotFounded']}
                                                disabled={!isEdit}
                                                fullWidth={true}
                                                PopperComponent={CustomPopper}
                                                closeIcon=""
                                                id="childCount"
                                                value={countOptions[childCount]}
                                                onChange={(e: any, val: any) => setChildCount(parseInt(val?.value))}
                                                getOptionLabel={(option: any) => (option?.label ? option?.label : '')}
                                                options={countOptions ? countOptions : []}
                                                className={
                                                    userInfo?.hasChildType === '1' && childCount === 0 ? 'autocomplete-01 error' : 'autocomplete-01'
                                                }
                                                renderInput={params => (
                                                    <div ref={params.InputProps.ref} className="render-container">
                                                        <input
                                                            {...params.inputProps}
                                                            className="render-input"
                                                            placeholder={words && words['WEB.choose']}
                                                        />
                                                        {isEdit && <SelectArrowIcon className="red-arrow" />}
                                                    </div>
                                                )}
                                            />
                                        </div>
                                    )}
                                </div>

                                <div className="c-item-02-A-04">
                                    {userInfo?.hasChildType === '1' &&
                                        userInfo?.userChildBirthDates?.map((item, index) => (
                                            <div key={'userChildBirthDates' + index} className="c-unit-01-A">
                                                <UserChild
                                                    setUserInfo={setUserInfo}
                                                    isEdit={isEdit}
                                                    key={'userChildBirthDates' + index}
                                                    data={item}
                                                    index={index}
                                                    updateUserChildren={updateUserChildren}
                                                    isTouched={isTouched}
                                                />
                                            </div>
                                        ))}
                                </div>
                            </>

                            <div className="form-group-01 c-item-02-A-05">
                                <FormControl component="fieldset">
                                    <label className="label-01">{isEdit ? words && words['WEB.doYouHavePet'] : words && words['WEB.hasPet']}</label>
                                    <RadioGroup
                                        className="radio-button-group-01"
                                        aria-label="hasPet"
                                        name="hasPet"
                                        value={userInfo.hasPetType ? userInfo?.hasPetType : '5'}
                                        onChange={e =>
                                            isEdit &&
                                            setUserInfo(prevState => ({
                                                ...prevState,
                                                hasPetType: e.target.value,
                                            }))
                                        }>
                                        <FormControlLabel
                                            className="radio-button-status"
                                            value="1"
                                            control={<Radio className="radio-button-icon" />}
                                            label={words && words['WEB.yes']}
                                        />
                                        <FormControlLabel
                                            className="radio-button-status"
                                            value="2"
                                            control={<Radio className="radio-button-icon" />}
                                            label={words && words['WEB.NO']}
                                        />
                                        <FormControlLabel
                                            className="radio-button-status"
                                            value="5"
                                            control={<Radio className="radio-button-icon" />}
                                            label={words && words['WEB.dontShare']}
                                        />
                                    </RadioGroup>
                                </FormControl>
                                {userInfo.hasPetType === '1' && (
                                    <div className="c-unit-01 form-group-02">
                                        <label className="label-01">{words && words['WEB.numberOfPet']}</label>
                                        <Autocomplete
                                            noOptionsText={words && words['WEB.resultNotFounded']}
                                            disabled={!isEdit}
                                            fullWidth={true}
                                            PopperComponent={CustomPopper}
                                            closeIcon=""
                                            id="petsCount"
                                            value={countOptions[petsCount]}
                                            onChange={(e: any, val: any) => setPetsCount(parseInt(val?.value))}
                                            getOptionLabel={(option: any) => (option?.label ? option?.label : '')}
                                            options={countOptions ? countOptions : []}
                                            className={userInfo?.hasPetType === '1' && petsCount === 0 ? 'autocomplete-01 error' : 'autocomplete-01'}
                                            renderInput={params => (
                                                <div ref={params.InputProps.ref} className="render-container">
                                                    <input
                                                        {...params.inputProps}
                                                        className="render-input"
                                                        placeholder={words && words['WEB.choose']}
                                                    />
                                                    {isEdit && <SelectArrowIcon className="red-arrow" />}
                                                </div>
                                            )}
                                        />
                                    </div>
                                )}
                            </div>
                            {userInfo.hasPetType === '1' && petsCount >= 0 && (
                                <div className="c-item-02-A-06">
                                    <div className="c-unit-03">
                                        {petsCount > 0 &&
                                            userInfo.userPetBirthDates.map((item, index) => (
                                                <div key={'userPetBirthDates' + index} className="c-unit-03-A">
                                                    <UserPets
                                                        key={'userPetBirthDates' + index}
                                                        data={item}
                                                        index={index}
                                                        updateUserPets={updateUserPets}
                                                        isEdit={isEdit}
                                                        isTouched={isTouched}
                                                        setUserInfo={setUserInfo}
                                                    />
                                                </div>
                                            ))}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </AccordionDetails>
            </Accordion>
        </div>
    );
};
export default PersonalInfo;
