import React, { useEffect } from 'react';

import { useAppSelector } from '../../store';
import { getLocalizationWords } from '../../store/features/localization/localizationSlice';
import { useDispatch } from 'react-redux';

export const AuthWarning = () => {
    const { words } = useAppSelector(state => state.localization);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getLocalizationWords());

    }, []);
    return (
        <>
            <div className="not-support-page show">
                <div className="not-support-content">
                    {words && words['WEB.availableIKVSoon'] && (
                        <img
                            loading="lazy"
                            className="not-support-photo-1 authWarning"
                            src="https://khicindekocvar.blob.core.windows.net/cdn/prod/footer-koc-logo.png"
                        />
                    )}
                    <p className="title" id="transition-modal-description">
                        {words && words['WEB.availableIKVSoon']}
                    </p>
                </div>
            </div>
        </>
    );
};

export default AuthWarning;
