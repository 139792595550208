import { Popper, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useState, useEffect } from 'react';

import { ReactComponent as SelectArrowIcon } from '../../_assets/img/select-arrow.svg';
import CustomReactDatePicker from '../../components/custom-react-datepicker';
import { IUserChild, initialUserChild } from '../../models/profile/userChild';
import { useAppSelector } from '../../store';

const UserChild = (props: any) => {
    const { words } = useAppSelector(state => state.localization);
    const [state, setState] = useState<IUserChild>(initialUserChild);

    const [childGenderOptions, setChildGenderOptions] = useState<any>([]);

    useEffect(() => {
        setChildGenderOptions([
            { value: '2', label: words && words['WEB.femaleLetter'] },
            { value: '1', label: words && words['WEB.maleLetter'] },
            { value: '5', label: words && words['WEB.dontShareLowercase'] },
        ]);
    }, [words]);

    useEffect(() => {
        if (state !== props.data && state !== initialUserChild) {
            props.updateUserChildren(state, props.index);
        }
    }, [state]);

    const chooseGenderLetter =
        props?.data?.gender?.toString() === '2'
            ? words && words['WEB.femaleLetter']
            : props?.data?.gender?.toString() === '1'
            ? words && words['WEB.maleLetter']
            : words && words['WEB.dontShareLowercase'];
    useEffect(() => {
        setState(props.data ? { ...props?.data, gender: props?.data?.gender, genderLetter: chooseGenderLetter } : initialUserChild);
    }, [chooseGenderLetter]);

    const CustomPopper = function (props) {
        return <Popper {...props} className="dropdown-01" placement="bottom" />;
    };

    return (
        <>
            <div className="c-unit-01">
                <label className="label-01">{props.index + 1 + '. ' + (words && words['WEB.childName'])}</label>
                <TextField
                    className={props.isTouched && state.userChildName === '' ? 'textfield-01 error' : 'textfield-01'}
                    name="userChildName"
                    disabled={!props.isEdit}
                    placeholder={words && words['WEB.noInformationShared']}
                    onChange={e => setState({ ...state, userChildName: e?.target?.value })}
                    value={state.userChildName}
                />
                {props.isTouched && state.userChildName === '' && (
                    <span className="validation-error-message-01">*{words && words['WEB.requiredField']}</span>
                )}
            </div>
            <div className="c-unit-01">
                <label className="label-01">{words && words['WEB.birtdate']}</label>
                <CustomReactDatePicker
                    name="userChildBirthDate"
                    stateDate={state?.userChildBirthDate}
                    setState={setState}
                    isEdit={props?.isEdit}
                    isTouched={props?.isTouched}
                />
                {props.isTouched && state.userChildBirthDate === null && (
                    <span className="validation-error-message-01">*{words && words['WEB.requiredField']}</span>
                )}
            </div>
            <div className="c-unit-01 form-group-02">
                <label className="label-01">{words && words['WEB.gender']}</label>
                <Autocomplete
                    noOptionsText={words && words['WEB.resultNotFounded']}
                    disabled={!props.isEdit}
                    fullWidth={true}
                    PopperComponent={CustomPopper}
                    closeIcon=""
                    id="Child-count"
                    value={state?.gender ? childGenderOptions.find(gender => gender.value === state?.gender?.toString()) : ''}
                    onChange={(e: any, newValue: any) => {
                        setState({ ...state, gender: newValue?.value, genderLetter: newValue?.label });
                    }}
                    getOptionLabel={(option: any) => (option && option?.label ? option?.label : '')}
                    options={childGenderOptions ? childGenderOptions : []}
                    className={props.isTouched && state.gender === '' ? 'autocomplete-01 error' : 'autocomplete-01'}
                    renderInput={params => (
                        <div ref={params.InputProps.ref} className="render-container">
                            <input
                                {...params.inputProps}
                                className="render-input"
                                placeholder={words && words['WEB.choose']}
                            />
                            {props.isEdit && <SelectArrowIcon className="red-arrow" />}
                        </div>
                    )}
                />

                {props.isTouched && !state.gender && <span className="validation-error-message-01">*{words && words['WEB.requiredField']}</span>}
            </div>
        </>
    );
};

export default UserChild;
