const ACCOUNT_BASE_ROUTE = 'accounts/';
export const ACCOUNT_SETTINGS = {
    BASE_ROUTE: ACCOUNT_BASE_ROUTE,
    CHANGE_PASSWORD: `${ACCOUNT_BASE_ROUTE}change-password`,
    CHANGE_PASSWORD_EXPIRED: `${ACCOUNT_BASE_ROUTE}change-password-expired`,
    CHANGE_PROFILE_FOR_USER: `${ACCOUNT_BASE_ROUTE}change-profile-info`,
    FORGOT_PASSWORD: `${ACCOUNT_BASE_ROUTE}forgot-password`,
    GET_ROLE_ASSIGNMENTS: `${ACCOUNT_BASE_ROUTE}role-assignments`,
    GET_USER_CLAIMS_TREE: `${ACCOUNT_BASE_ROUTE}user-claims-tree`,
    IS_CAPTCHA_PASSED: `${ACCOUNT_BASE_ROUTE}is-captcha-passed?captcha=`,
    LOGIN: `${ACCOUNT_BASE_ROUTE}login`,
    PAGED_LIST: `${ACCOUNT_BASE_ROUTE}list`,
    REFRESH: `${ACCOUNT_BASE_ROUTE}refresh`,
    REGISTER: `${ACCOUNT_BASE_ROUTE}register`,
    RESETPASSWORD: `${ACCOUNT_BASE_ROUTE}reset-password`,
    SAVE_USER_CLAIMS: `${ACCOUNT_BASE_ROUTE}user-claims`,
    SET_PROFILE_PHOTO: `${ACCOUNT_BASE_ROUTE}set-profile-photo`,
    USER_SEARCH: `${ACCOUNT_BASE_ROUTE}search`,
};

const APPLICATION_SETTINGS_BASE_ROUTE = 'application-settings/';
export const APPLICATION_SETTINGS = {
    BASE_ROUTE: APPLICATION_SETTINGS_BASE_ROUTE,
    GET_BY_ID: `${APPLICATION_SETTINGS_BASE_ROUTE}id`,
    GET_BY_KEY: `${APPLICATION_SETTINGS_BASE_ROUTE}`,
    PAGED_LIST: `${APPLICATION_SETTINGS_BASE_ROUTE}list`,
    SEARCH: `${APPLICATION_SETTINGS_BASE_ROUTE}search`,
};

const APPLICATION_SETTINGS_CATEGORY_BASE_ROUTE = 'application-setting-categories/';
export const APPLICATION_SETTINGS_CATEGORY = {
    BASE_ROUTE: APPLICATION_SETTINGS_CATEGORY_BASE_ROUTE,
    GET_BY_ID: `${APPLICATION_SETTINGS_CATEGORY_BASE_ROUTE}`,
    PAGED_LIST: `${APPLICATION_SETTINGS_CATEGORY_BASE_ROUTE}list`,
    SEARCH: `${APPLICATION_SETTINGS_CATEGORY_BASE_ROUTE}search`,
};

const AUTHENTICATIONS_SETTINGS_BASE_ROUTE = 'authentications/';
export const AUTHENTICATIONS_SETTINGS = {
    BASE_ROUTE: AUTHENTICATIONS_SETTINGS_BASE_ROUTE,
    GENERATE_AUTHENTICATOR_SHARED_KEY: `${AUTHENTICATIONS_SETTINGS_BASE_ROUTE}generate-authenticator-shared-key`,
    SEND_VERIFICATION_CODE: `${AUTHENTICATIONS_SETTINGS_BASE_ROUTE}send-verification-code`,
    TWO_FACTOR_VERIFICATION_CODE: `${AUTHENTICATIONS_SETTINGS_BASE_ROUTE}two-factor-verification`,
};

const CONFIGURATIONS_SETTINGS_BASE_ROUTE = 'configurations/';
export const CONFIGURATIONS_SETTINGS = {
    BASE_ROUTE: CONFIGURATIONS_SETTINGS_BASE_ROUTE,
    FILE_UPLOADER: `${CONFIGURATIONS_SETTINGS_BASE_ROUTE}file-uploader`,
    REACT: `${CONFIGURATIONS_SETTINGS_BASE_ROUTE}react`,
};

export const GLOBAL_ERROR_HANDLING_PATH = 'Home/Error';

const LOGIN_AUDITLOG_SETTINGS_BASE_ROUTE = 'login-audit-logs/';
export const LOGIN_AUDITLOG_SETTINGS = {
    BASE_ROUTE: LOGIN_AUDITLOG_SETTINGS_BASE_ROUTE,
    PAGED_LIST: `${LOGIN_AUDITLOG_SETTINGS_BASE_ROUTE}list`,
    EXCEL_EXPORT: `${LOGIN_AUDITLOG_SETTINGS_BASE_ROUTE}export-excel`,
    PDF_EXPORT: `${LOGIN_AUDITLOG_SETTINGS_BASE_ROUTE}pdf-export`,
    SEARCH: `${LOGIN_AUDITLOG_SETTINGS_BASE_ROUTE}search`,
};

const ROLE_SETTINGS_BASE_ROUTE = 'roles/';
export const ROLE_SETTINGS = {
    BASE_ROUTE: ROLE_SETTINGS_BASE_ROUTE,
    PAGED_LIST: `${ROLE_SETTINGS_BASE_ROUTE}list`,
    GET_ROLE_CLAIMS_TREE: `${ROLE_SETTINGS_BASE_ROUTE}role-claims-tree`,
    GET_ROLE_USER_INFO: `${ROLE_SETTINGS_BASE_ROUTE}role-user-info`,
    SAVE_ROLE_CLAIMS: `${ROLE_SETTINGS_BASE_ROUTE}role-claims`,
    SEARCH: `${ROLE_SETTINGS_BASE_ROUTE}search`
};

const EMAIL_TEMPLATE_SETTINGS_BASE_ROUTE = 'email-templates/';
export const EMAIL_TEMPLATE_SETTINGS = {
    BASE_ROUTE: EMAIL_TEMPLATE_SETTINGS_BASE_ROUTE,
    PAGED_LIST: `${EMAIL_TEMPLATE_SETTINGS_BASE_ROUTE}list`,
    BY_ID: `${EMAIL_TEMPLATE_SETTINGS_BASE_ROUTE}`,
    SEARCH: `${EMAIL_TEMPLATE_SETTINGS_BASE_ROUTE}search`,
    SEND_TRY_MAIL: `${EMAIL_TEMPLATE_SETTINGS_BASE_ROUTE}send-email`
};