async function rawHttp(
    path: string,
    config: RequestInit
  ): Promise<Response> {
    const request = new Request(path, config);
    const response = await fetch(request);

    if (!response.ok) {
        throw new Error(response.statusText);
    }

      return response;
  }

async function http<T>(
  path: string,
  config: RequestInit
): Promise<T> {
  const request = new Request(path, config);
  const response = await fetch(request);

  if (!response.ok) {
    throw new Error(response.statusText);
  }

    // may error if there is no body, return empty array
    return response.json().catch(() => ({}));
}

export async function getRequest<T>(
  path: string,
  config?: RequestInit,
): Promise<T> {
  const init = { method: "get", ...config };
    return await http<T>(path, init);
}

export async function postRequest<T, U>(
  path: string,
  body?: T,
  config?: RequestInit,
): Promise<U> {
  const init = { method: "post", body: JSON.stringify(body), ...config };
    return await http<U>(path, init);
}

export async function putRequest<T, U>(
  path: string,
  body: T,
  config?: RequestInit,
): Promise<U> {
  const init = { method: "put", body: JSON.stringify(body), ...config };
    return await http<U>(path, init);
}

export async function binaryRequest<T>(
    path: string,
    config?: RequestInit,
  ): Promise<Response> {
    const init = { method: "get", ...config };

    return await rawHttp(path, init);
  }
  
