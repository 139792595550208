import React from "react";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import { useAppSelector } from "../../store";

export const ProgressBaar = ({percent}:any) => {
    const { words } = useAppSelector(state => state.localization);


    return (
        <div className="progressbar-01">

            <div className="c-container-01">
            <ProgressBar
                filledBackground='#2e333e'
                unfilledBackground='#fafafa'
                percent={percent}
            >
                <Step transition="scale" >
                    {({ accomplished, index }) => (
                        <div
                            className=''
                        >

                        </div>
                    )}
                </Step>
                <Step transition="scale">
                    {({ accomplished, index }) => (
                        <div
                            className={`transitionStep ${accomplished ? "accomplished" : null}`}
                        >
                            {index + 1}
                        </div>
                    )}

                </Step>
                <Step transition="scale">
                    {({ accomplished, index }) => (
                        <div
                            className={`transitionStep ${accomplished ? "accomplished" : null}`}
                        >
                            {index + 1}
                        </div>
                    )}

                </Step>
                <Step transition="scale">
                    {({ accomplished, index }) => (
                        <div
                            className={`transitionStep ${accomplished ? "accomplished" : null}`}
                        >
                            {index + 1}
                        </div>
                    )}

                </Step>
                <Step transition="scale">
                    {({ accomplished, index }) => (
                        <div
                            className={`transitionStep ${accomplished ? "accomplished" : null}`}
                        >
                            {index + 1}
                        </div>
                    )}

                </Step>
                <Step transition="scale">
                    {({ accomplished, index }) => (
                        <div
                            className={`transitionStep ${accomplished ? "accomplished" : null}`}
                        >
                            {index + 1}
                        </div>
                    )}

                </Step>
                <Step transition="scale">
                    {({ accomplished, index }) => (
                        <div
                            className={`transitionStep ${accomplished ? "accomplished" : null}`}
                        >
                            {index + 1}
                        </div>
                    )}

                </Step>
                <Step transition="scale">
                    {({ accomplished, index }) => (
                        <div
                            className=''
                        >
                        </div>
                    )}
                </Step>
            </ProgressBar>
            <span className="c-item">%{percent}</span>
            </div>
            <span className="c-container-02">{words && words['WEB.profileFullness']}</span>
        </div>


    )
}

export default ProgressBaar; 
