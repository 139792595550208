import React from 'react';
import { Route } from 'react-router-dom';


const renderResult = ({ component: Component, ...rest }: any): JSX.Element => {
    return (
        <Route
            {...rest}
            render={props => {

                return Component ? (//login ise
                    <Component {...props} />
                ) : (
                    rest.render(props)
                );

            }}
        />
    );
};
renderResult.displayName = 'privateRoute';
export default renderResult;
