import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import React, { FC, useEffect, useReducer } from 'react';
import { isMacOs } from 'react-device-detect';
import { Link } from 'react-router-dom';

import { IToBeKoc, appStateReducer } from '../../models/components/details/ToBe-Koc';
import { useAppSelector } from '../../store';

const VideoURLFrame: FC<IToBeKoc> = ({ isHomePage, isDetailPage, details }) => {
    const initialStateReducer = { iframeUrl: null, imageData: [], activeImage: null, openAppDetailModal: false };
    const [event, updateEvent] = useReducer(appStateReducer, initialStateReducer);
    const { imageData, activeImage } = event;
    const { homePageFifthPartList } = useAppSelector(state => state.hr);

    useEffect(() => {
        const data = [];
        const col: number[] = [
            1, 1, 2, 1, 1, 1, 1, 1, 2, 1, 1, 1, 1, 1, 2, 1, 1, 1, 1, 1, 2, 1, 1, 1, 1, 1, 2, 1, 1, 1, 1, 1, 2, 1, 1, 1, 1, 1, 2, 1, 1, 1, 1, 1, 2, 1,
            1, 1, 1, 1, 2, 1, 1, 1, 1, 1, 2, 1, 1, 1, 1, 1, 2, 1, 1, 1, 1, 1, 2, 1, 1, 1,
        ];
        for (let i = 0; i < details?.items?.ikCatiLeftMenuWidgetMedias?.length; i++) {
            const item_ = details?.items?.ikCatiLeftMenuWidgetMedias[i];
            data?.push({
                image: item_?.image,
                iframe: item_?.url,
                webUrl: item_?.webUrl,
                col: col[i],
                order: i + 1,
            });
        }
        updateEvent({ imageData: data });
    }, [details]);    

    return (
        <div
            className={
                isDetailPage
                    ? 'section-02 f-to-be-Koc'
                    : isMacOs && isDetailPage
                    ? 'section-02 f-to-be-Koc ismacOs'
                    : isMacOs
                    ? 'section-02 ismacOs'
                    : 'section-02'
            }
            >
            <div className="c-wrapper">
                {isHomePage && (
                    <div className="c-container-01">
                        <img loading="lazy" className="c-item" src={homePageFifthPartList?.items[0]?.imageUrl} />
                    </div>
                )}
                <div className="c-container-02">
                    {isHomePage && <h4 className="c-container-02-A">{details?.items?.name}</h4>}
                    <div className="c-container-02-B">
                        <ImageList
                            gap={32}
                            rowHeight={332}
                            className={imageData?.length % 2 === 1 ? 'f-to-be-imageList f-odd' : 'f-to-be-imageList'}
                            cols={3}>
                            {imageData?.map((itemData, index) => (
                                <ImageListItem
                                    onClick={() => updateEvent({ iframeUrl: itemData?.iframe })}
                                    onMouseOver={() => updateEvent({ activeImage: index + 1 })}
                                    onMouseLeave={() => updateEvent({ activeImage: null })}
                                    className={activeImage === itemData?.order ? 'f-to-be-image-item f-filter' : 'f-to-be-image-item'}
                                    key={'imageItem-' + index}
                                    cols={itemData?.col}>
                                    <Link
                                        className="tobekoc-url"
                                        to={{
                                            pathname: `/${itemData?.webUrl}`
                                        }}>
                                        <img loading="lazy" className="f-to-be-image-item-01" src={itemData?.image} alt={itemData?.title} />
                                        <svg
                                            className="f-to-be-image-item-02"
                                            width="110"
                                            height="110"
                                            viewBox="0 0 90 90"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                fill="#FFF"
                                                fillRule="evenodd"
                                                d="M45 4C22.393 4 4 22.393 4 45s18.393 41 41 41 41-18.393 41-41S67.607 4 45 4m0 86C20.187 90 0 69.813 0 45S20.187 0 45 0s45 20.187 45 45-20.187 45-45 45M32 27.387v34.9c0 1.06 1.136 1.728 2.056 1.208l30.902-17.45c.938-.53.938-1.885 0-2.414L34.056 26.18c-.92-.52-2.056.148-2.056 1.207"></path>
                                        </svg>
                                    </Link>
                                </ImageListItem>
                            ))}
                        </ImageList>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default VideoURLFrame;
