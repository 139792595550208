import React, { FC, useReducer } from 'react';

import { IToBeKoc, appStateReducer } from '../../models/components/details/ToBe-Koc';
import { useAppSelector } from '../../store';
import { Link } from 'react-router-dom';

const ToBeKocResponsive: FC<IToBeKoc> = ({ isHomePage, isDetailPage, details }) => {
    const initialStateReducer = { iframeUrl: null, imageData: [], activeImage: null, openAppDetailModal: false };
    const [, updateEvent] = useReducer(appStateReducer, initialStateReducer);
    const { homePageFifthPartList } = useAppSelector(state => state.hr);

    return (
        <div className={isDetailPage ? 'section-02 f-to-be-Koc' : 'section-02'}>
            <div className="c-wrapper">
                {isHomePage && (
                    <div className="c-item-01">
                        <img loading="lazy" className="c-item" src={homePageFifthPartList?.items[0]?.imageUrl} />
                    </div>
                )}
                {isHomePage && <h4 className="c-item-02">{details?.items?.name} </h4>}
                <div className={'c-item-03'}>
                    {details?.items?.ikCatiLeftMenuWidgetMedias.map((item, index) => (
                        <Link
                            key={item?.webUrl + index}
                            className={(index + 1) % 3 === 0 ? 'c-unit-01 active' : 'c-unit-01'}
                            to={{
                                pathname: `/${item?.webUrl}`
                            }}>
                            <button
                                onClick={() => updateEvent({ iframeUrl: item?.url })}
                                className={(index + 1) % 3 === 0 ? 'c-unit-01 active' : 'w-100'}
                            >
                                <img src={item.image} className={'c-unit-01-A'} />

                                <svg className="c-unit-01-B" width="110" height="110" viewBox="0 0 90 90" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        fill="#FFF"
                                        fillRule="evenodd"
                                        d="M45 4C22.393 4 4 22.393 4 45s18.393 41 41 41 41-18.393 41-41S67.607 4 45 4m0 86C20.187 90 0 69.813 0 45S20.187 0 45 0s45 20.187 45 45-20.187 45-45 45M32 27.387v34.9c0 1.06 1.136 1.728 2.056 1.208l30.902-17.45c.938-.53.938-1.885 0-2.414L34.056 26.18c-.92-.52-2.056.148-2.056 1.207"></path>
                                </svg>
                            </button>
                        </Link>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ToBeKocResponsive;
