import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

import { get } from '../../core/client';


const KocIframe = () => {
    const [iframeUrl, setIframeUrl] = useState(null);
    const history = useHistory();

    useEffect(() => {
        get<any>(`hr/get-promotion-video?name=` + history?.location?.pathname.slice(1)).then((response) => setIframeUrl(response?.result));
    }, [history]);

    return (
        <div className="koc-iframe">
            <div className="koc-items-group">
                <Link
                    className="search-close-icon"
                    to={{
                        pathname: `${iframeUrl?.returnUrl || '/'}`,
                    }} >
                    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="48" height="48" rx="24" fill="black" />
                        <g clipPath="url(#clip0_4028_10926)">
                            <path
                                d="M31 18.41L29.59 17L24 22.59L18.41 17L17 18.41L22.59 24L17 29.59L18.41 31L24 25.41L29.59 31L31 29.59L25.41 24L31 18.41Z"
                                fill="white"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0_4028_10926">
                                <rect width="24" height="24" fill="white" transform="translate(12 12)" />
                            </clipPath>
                        </defs>
                    </svg>
                </Link>
                <div className="koc-iframe-content" dangerouslySetInnerHTML={{ __html: iframeUrl?.videoUrl }}></div>
            </div>
        </div>
    );
};

export default KocIframe;
