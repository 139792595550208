import React, { useEffect } from 'react';
import {
    mobileVendor,
    browserVersion,
    browserName as browserNames,
    isAndroid,
    isIOS,
    mobileModel,
    isMobileOnly,
    osName,
    osVersion,
    isMacOs,
} from 'react-device-detect';
import { Link } from 'react-router-dom';

import { analiticsLog, isBrave, userId } from '../../_helpers/useful-functions';
import { post } from '../../core/client';
import { getLocalStorageItem } from '../../core/utility';
import { useAppSelector } from '../../store';

const LandingSectionNew = (props): any => {
    const { landingPageList } = useAppSelector(state => state.hr);
    const { loginId } = useAppSelector(state => state.users.globalLogin);

    const animationEnd = 1000;
    let main = null;
    let cardContainer: any = null;
    const initialScale = 2;
    let previousRemainingPercent = 1;

    useEffect(() => {
        initAnimation();
        onScroll(true);
    }, []);

    function initAnimation() {
        main = document.querySelector('.animation-main');
        cardContainer = document.querySelector('.card-container');
        window.addEventListener('scroll', function () {
            onScroll();
        });
    }
    function onScroll(disableCheck?: any) {
        const remaingPercent = Math.max(1 - window.scrollY / animationEnd, 0);

        if (disableCheck || (previousRemainingPercent > 0 && previousRemainingPercent < 1)) {
            let transform = '';
            if (cardContainer) {
                if (initialScale) {
                    transform += 'scale(' + (1 + Math.max(remaingPercent * (initialScale - 1), 0)) + ')';
                    cardContainer.style.filter = 'grayscale(' + Math.max(remaingPercent, 0) + ')';
                }

                if (remaingPercent == 0) {
                    main.classList.add('finished');
                    cardContainer?.classList?.add('padding-top-value');
                } else {
                    main.classList.remove('finished');
                    cardContainer?.classList?.remove('padding-top-value');
                }

                cardContainer.style.transform = transform.trim();
            }
        }
        previousRemainingPercent = remaingPercent;
    }

    const handleApplicationLogin = async applicationName => {
        const email = getLocalStorageItem('USER_EMAIL');
        const os = isIOS ? 1 : isAndroid ? 2 : 0; //! IOS=1, Android=2, Huawei=3
        const deviceName = isMobileOnly ? mobileVendor : null;
        const deviceModel = isMobileOnly ? mobileModel : null;
        const isMobile = isMobileOnly;
        const osNameWeb = osName;
        const osVersionWeb = osVersion;
        const browserName = isBrave() ? 'Brave' : browserNames;

        const applicationFormData = {
            email,
            applicationName,
            loginId,
            isMobile,
            os,
            deviceName,
            deviceModel,
            osVersion: null,
            version: null,
            deviceId: null,
            schema: null,
            //!add pls
            browserName,
            browserVersion,
            osNameWeb,
            osVersionWeb,
            //!
        };
        const endpoint = `/hr/click-application-link`;
        await post<any>(endpoint, applicationFormData, '');
        analiticsLog(applicationName);
    };

    return (
        userId && (
            <>
                <section className="animation-main">
                    <div className="f-container">
                        <div className={isMacOs ? "card-container anim isMacOs" : "card-container anim"}>
                            <div className="animation-cards " id="cards">
                                <div className="animation-col">
                                    {landingPageList?.col1?.map((item, index) => (
                                        <Link
                                            onClick={() => analiticsLog(item?.header)}
                                            target={item?.url && '_blank'}
                                            rel="noreferrer"
                                            to={
                                                item?.url?.includes('kocailem.com')
                                                    ? { pathname: item?.url + '?loginId=' + loginId }
                                                    : item?.url
                                                    ? { pathname: item?.url }
                                                    : { pathname: '/' }
                                            }
                                            key={'card-01' + index}
                                            className="f-animation-card">
                                            <img className="main-img" src={item?.imageUrl} alt="image" />
                                            <div className="detail">
                                                <img src={item?.iconUrl} alt="icon" />
                                                <div className="detail-text">
                                                    <p className="title">{item?.header}</p>
                                                    {item?.subTitle && <p className="text">{item?.subTitle}</p>}
                                                </div>
                                            </div>
                                        </Link>
                                    ))}
                                </div>
                                <div className="animation-col">
                                    {landingPageList?.col2?.map((item, index) => (
                                        <a
                                            onClick={() => handleApplicationLogin(item?.header)}
                                            target={item?.url && '_blank'}
                                            rel="noreferrer"
                                            href={item?.url?.includes('kocailem.com') ? item?.url + '?loginId=' + loginId : item?.url}
                                            key={'card-02' + index}
                                            className="f-animation-card">
                                            <img className="main-img" src={item?.imageUrl} alt="image" />
                                            <div className="detail">
                                                <img src={item?.iconUrl} alt="icon" />
                                                <div className="detail-text">
                                                    <p className="title">{item?.header}</p>
                                                    {item?.subTitle && <p className="text">{item?.subTitle}</p>}
                                                </div>
                                            </div>
                                        </a>
                                    ))}
                                </div>
                                <div className="animation-col">
                                    {landingPageList?.col3?.map((item, index) => (
                                        <Link
                                            onClick={() => analiticsLog(item?.header)}
                                            target={item?.url && '_blank'}
                                            rel="noreferrer"
                                            to={
                                                item?.url?.includes('kocailem.com')
                                                    ? { pathname: item?.url + '?loginId=' + loginId }
                                                    : item?.url
                                                    ? { pathname: item?.url }
                                                    : { pathname: '/' }
                                            }
                                            key={'card-03' + index}
                                            className="f-animation-card">
                                            <img className="main-img" src={item?.imageUrl} alt="image" />
                                            <div className="detail">
                                                <img src={item?.iconUrl} alt="icon" />
                                                <div className="detail-text">
                                                    <p className="title">{item?.header}</p>
                                                    {item?.subTitle && <p className="text">{item?.subTitle}</p>}
                                                </div>
                                            </div>
                                        </Link>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    );
};

export default LandingSectionNew;
