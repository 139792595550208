interface IDatePicker {
    setInputValueDate?: any;
    inputValueDate?: string;
    setState?: any;
    stateDate?: any;
    name: string;
    isEdit?: boolean;
    onDateChange?: (date?: Date, name_?: string) => void;
    isTouched?: boolean;
}

interface IAppStateReducer {
    selectedDate?: Date;
    dateOfMarriage?: Date;
    formattedDate?: string;
    inputValue?: string;
    showMonthYearPicker: boolean;
    showYearPicker: boolean;
    showDayPicker: boolean;
    openDatePicker: boolean;
}

const appStateReducer = (prev: IAppStateReducer, next: Partial<IAppStateReducer>): IAppStateReducer => {
    return { ...prev, ...next };
};
export { appStateReducer };
export type { IDatePicker, IAppStateReducer };
