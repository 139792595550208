interface IHobby {
    id?: string;
    joyId?: string;
    joyName?: string;
    joyTypeId?: string;
    joyTypeName?: string;
    isOtherDetail?: string;
    isOther?: boolean;
}

const initialStateHobby: IHobby = {
    id: null,
    joyId: '',
    joyName: '',
    joyTypeId: '',
    joyTypeName: '',
    isOtherDetail: '',
    isOther: false,
};

export type { IHobby };

export { initialStateHobby };
