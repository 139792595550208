interface IUserChild {
    userChildId?: string;
    userChildName: string;
    userChildBirthDate: Date | null;
    gender: string;
    genderLetter: string;
}

const initialUserChild: IUserChild = {
    userChildId: '',
    userChildName: '',
    userChildBirthDate: null,
    gender: '',
    genderLetter: '',
};

export type { IUserChild };

export {initialUserChild}