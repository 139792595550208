import React, { useEffect, useState } from 'react';
// import { isFirefox, isChrome, isEdge, isSafari } from 'react-device-detect';
import { useDispatch } from 'react-redux';

import { userId } from '../../_helpers/useful-functions';
import Cookies from '../../components/cookies';
import AsideMenu from '../../components/layouts/layout1/asideMenu';
import Footer from '../../components/layouts/layout1/footer';
import Header from '../../components/layouts/layout1/header';
import { getLocalStorageItem } from '../../core/utility';
import { getFooterList } from '../../store/features/hr/hrSlice';
import { getLeftMenuList } from '../../store/features/hrDetail/hrDetailsSlice';
import { getLocalizationWords } from '../../store/features/localization/localizationSlice';
import { getUserInfo } from '../../store/features/user/usersSlice';
import NotSupportPage from '../notSupportPage';
import KvkkDetailText from './KvkkDetilText';

const KvkkDetail = () => {
    const [windowSize, setWindowSize] = useState({ width: undefined });
    const dispatch = useDispatch();
    const culture = getLocalStorageItem('SelectedLanguage');

    useEffect(() => {
        dispatch(getLocalizationWords());
        dispatch(getUserInfo());
        dispatch(getLeftMenuList());
        dispatch(getFooterList());
    }, [culture]);

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
            });
        }
        setWindowSize({ width: window.innerWidth });

        window.addEventListener('resize', handleResize);

        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <>
            {(windowSize.width < 840 && userId) || (!userId && windowSize.width > 600 && windowSize.width < 800) ? (
                <NotSupportPage />
            ) : (
                <div id="kvkk-wrapper">
                    <Header />
                    <AsideMenu />
                    <KvkkDetailText />
                    <Cookies />
                    <Footer />
                </div>
            )}
        </>
    );
};

export default KvkkDetail;
