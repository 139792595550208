import CircularProgress from '@material-ui/core/CircularProgress';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import InformationContent from '../../components/details/InformationContent';
import ToBeKocResponsive from '../../components/details/TobeKocResponsive';
import { useAppSelector } from '../../store';
import { setIsChangePage } from '../../store/features/hr/hrSlice';
import { getLeftMenuList, getToBeKocDetails } from '../../store/features/hrDetail/hrDetailsSlice';
import { getLocalStorageItem } from '../../core/utility';
import VideoURLFrame from '../../components/details/VideoURLFrame';

const CardDetailKoc = () => {
    const dispatch = useDispatch();
    const { toBeKocDetails } = useAppSelector(state => state.hrDetails);
    const [windowSize, setWindowSize] = useState({ width: undefined });
    const culture = getLocalStorageItem('SelectedLanguage');

    useEffect(() => {
        dispatch(getToBeKocDetails());
        dispatch(getLeftMenuList());
    }, [culture]);

    useEffect(() => {
        dispatch(setIsChangePage(false));
    }, []);

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
            });
        }
        setWindowSize({ width: window.innerWidth });
        window.addEventListener('resize', handleResize);
        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <>
            {toBeKocDetails?.loading ? (
                <div className="spinner-02">
                    <CircularProgress size={100} />
                </div>
            ) : (
                <>
                    <InformationContent data={toBeKocDetails} isToBeKoc />
                    {windowSize.width > 768 && <VideoURLFrame isDetailPage details={toBeKocDetails} />}
                    {windowSize.width <= 768 && <ToBeKocResponsive isDetailPage details={toBeKocDetails} />}
                </>
            )}
        </>
    );
};

export default CardDetailKoc;
