import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';

const Spinner = (props: any) => {
    if (props.type === '01') {
        return <div className="spinner-01"></div>;
    }
    if (props.type === '03') {
        return (
            <div className="spinner-03">
                <CircularProgress size={100} />
            </div>
        );
    }

    return (
        <div className="spinner-00">
            <CircularProgress />
        </div>
    );
};

export default Spinner;
