import IconButton from '@material-ui/core/IconButton';
import Slider from '@material-ui/core/Slider';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import RotateRightIcon from '@material-ui/icons/RotateRight';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import React, { useCallback, useState } from 'react';
import Cropper from 'react-easy-crop';

import getCroppedImg from './cropImage';

export default function ImageCropper({ img, onCrop }: any) {
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [rotate, setRotate] = useState<number>(0);
    const [zoom, setZoom] = useState<number>(1);

    const onCropComplete = useCallback(
        async (croppedArea, croppedAreaPixels) => {
            const croppedImage = await getCroppedImg(img, croppedAreaPixels, rotate);
            onCrop(croppedImage);
        },
        [rotate, img],
    );
    
    return (
        <div className="crop-image">
            <div className="cropper">
                <Cropper
                    image={img}
                    aspect={1 / 1}
                    crop={crop}
                    zoom={zoom}
                    rotation={rotate}
                    onCropChange={setCrop}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                />
            </div>
            <div className="wrapper">
                <div className="rotate-wrap">
                    <IconButton onClick={() => setRotate(rotate - 15)}>
                        <RotateLeftIcon fontSize="large" />
                    </IconButton>
                    <IconButton onClick={() => setRotate(rotate + 15)}>
                        <RotateRightIcon fontSize="large" />
                    </IconButton>
                </div>
                <div className="slider-wrap">
                    <IconButton disabled={zoom <= 1} onClick={() => setZoom(zoom - 0.5)}>
                        <ZoomOutIcon fontSize="large" className="zoom-out" />
                    </IconButton>
                    <Slider
                        id="slider-image-cropper"
                        className="slider"
                        value={zoom}
                        min={1}
                        max={3}
                        step={0.1}
                        onChange={(e, zoom: any) => setZoom(zoom)}
                    />
                    <IconButton disabled={zoom >= 3} onClick={() => setZoom(zoom + 0.5)}>
                        <ZoomInIcon fontSize="large" className="zoom-in" />
                    </IconButton>
                </div>
            </div>
        </div>
    );
}
