import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import mobilKocLogo from '../../_assets/img/footer-koc-logo.svg';
import { getLocalStorageItem } from '../../core/utility';
import { useAppSelector } from '../../store';
import { getLocalizationWords } from '../../store/features/localization/localizationSlice';

const MobileStore = () => {
    const { words } = useAppSelector(state => state.localization);
    const culture = getLocalStorageItem('SelectedLanguage');
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getLocalizationWords());
    }, [culture]);

    return (
        <div className="responsive-store">
            <div className="c-item-01">
                <img loading="lazy" className="c-item-01-A" src={mobilKocLogo} alt="logo" />
            </div>

            <p className="c-item-02">
                { words['WEB.youCanAccessInIKVMobilApp'] ?? 'İçinde Koç Var’a cepten İçinde Koç Var mobil uygulaması üzerinden erişebilirsin.'}
            </p>

            <div className="c-item-03">
                <Link to={{ pathname: 'https://apps.apple.com/tr/app/i-%C3%A7inde-ko%C3%A7-var/id1666252893' }} target="_blank" rel="noreferrer">
                    <img loading="lazy" className="c-item-03-A" src="https://khicindekocvar.blob.core.windows.net/cdn/prod/app-store.png" />
                </Link>
                <Link to={{ pathname: 'https://play.google.com/store/apps/details?id=com.koc.icindekocvar' }} target="_blank" rel="noreferrer">
                    <img loading="lazy" className="c-item-03-B" src="https://khicindekocvar.blob.core.windows.net/cdn/prod/android-store.png" />
                </Link>
            </div>
        </div>
    );
};

export default MobileStore;
