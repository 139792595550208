import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { isMacOs } from 'react-device-detect';

import { get } from '../../core/client';
import { getLocalStorageItem } from '../../core/utility';
import SliderNews from './SliderNews';

const TheNews = () => {
    const [bannerNews, setBannerNews] = useState(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const culture = getLocalStorageItem('SelectedLanguage');

    const getBannerNews = async () => {
        setIsLoading(true);
        await get<any>(`/hr/get-ik-second-part`)
            .then(res => {
                if (res.isSuccessful) {
                    setBannerNews(res?.result?.items);
                    setIsLoading(true);
                }
            })
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        getBannerNews();
    }, [culture]);

    const image1 = bannerNews && bannerNews[0].imageUrl;
    const image2 = bannerNews && bannerNews[1].imageUrl;

    return (
        !isLoading && (
            <div
                className={culture === 'en-US' && isMacOs ? 'section-01 en-culture isMacOs' : isMacOs ? 'section-01 isMacOs' : 'section-01'}
                id="the-news-content">
                <div className="c-container-group">
                    <div className="c-container-01">
                        <img className="c-item-01" src={image1 && image1} />
                    </div>

                    <div className="c-container-02">
                        <img className="c-item-01" src={image2 && image2} />
                    </div>
                </div>

                <div className="c-container-03">
                    <SliderNews />
                </div>
            </div>
        )
    );
};

export default TheNews;
