interface ICelebrate {
    id?: string;
    specialDateId?: string;
    specialDateName?: string;
    celebrationForWhomId?: string;
    celebrationForWhomName?: string;
    name?: string;
    specialDateCategoryId?: string;
    specialDateCategoryName?: string;
    isOtherPerson?: string;
    isOther?: boolean;
}

const initialCelebrate: ICelebrate = {
    id: null,
    specialDateId: null,
    specialDateName: '',
    celebrationForWhomId: null,
    celebrationForWhomName: '',
    name: '',
    specialDateCategoryId: null,
    specialDateCategoryName: '',
    isOtherPerson: '',
    isOther: false,
};

export type { ICelebrate };

export { initialCelebrate };
