import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { get } from '../../../core/client';
import { IHr } from './model';
import { userId } from '../../../_helpers/useful-functions';

export const getBannerList: any = createAsyncThunk('hr/getBannerList', async () => {
    const bannerList = await get<any>('hr/get-banner-list');
    return bannerList;
});
export const getNewsList: any = createAsyncThunk('hr/getNewsList', async () => {
    const newsList = await get<any>('hr/get-news-list');
    return newsList;
});
export const getApplicationList: any = createAsyncThunk('hr/getApplicationList', async () => {
    const applicationList = await get<any>('hr/get-application-list');
    return applicationList;
});
export const getTabBarApplicationList: any = createAsyncThunk('hr/getTabBarApplicationList', async () => {
   
    const url = userId ? 'hr/get-login-application-list' : 'hr/get-application-list';
    const tabBarApplicationList = await get<any>(url);
    return tabBarApplicationList;
});
export const getApplicationIdList: any = createAsyncThunk('hr/getApplicationIdList', async () => {
    const applicationIdList_ = await get<any>('hr/get-application-id-list');
    return applicationIdList_;
});

export const getLandingPageList: any = createAsyncThunk('hr/getlandingPageList', async () => {
    const landingPageList = await get<any>('hr/get-login-application-landing-page-list');
    return landingPageList;
});

export const getFifthPart: any = createAsyncThunk('hr/getIkFiftPart', async () => {
    const homePageFifthPart = await get<any>('hr/get-ik-fifth-part');
    return homePageFifthPart;
});

export const getSearch: any = createAsyncThunk('hr/getSearch', async (keyword: any) => {
    const searhDetail = await get<any>(`hr/search?keyword=${keyword.searchValue}&pageSize=5&pageIndex=${keyword.pageIndex}`);
    return searhDetail;
});
export const getFooterList: any = createAsyncThunk('hr/getFooterList', async () => {
    const footerList = await get<any>('hr/get-contact-list?platform=WEB');
    return footerList;
});

const hrSlice = createSlice({
    name: 'hr',
    initialState: {
        banner: {
            loading: true,
            items: [],
        },
        news: {
            loading: true,
            items: [],
        },
        applications: {
            loading: true,
            items: [],
        },
        tabBarApplications: {
            loading: true,
            items: [],
        },
        applicationIdList: {
            loading: true,
            items: [],
        },
        landingPageList: {
            loading: true,
            col1: [],
            col2: [],
            col3: [],
        },
        // applicationDetailsList: {
        //     loading: true,
        //     items: [],
        // },
        homePageFifthPartList: {
            loading: true,
            items: [],
        },
        searchDetail: {
            loading: true,
            items: null,
        },

        slideItemIndex: {
            index: 0,
        },
        slideItem: 0,
        drawerOpen: false,
        appCardToOpen: false,
        appCardDetailToOpen: false,
        appIndex: 1,
        userId: null,
        isFirst: false,
        footerList: {
            loading: true,
            items: null,
        },
        isChangePage: false,
    },
    reducers: {
        setSlideItemIndex(state, action) {
            state.slideItemIndex.index = action.payload.slideIndex;
        },
        setSlideItem(state, action) {
            state.slideItem = action.payload;
        },
        setAppCardToClose(state, action) {
            state.appCardToOpen = action.payload;
        },
        setAppCardDetailToOpen(state, action) {
            state.appCardDetailToOpen = action.payload;
        },
        setAppIndex(state, action) {
            state.appIndex = action.payload;
        },
        setUserId(state, action) {
            state.userId = action.payload;
        },
        setClearSearchData(state) {
            state.searchDetail.items = null;
        },
        setDrawerMenuOpen(state, action) {
            state.drawerOpen = action.payload;
        },
        setIsChangePage(state, action) {
            state.isChangePage = action.payload;
        },
    },
    extraReducers: builder => {
        builder.addCase(getBannerList.fulfilled, (state, action: PayloadAction<IHr>) => {
            state.banner.items = action?.payload.isSuccessful ? action.payload.result.items : [];
            state.banner.loading = false;
        });
        builder.addCase(getNewsList.fulfilled, (state, action: PayloadAction<IHr>) => {
            state.news.items = action?.payload.isSuccessful ? action.payload.result.items : [];
            state.news.loading = false;
        });
        builder.addCase(getApplicationList.fulfilled, (state, action: PayloadAction<IHr>) => {
            state.applications.items = action?.payload.isSuccessful ? action.payload.result.items : [];
            state.applications.loading = false;
        });
        builder.addCase(getApplicationIdList.fulfilled, (state, action: PayloadAction<IHr>) => {
            state.applicationIdList.items = action?.payload.isSuccessful ? action.payload.result.items : [];
            state.applicationIdList.loading = false;
        });
        builder.addCase(getTabBarApplicationList.fulfilled, (state, action: PayloadAction<IHr>) => {
            state.tabBarApplications.items = action?.payload.isSuccessful ? action.payload.result.items : [];
            state.tabBarApplications.loading = false;
        });
       
        builder.addCase(getLandingPageList.pending || getLandingPageList.rejected, (state, action: PayloadAction<IHr>) => {
            state.landingPageList.loading = true;
        });
        builder.addCase(getLandingPageList.fulfilled, (state, action: PayloadAction<IHr>) => {
            state.landingPageList.col1 = action?.payload.isSuccessful ? action.payload.result.items : [];
            state.landingPageList.col2 = action?.payload.isSuccessful ? action.payload.result.items2 : [];
            state.landingPageList.col3 = action?.payload.isSuccessful ? action.payload.result.items3 : [];
            state.landingPageList.loading = false;
        });
        builder.addCase(getFifthPart.fulfilled, (state, action: PayloadAction<IHr>) => {
            state.homePageFifthPartList.items = action?.payload.isSuccessful ? action.payload.result.items : [];
            state.homePageFifthPartList.loading = false;
        });
        builder.addCase(getSearch.pending, (state, action: PayloadAction<any>) => {
            state.searchDetail.loading = true;
        });
        builder.addCase(getSearch.fulfilled, (state, action: PayloadAction<any>) => {
            state.searchDetail.loading = false;
            state.searchDetail.items = action?.payload.isSuccessful ? action.payload.result : [];
        });
        builder.addCase(getFooterList.pending || getFooterList.rejected, (state, action: PayloadAction<any>) => {
            state.footerList.loading = true;
        });
        builder.addCase(getFooterList.fulfilled, (state, action: PayloadAction<any>) => {
            state.footerList.loading = false;
            state.footerList.items = action?.payload.isSuccessful ? action.payload.result : [];
        });
    },
});

export default hrSlice.reducer;
export const {
    setSlideItemIndex,
    setSlideItem,
    setAppCardToClose,
    setAppCardDetailToOpen,
    setAppIndex,
    setUserId,
    setClearSearchData,
    setDrawerMenuOpen,
    setIsChangePage,
} = hrSlice.actions;
