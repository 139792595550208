import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Lazy, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { isMacOs } from 'react-device-detect';

import { scrollToTop, userId } from '../../_helpers/useful-functions';
import { useAppSelector } from '../../store';
import { setIsChangePage } from '../../store/features/hr/hrSlice';

let sliderInterval: any = null;

const SliderApps = () => {
    const { applications }: any = useAppSelector(state => state.hr);
    const { words } = useAppSelector(state => state.localization);
    const [swiper, setSwiper] = useState(null);
    const [activeSlide, setActiveSlide] = useState<number>(0);
    const [isPlay, setIsPlay] = useState<boolean>(false);
    const [sliderTimeout, setSliderTimeout] = useState(null);
    const dispatch = useDispatch();

    const handleNextClick = () => {
        if (swiper) {
            const activeSlide_ = activeSlide + 1;
            if (activeSlide_ > 0 && activeSlide_ < applications?.items.length) {
                const bolum = activeSlide_ / 4; 
                const kalan = activeSlide_ % 4; 

                if (kalan === 0) {
                    const slideTo_ = bolum * 4;
                    swiper?.slideTo(slideTo_, 0, false);
                    setActiveSlide(slideTo_);
                } else {
                    const _slideTo = (parseInt(bolum.toString()) + 1) * 4;
                    swiper?.slideTo(_slideTo, 0, true);
                    setActiveSlide(_slideTo);
                }
            }
            const conditionLast3Slides =
                applications?.items?.length - 3 === activeSlide_ ||
                applications?.items?.length - 2 === activeSlide_ ||
                applications?.items?.length - 1 === activeSlide_ ||
                applications?.items?.length === activeSlide_;
            if (conditionLast3Slides) {
                setActiveSlide(0);
                swiper?.slideTo(0, 0, true);
            }
        }
    };

    const handlePrevClick = () => {
        if (swiper) {
            const activeSlide_ = activeSlide + 1;
            if (activeSlide_ > 4) {
                const bolum = activeSlide_ / 4; //1.25 1.50 1.75 2
                const kalan = activeSlide_ % 4; //0,1,2,3

                if (kalan === 0) {
                    const slideTo_ = (bolum - 2) * 4;
                    swiper?.slideTo(slideTo_, 0, true);
                    setActiveSlide(slideTo_);
                } else {
                    const _slideTo = (parseInt(bolum.toString()) - 1) * 4;
                    swiper?.slideTo(_slideTo, 0, true);
                    setActiveSlide(_slideTo);
                }
            }

            if (activeSlide_ === 1) {
                const lastSlide = applications?.items?.length - 4;
                swiper?.slideTo(lastSlide, 0, true);
                setActiveSlide(lastSlide);
            }
        }
    };

    const handleAppClick = (appId: string, cardId: string) => {
        // setAppId(appId);
        if (cardId) {
            scrollToTop();
            dispatch(setIsChangePage(true));
        }
    };

    const handleOnMouseOver = (slideNumber: number) => {
        if (sliderTimeout !== null) {
            clearTimeout(sliderTimeout);
        }

        const timeout = setTimeout(() => {
            if (slideNumber !== activeSlide) {
                if (swiper) {
                    setActiveSlide(slideNumber);
                }
            }
        }, 700);

        setSliderTimeout(timeout);
    };

    useEffect(() => {
        return () => {
            if (sliderTimeout !== null) {
                clearTimeout(sliderTimeout);
            }
        };
    }, []);

    useEffect(() => {
        return () => {
            clearInterval(sliderInterval);
        };
    }, [activeSlide, isPlay]);

   //! slider-apps pause - start begin..

    useEffect(() => {
        const scrollElement = userId ? window : document?.getElementById('root');

        scrollElement.addEventListener('scroll', onScrolling);

        return () => {
            scrollElement.removeEventListener('scroll', onScrolling);
        };
    }, [userId]);

    const onScrolling = () => {
        const sliderAppsContainer = document.getElementById('carousel-allApplications') as HTMLElement;
        const sliderAppsContainerTop = sliderAppsContainer?.getBoundingClientRect()?.top;

        if (sliderAppsContainerTop <= 120 && sliderAppsContainerTop >= -680) {
            setIsPlay(true);
        } else if (sliderAppsContainerTop > 120 || sliderAppsContainerTop < -680) {
            setIsPlay(false);
        }
    };
    //! slider-apps pause - start end..

    useEffect(() => {
        if (isPlay) {
            sliderInterval = setInterval(() => {
                if (applications?.items?.length === activeSlide + 1) {
                    setActiveSlide(0);
                    swiper?.slideTo(0, 0, true);
                } else {
                    const bolum = (activeSlide + 1) / 4;
                    const kalan = (activeSlide + 1) % 4;
                    if (kalan === 0) {
                        const slideTo_ = bolum * 4;
                        swiper?.slideTo(slideTo_, 0, true);
                        setActiveSlide(slideTo_);
                    } else {
                        setActiveSlide(prevCount => prevCount + 1);
                    }
                }
            }, 5000);
        }
        return () => {
            clearInterval(sliderInterval);
        };
    }, [activeSlide, isPlay]);
    //! slider timer start-stop  end...

    return (
        <div className={isMacOs?"comp-carousel-03 isMacOs":"comp-carousel-03"} id="carousel-allApplications">
            <div className="c-wrapper">
                <div className="c-container">
                    <h6 className="c-item-01">{words && words['WEB.DigitalApps']}</h6>
                    <p className="c-item-02">{words && words['WEB.KocAppsTextBody']}</p>
                </div>
                <Swiper
                    lazy
                    className="allApplication-swiper"
                    id="swiper-applications"
                    modules={[Navigation, Lazy]}
                    navigation={{ nextEl: 'button-next', prevEl: 'button-prev' }}
                    loop
                    loopedSlides={applications?.items?.length}
                    slidesPerView="auto"
                    cssMode={true}
                    allowTouchMove={false}
                    onSwiper={(swiper_: any) => {
                        setSwiper(swiper_);
                    }}>
                    <div className="c-container-pagination-arrows">
                        <button className="c-item-01 button-prev" onClick={handlePrevClick}>
                            <svg width="15" height="25" viewBox="0 0 15 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14 23.75L2 12.5L14 1.25001" stroke="black" strokeWidth="2" strokeLinecap="round" />
                            </svg>
                        </button>
                        <button className="c-item-02 button-next" onClick={handleNextClick}>
                            <svg width="15" height="25" viewBox="0 0 15 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 23.75L13 12.5L1.00001 1.25001" stroke="black" strokeWidth="2" strokeLinecap="round" />
                            </svg>
                        </button>
                    </div>

                    <div className="c-container-pagination-numbers">
                        {/* --------Pagination Number----- */}
                        {applications?.items.map((item: any, index: number): any => {
                            let show = false;

                            const _activeSlide = activeSlide + 1;
                            const _index = index + 1;

                            const mode = _activeSlide % 4; //0,1,2,3

                            if (mode === 0) {
                                //4,8
                                if (
                                    _index === _activeSlide ||
                                    _index === _activeSlide - 1 ||
                                    _index === _activeSlide - 2 ||
                                    _index === _activeSlide - 3
                                ) {
                                    show = true;
                                }
                            }

                            if (mode === 1) {
                                //1,5
                                //1,2,3,4
                                if (
                                    _index === _activeSlide ||
                                    _index === _activeSlide + 1 ||
                                    _index === _activeSlide + 2 ||
                                    _index === _activeSlide + 3
                                ) {
                                    show = true;
                                }
                            }
                            if (mode === 2) {
                                //2,6

                                if (
                                    _index === _activeSlide ||
                                    _index === _activeSlide - 1 ||
                                    _index === _activeSlide + 1 ||
                                    _index === _activeSlide + 2
                                ) {
                                    show = true;
                                }
                            }
                            if (mode === 3) {
                                //3,7
                                if (
                                    _index === _activeSlide ||
                                    _index === _activeSlide - 2 ||
                                    _index === _activeSlide - 1 ||
                                    _index === _activeSlide + 1
                                ) {
                                    show = true;
                                }
                            }

                            if (applications?.items?.length === _activeSlide) {
                                if (
                                    _index === _activeSlide ||
                                    _index === _activeSlide - 1 ||
                                    _index === _activeSlide - 2 ||
                                    _index === _activeSlide - 3
                                ) {
                                    show = true;
                                }
                            }
                            if (show) {
                                const activeSlide_ = activeSlide + 1;
                                let slideTo_ = 0;
                                const bolum = activeSlide_ / 4; //0,25 0,50 0,75 1 => 0,1
                                const kalan = activeSlide_ % 4; //0,1,2,3

                                if (kalan === 0) {
                                    slideTo_ = (bolum - 1) * 4; //
                                } else {
                                    slideTo_ = parseInt(bolum.toString()) * 4;
                                }

                                return (
                                    <a
                                        className={activeSlide === index ? 'pagination-number active' : 'pagination-number'}
                                        href="#!"
                                        key={item + index}
                                        onClick={() => {
                                            if (swiper) {
                                                swiper?.slideTo(slideTo_, 0, true);
                                                setActiveSlide(index);
                                            }
                                        }}>
                                        {index + 1}
                                    </a>
                                );
                            }
                        })}
                    </div>
                    {applications?.items?.length > 0 &&
                        applications?.items?.map((application: any, index: number) => (
                            <SwiperSlide
                                key={'SliderApps-' + index}
                                onMouseOver={() => handleOnMouseOver(index)}>
                                <Link
                                    onClick={() => handleAppClick(application?.id, application?.cardId)}
                                    to={{
                                        pathname:
                                            application?.cardId?.length > 0 ? `/${words && words['WEB.cardDetails']}/${application?.cardId}` : '',
                                    }}
                                    className={activeSlide === index ? 'c-container-01 active' : 'c-container-01'}>
                                    {application?.imageUrl.endsWith('.svg') ||
                                    application?.imageUrl.endsWith('.png') ||
                                    application?.imageUrl.endsWith('.jpeg') ||
                                    application?.imageUrl.endsWith('.jpg') ? (
                                        <>
                                            <img
                                                loading="lazy"
                                                className={activeSlide === index ? 'c-item-01 filter' : 'c-item-01'}
                                                src={application?.imageUrl}
                                            />
                                        </>
                                    ) : (
                                        <video className="c-item-01" controls={false} autoPlay={false}>
                                            <source src={application?.imageUrl} type="video/mp4" />
                                        </video>
                                    )}
                                    <div className="c-item-02">
                                        <h6 className="c-unit-01">
                                            {
                                                activeSlide === index && application?.header
                                            }
                                        </h6>
                                        <h6 className="c-unit-02">{activeSlide === index && application?.digitalPlatformBody}</h6>
                                    </div>

                                    <div className="c-item-03">
                                        {activeSlide === index && <img loading="lazy" className="c-unit-01" src={application?.iconUrl2} />}
                                    </div>
                                </Link>
                            </SwiperSlide>
                        ))}
                </Swiper>
            </div>
        </div>
    );
};

export default SliderApps;
