import { FormControl, FormControlLabel, FormGroup } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import React, { FC } from 'react';
import { useDispatch } from 'react-redux';

import { ICookieAccordion } from '../../models/components/cookie-accordion';
import { useAppSelector } from '../../store';
import { setCookieOptions } from '../../store/features/user/usersSlice';
import IOSSwitch from '../ios-button';

const CookieAccordion: FC<ICookieAccordion> = ({ cookiesData, index, handleExpanded, expanded, createCookiesNameValue }) => {
    const dispatch = useDispatch();
    const { cookiesOptions } = useAppSelector(state => state.users);

    const handleCookieOptions = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(setCookieOptions({ ...cookiesOptions, [event.target.name]: event.target.checked }));
        if (event.target.name === 'googleAnalist') {
            createCookiesNameValue('googleAnalistAccepted', event.target.checked);
        }
        if (event.target.name === 'googleAdsense') {
            createCookiesNameValue('googleAdsenseAccepted', event.target.checked);
        }
        if (event.target.name === 'facebookPixel') {
            createCookiesNameValue('facebookPixelAccepted', event.target.checked);
        }
    };
    const handleSelectGroupCookies = (event: React.ChangeEvent<HTMLInputElement>, displayOrder: number) => {
        //! googleAnalistc
        if (displayOrder === 3) {
            createCookiesNameValue('googleAnalistAccepted', event.target.checked);
            dispatch(setCookieOptions({ ...cookiesOptions, googleAnalist: event.target.checked }));
        }
        //! googleAdsense and facebookPixel
        if (displayOrder === 4) {
            createCookiesNameValue('googleAdsenseAccepted', event.target.checked);
            createCookiesNameValue('facebookPixelAccepted', event.target.checked);
            dispatch(setCookieOptions({ ...cookiesOptions, googleAdsense: event.target.checked, facebookPixel: event.target.checked }));
        }
    };

    return (
        <Accordion className="accordion-01" expanded={expanded === 'panel-' + (index + 1)} onChange={handleExpanded('panel-' + (index + 1))}>
            <AccordionSummary
                className="accordion-01-summary"
                IconButtonProps={{ onClick: (event) => event.stopPropagation() }}
                expandIcon={
                    <FormControlLabel
                        label=""
                        control={
                            <IOSSwitch
                                className={cookiesData?.isMandatory ? 'button-05 f-button-ios-disabled' : 'button-05'}
                                isMandatory={cookiesData?.isMandatory}
                                cookieData={cookiesData}
                                location="on"
                                onChange={e => handleSelectGroupCookies(e, cookiesData?.displayOrder)}
                                name={cookiesData?.name}
                            />
                        }
                    />
                }>
                <span className="c-item-01">{expanded === 'panel-' + index + 1 ? <RemoveIcon color="error" /> : <AddIcon />} </span>
                <span className="c-item-02"> {cookiesData?.title}</span>
            </AccordionSummary>

            <AccordionDetails className="accordion-01-details">
                <p className="c-item-01">{cookiesData?.description}</p>
                <FormControl component="fieldset" className="c-item-02">
                    {cookiesData?.privacyPreferenceDetailDescriptionList?.map((cookie, index) => {
                        return (
                            <FormGroup className="form-group" key={index}>
                                <FormControlLabel
                                    className="form-control-label"
                                    label={cookie?.title}
                                    control={
                                        <IOSSwitch
                                            className="button-05"
                                            onChange={handleCookieOptions}
                                            location="in"
                                            cookie={cookie}
                                            name={cookie?.name}
                                        />
                                    }
                                />
                                <p className="c-unit-01">{cookie?.description}</p>
                            </FormGroup>
                        );
                    })}
                </FormControl>
            </AccordionDetails>
        </Accordion>
    );
};

export default CookieAccordion;
