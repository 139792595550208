import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { get } from '../../../core/client';
import { IResultOptions } from './model';

export const getAllHobyOptions: any = createAsyncThunk('profile/getAllHobyOptions', async () => {
    const allHobyOptions = await get<any>('joy/get-joy-type-list');
    return allHobyOptions;
});
export const getAllCityOptions: any = createAsyncThunk('profile/getAllCityOptions', async () => {
    const allCityOptions = await get<any>('Location/get-city-list');
    return allCityOptions;
});
export const getAllUserPetOptions: any = createAsyncThunk('profile/getAllUserPetOptions', async () => {
    const allUserPetOptions = await get<any>('pet-bread/list');
    return allUserPetOptions;
});
export const getAllCelebrationOptions: any = createAsyncThunk('profile/getAllCelebrationOptions', async () => {
    const allCelebrationOptions = await get<any>('/specialdate/get-specialdate-list-by-celebrates');
    return allCelebrationOptions;
});
export const getAllCelebrateWhomOptions: any = createAsyncThunk('profile/getAllCelebrateWhomOptions', async () => {
    const allCelebrateWhomOptions = await get<any>('/users/get-celebrates-whom-for-user-list');
    return allCelebrateWhomOptions;
});
export const getBasketballTeamsOptions: any = createAsyncThunk('profile/getBasketballTeamsOptions', async () => {
    const basketballTeamsOptions = await get<any>('/sport/get-sport-team-by-type-id?type=1');
    return basketballTeamsOptions;
});
export const getVoleyballTeamsOptions: any = createAsyncThunk('profile/getVoleyballTeamsOptions', async () => {
    const voleyballTeamsOptions = await get<any>('/sport/get-sport-team-by-type-id?type=2');
    return voleyballTeamsOptions;
});
export const getFootballTeamsOptions: any = createAsyncThunk('profile/getFootballTeamsOptions', async () => {
    const footballTeamsOptions = await get<any>('/sport/get-sport-team-by-type-id?type=3');
    return footballTeamsOptions;
});
export const getOtherTeamsOptions: any = createAsyncThunk('profile/getOtherTeamsOptions', async () => {
    const otherTeamsOptions = await get<any>('/sport/get-sport-team-by-type-id?type=4');
    return otherTeamsOptions;
});

export const getProfileUserDataById: any = createAsyncThunk('profile/getProfileUser', async (id: any) => {
    const profileUserDataById = await get<any>('users/user-profile-info-by-userId?userId=' + id);
    return profileUserDataById;
});

const profileSlice = createSlice({
    name: 'profile',
    initialState: {
        hobbyOptions: {
            loading: true,
            items: [],
        },
        cityOptions: {
            loading: true,
            items: [],
        },
        userPetOptions: {
            loading: true,
            items: [],
        },
        celebrationOptions: {
            loading: true,
            items: [],
        },
        celebrateWhomOptions: {
            loading: true,
            items: [],
        },
        basketballTeams: {
            loading: true,
            items: [],
        },
        voleyballTeams: {
            loading: true,
            items: [],
        },
        footballTeams: {
            loading: true,
            items: [],
        },
        otherTeams: {
            loading: true,
            items: [],
        },
        profileData: {
            joy: [],
            teams: [],
            celebrate: [],
            address: null,
        },
        profileUserData: {
            profileUserInfo: null,
            fullnessRatio: null,
            loading: true,
        },
    },
    reducers: {
        setProfileData(state, action) {
            state.profileData = action.payload;
        },
        setFullnessRatio(state, action) {
            state.profileUserData.fullnessRatio = action.payload;
        },
    },
    extraReducers: builder => {
        builder.addCase(getAllHobyOptions.fulfilled, (state, action: PayloadAction<IResultOptions>) => {
            state.hobbyOptions.items = action.payload.isSuccessful ? action.payload.result.items : [];
            state.hobbyOptions.loading = false;
        });
        builder.addCase(getAllCityOptions.fulfilled, (state, action: PayloadAction<IResultOptions>) => {
            state.cityOptions.items = action.payload.isSuccessful ? action.payload.result.items : [];
            state.cityOptions.loading = false;
        });
        builder.addCase(getAllUserPetOptions.fulfilled, (state, action: PayloadAction<IResultOptions>) => {
            state.userPetOptions.items = action.payload.isSuccessful ? action.payload.result.items : [];
            state.userPetOptions.loading = false;
        });
        builder.addCase(getAllCelebrationOptions.fulfilled, (state, action: PayloadAction<IResultOptions>) => {
            state.celebrationOptions.items = action.payload.isSuccessful ? action.payload.result.items : [];
            state.celebrationOptions.loading = false;
        });
        builder.addCase(getAllCelebrateWhomOptions.fulfilled, (state, action: PayloadAction<IResultOptions>) => {
            state.celebrateWhomOptions.items = action.payload.isSuccessful ? action.payload.result.items : [];
            state.celebrateWhomOptions.loading = false;
        });
        builder.addCase(getBasketballTeamsOptions.fulfilled, (state, action: PayloadAction<IResultOptions>) => {
            state.basketballTeams.items = action.payload.isSuccessful ? action.payload.result.items : [];
            state.basketballTeams.loading = false;
        });
        builder.addCase(getVoleyballTeamsOptions.fulfilled, (state, action: PayloadAction<IResultOptions>) => {
            state.voleyballTeams.items = action.payload.isSuccessful ? action.payload.result.items : [];
            state.voleyballTeams.loading = false;
        });
        builder.addCase(getFootballTeamsOptions.fulfilled, (state, action: PayloadAction<IResultOptions>) => {
            state.footballTeams.items = action.payload.isSuccessful ? action.payload.result.items : [];
            state.footballTeams.loading = false;
        });
        builder.addCase(getOtherTeamsOptions.fulfilled, (state, action: PayloadAction<IResultOptions>) => {
            state.otherTeams.items = action.payload.isSuccessful ? action.payload.result.items : [];
            state.otherTeams.loading = false;
        });
        builder.addCase(getProfileUserDataById.fulfilled, (state, action: PayloadAction<IResultOptions>) => {
            state.profileUserData.profileUserInfo = action.payload.isSuccessful ? action.payload.result : [];
            state.profileUserData.fullnessRatio = action.payload.isSuccessful ? action.payload.result.fullnessRatio : null;
            state.profileUserData.loading = false;
        });
    },
});

export default profileSlice.reducer;
export const { setProfileData, setFullnessRatio } = profileSlice.actions;
