interface IAdress {
    id?: string;
    cityId?: string;
    cityName?: string;
    countyId?: string;
    county?: string;
    address?: string;
}

const initialStateAddress = {
    id: null,
    cityId: null,
    countyId: null,
    address: '',
    cityName: '',
    county: '',
};

export type { IAdress };

export { initialStateAddress };
