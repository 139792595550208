import { Button, Form, jwtTokenParse, toastMessage } from '@kocsistem/oneframe-react-bundle';
import { get } from 'lodash';
import React, { useRef, useState, useEffect } from 'react';
// import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';

import { getUserRoleClaims } from '../../_actions/action-users';
import { getRequest, postRequest } from '../../_helpers/fetch-wrapper';
import {
    /*get as getAxiosRequest,*/
    post,
} from '../../core/client';
import {
    LOCAL_STORAGE,
    /*, MESSAGE_TYPE */
} from '../../core/constants';
import { CONFIGURATIONS_SETTINGS, ACCOUNT_SETTINGS } from '../../core/constants/apiEndpoints';
import {
    setILocalStorageItem,
    getAuthApiBaseUrl,
    getCookieValue,
    getQueryParameter,
    isAuthenticated,
    setCookie,
    setLocalStorageItem,
} from '../../core/utility';
import { LoginResponse } from '../../models/users/login-response';

require('es6-promise').polyfill();


const Logout = ({ location, history }: any) => {
    const is2FaEnabled = localStorage.getItem(LOCAL_STORAGE.TWOFA_VERIFICATION_TYPE);
    const { t } = useTranslation();
    const ref = useRef<any>();
    const urlBase = () => getAuthApiBaseUrl();

    const [pageReady, setPageReady] = useState(true);
    const fromUrl = '/';
    const [showPage, setShowPage] = useState<boolean>(false);
    const [state] = useState({
        email: '',
        password: '',
        rememberMe: false,
    });

    useEffect(() => {
        async function handleTokens() {
            const ssoCallBackResult = await ssoCallBackHandler();
            if (ssoCallBackResult) {
                return;
            }

            const portalTokenResult = await portalTokenHandler();

            if (portalTokenResult) {
                return;
            }

            setShowPage(true);
        }

        handleTokens();
    }, []);

    const prepareToken = async tokenResult => {
        const sanitizedBase64 = tokenResult.token.split('.')[1].replaceAll('-', '+').replaceAll('_', '/');
        const splittedToken = tokenResult.token.split('.');
        const sanitizedToken = splittedToken[0] + '.' + sanitizedBase64 + '.' + splittedToken[2];

        const token: any = jwtTokenParse(sanitizedToken);

        setLocalStorageItem(LOCAL_STORAGE.ACCESS_TOKEN, tokenResult.token);
        setLocalStorageItem(LOCAL_STORAGE.USER_EMAIL, tokenResult.claims[0].name);
        setLocalStorageItem(LOCAL_STORAGE.USER_ID, tokenResult.claims[8].name);
        setLocalStorageItem(LOCAL_STORAGE.EXPIRED_TIME, token.exp);
        setLocalStorageItem(LOCAL_STORAGE.REFRESH_TOKEN, tokenResult.refreshToken);
        setLocalStorageItem(LOCAL_STORAGE.USER_FULLNAME, tokenResult.claims[3].name);
    };

    const portalTokenHandler = async (): Promise<boolean> => {
        const encryptedToken = getCookieValue('EncryptedToken');

        if (isAuthenticated() || !encryptedToken) {
            return false;
        }

        const urlSuffix = '/accounts/handleCrossSiteToken/?encryptedToken=';
        const url = String(urlBase() + urlSuffix);
        const urlToken = url + encryptedToken;

        if (encryptedToken) {
            await postRequest(urlToken).then(async (data: any) => {
                if (data?.result?.token) {
                    prepareToken(data.result);
                    await prepareUserRoleClaims();
                    setTimeout(() => {
                        window.location.href = fromUrl;
                    }, 500);
                } else {
                    console.error('handleCrossSiteToken', data.error.message);
                    setShowPage(true);
                }
            });
        }

        return true;
    };

    const prepareUserRoleClaims = async () => {
        const userClaims: Array<string> = await getUserRoleClaims();

        setLocalStorageItem(LOCAL_STORAGE.USER_ROLE_CLAIMS, userClaims);
    };

    const ssoCallBackHandler = async (): Promise<boolean> => {
        const ssoToken = getQueryParameter('SsoToken');
        //dışardan geldikten sonra

        if (!ssoToken) {
            return false;
        }

        const isRememberMeActive = getQueryParameter('isRememberMeActive');
        const urlSuffix = '/accounts/ssoCallBack';
        const url = String(urlBase() + urlSuffix + '/?ssoToken=' + ssoToken + '&isRememberMeActive=' + (isRememberMeActive || false));
        const ssoTokenCallback: Response = await getRequest(url);
        const response: any = await ssoTokenCallback;

        if (response?.result?.token) {
            await prepareToken(response.result);

            setCookie('EncryptedPanelToken', response.result.encryptedToken, 1, process.env.ENCRYPTEDTOKEN_DOMAIN);

            setLocalStorageItem(LOCAL_STORAGE.Encrypted_Panel_Token, response.result.encryptedToken);

            await prepareUserRoleClaims();

            setTimeout(() => {
                window.location.href = fromUrl;
            }, 500);
        } else {
            toastMessage({
                messageType: 'error',
                title: response.error.code,
                message: response.error.message,
                position: 'center',
                overlay: true,
                icon: 'fad fa-exclamation-square',
            });

            setTimeout(() => {
                // window.location.href = "/accounts/login";
                window.location.href = "/";
            }, 1500);

        }

        return true;
    };

    setILocalStorageItem(LOCAL_STORAGE.REFRESH_TOKEN, null);

    const postLogin = async (fromUrl: string) => {
        const response = await post<LoginResponse>(`${ACCOUNT_SETTINGS.LOGIN}`, { ...state }, !is2FaEnabled && t('shared.login-success'));
        if (response && response.isSuccessful) {
            const token: any = jwtTokenParse(response.result.token);
            setILocalStorageItem(LOCAL_STORAGE.ACCESS_TOKEN, response.result.token);
            setILocalStorageItem(LOCAL_STORAGE.REFRESH_TOKEN, response.result.refreshToken);
            setILocalStorageItem(LOCAL_STORAGE.USER_FULLNAME, token.given_name + ' ' + token.family_name);
            setILocalStorageItem(LOCAL_STORAGE.USER_EMAIL, token.unique_name);
            setILocalStorageItem(LOCAL_STORAGE.EXPIRED_TIME, token.exp);
            if (is2FaEnabled) {
                history.push('/accounts/login-2fa');
            } else {
                history.push(fromUrl);
            }
        }
    };

    // captcha token getter coment out for now
    // const captchaObject = useRef<HTMLElement | any>(null);

    const handleSubmit = async () => {
        setPageReady(false);
        let fromUrl = get(location, 'state.from') || '/';
        if (is2FaEnabled) {
            fromUrl = `/accounts/login-2fa`;
        }

        // captcha token getter coment out for now
        // const token = await captchaObject.current.executeAsync();
        // const res = await getAxiosRequest(`${ACCOUNT_SETTINGS.IS_CAPTCHA_PASSED}${token}`);

        await postLogin(fromUrl).finally(() => {
            setPageReady(true);
        });

        // captcha token getter coment out for now
        // if (res.result) {
        //     await postLogin(fromUrl).finally(() => {
        //         setPageReady(true);
        //     });
        // } else {
        //     toastMessage({
        //         messageType: MESSAGE_TYPE.ERROR,
        //         title: 'Captcha',
        //         message: t('shared.captcha-validation'),
        //         position: 'center',
        //         overlay: true,
        //         icon: 'flaticon-alert',
        //     });
        // }
    };

    const signInViaSso = async () => {
        const urlSuffix = '/accounts/ssoCatiSignIn';
        // const token = await captchaObject.current.executeAsync();
        const url = String(
            urlBase() + urlSuffix + '/?email=' + state.email + '&isRememberMeActive=' + state.rememberMe,
        ); /*+ "&captchaToken=" + token*/

        await getRequest(url).then((data: any) => {
            if (data?.result?.redirectUrl) {
                //dışarıya gidiyor
                window.location.href = data.result.redirectUrl;
            } else {
                toastMessage({
                    messageType: 'error',
                    message: data?.result?.error,
                    position: 'center',
                    overlay: true,
                    icon: 'fad fa-exclamation-square',
                });
            }

            setPageReady(false);
        });
    };

    const SSOLoginClickHandler = async () => {
        setPageReady(false);
        signInViaSso();
    };


    useEffect(() => {
        twoFaSettings();
    }, []);

    const twoFaSettings = async () => {
        const isEnabledStorage = localStorage.getItem(LOCAL_STORAGE.TWOFA_IS_ENABLED);
        const verificationTypeStorage = localStorage.getItem(LOCAL_STORAGE.TWOFA_VERIFICATION_TYPE);
        const verificationTimeStorage = localStorage.getItem(LOCAL_STORAGE.TWOFA_VERIFICATION_TIME);
        const authenticatorLinkNameStorage = localStorage.getItem(LOCAL_STORAGE.TWOFA_AUTHENTICATOR_LINK_NAME);

        if (
            valueNullControl(isEnabledStorage) ||
            valueNullControl(verificationTypeStorage) ||
            valueNullControl(verificationTimeStorage) ||
            valueNullControl(authenticatorLinkNameStorage)
        ) {
            const endpoint = `${CONFIGURATIONS_SETTINGS.REACT}`;
            const response = await post<any>(endpoint, [
                LOCAL_STORAGE.TWOFA_IS_ENABLED,
                LOCAL_STORAGE.TWOFA_VERIFICATION_TYPE,
                LOCAL_STORAGE.TWOFA_VERIFICATION_TIME,
                LOCAL_STORAGE.TWOFA_AUTHENTICATOR_LINK_NAME,
            ]);

            if (response && response.isSuccessful) {
                const is2FaEnabled = response.result[LOCAL_STORAGE.TWOFA_IS_ENABLED];
                localStorage.setItem(LOCAL_STORAGE.TWOFA_IS_ENABLED, response.result[LOCAL_STORAGE.TWOFA_IS_ENABLED]);

                if (is2FaEnabled) {
                    localStorage.setItem(LOCAL_STORAGE.TWOFA_VERIFICATION_TYPE, response.result[LOCAL_STORAGE.TWOFA_VERIFICATION_TYPE]);
                    localStorage.setItem(LOCAL_STORAGE.TWOFA_VERIFICATION_TIME, response.result[LOCAL_STORAGE.TWOFA_VERIFICATION_TIME]);
                    localStorage.setItem(LOCAL_STORAGE.TWOFA_AUTHENTICATOR_LINK_NAME, response.result[LOCAL_STORAGE.TWOFA_AUTHENTICATOR_LINK_NAME]);
                }
            }
        }
    };

    const valueNullControl = value => {
        if (value === null || value === '' || value === 'undefined') {
            return true;
        } else {
            return false;
        }
    };

    return (
        <>
            <div className="header-01">
                <div className="c-wrapper">
                    <a href="/accounts/login" className="c-item-01">
                        <img src='https://khicindekocvar.blob.core.windows.net/cdn/prod/footer-koc-logo.png' />
                    </a>
                </div>
            </div>
            <div className={`logout-container ${showPage ? '' : 'd-none'}`} ref={ref}>
                <div className='c-wrapper'>
                    <p className='c-item-01'>
                        Güvenli şekilde çıkış yaptın
                    </p>
                    <p className='c-item-02'>
                        Dilersen tekrar giriş yapabilirsin.
                    </p>
                    <div className="login-form c-item-03">
                        <Form onSubmit={() => handleSubmit()}>
                            {/* <ReCAPTCHA ref={captchaObject} size="invisible" sitekey="6LdHtXgeAAAAAJS2cisnoR0n3952AzjjtwU0zTWt" /> */}

                            <div className="form-field mb-4">
                                <Button
                                    disabled={!pageReady}
                                    loading={!pageReady}
                                    fullWidth
                                    className="custom-button-blue-50"
                                    text="Giriş Yap"
                                    onClick={SSOLoginClickHandler}
                                    iconRight={() => <i className="fad fa-arrow-right ml-2" />}
                                />
                            </div>
                        </Form>
                    </div>
                </div>

            </div>
        </>

    );
};

export default Logout;
