import React, { useState } from 'react';
import { isMacOs } from 'react-device-detect';
import { Link } from 'react-router-dom';

import { scrollToTop, userId } from '../../../_helpers/useful-functions';
import { useAppSelector } from '../../../store';
import ProgressBaar from '../../progressBar';
import MailModal from './mailModal';
import ModalDialog from './modal';

const Header = (props: any) => {
    const [showModal, setShowModal] = useState<boolean>(false);
    const [mailShowModal, setMailShowModal] = useState<boolean>(false);
    const { words } = useAppSelector(state => state.localization);
    const { profileUserInfo, fullnessRatio }: any = useAppSelector(state => state.profile.profileUserData);

    return (
        <div className={props.width < 768 ? 'header-02 show' : isMacOs ? 'header-02 isMacOs' : 'header-02'}>
            <div className="c-container-03">
                <img
                    loading="lazy"
                    className="c-item-01"
                    src="https://khicindekocvar.blob.core.windows.net/cdn/prod/icindeKocvar-background.png"
                    alt="koclogo"
                />
                <img
                    loading="lazy"
                    className="c-item-02"
                    src="https://khicindekocvar.blob.core.windows.net/cdn/prod/tablet_438619727-1200x200.png"
                    alt="koclogo"
                />
            </div>
            <div className="c-container-01">
                <div className="c-wrapper">
                    <Link to="/" onClick={() => scrollToTop} className="c-item-01">
                        <img loading="lazy" src="https://khicindekocvar.blob.core.windows.net/cdn/prod/footer-koc-logo.png" />
                    </Link>

                    {profileUserInfo && (
                        <div className="c-item-03">
                            <div className="c-item-03-A">
                                <h6 className="c-unit-01"> {profileUserInfo?.name + ' ' + profileUserInfo?.surname} </h6>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className="c-container-02">
                {profileUserInfo && (
                    <div className="c-wrapper">
                        <ModalDialog open={showModal} setOpen={setShowModal} />
                        {userId && (
                            <a onClick={() => setShowModal(true)} className="c-item-icon">
                                <svg width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M4 4H7L9 2H15L17 4H20C20.5304 4 21.0391 4.21071 21.4142 4.58579C21.7893 4.96086 22 5.46957 22 6V18C22 18.5304 21.7893 19.0391 21.4142 19.4142C21.0391 19.7893 20.5304 20 20 20H4C3.46957 20 2.96086 19.7893 2.58579 19.4142C2.21071 19.0391 2 18.5304 2 18V6C2 5.46957 2.21071 4.96086 2.58579 4.58579C2.96086 4.21071 3.46957 4 4 4ZM12 7C10.6739 7 9.40215 7.52678 8.46447 8.46447C7.52678 9.40215 7 10.6739 7 12C7 13.3261 7.52678 14.5979 8.46447 15.5355C9.40215 16.4732 10.6739 17 12 17C13.3261 17 14.5979 16.4732 15.5355 15.5355C16.4732 14.5979 17 13.3261 17 12C17 10.6739 16.4732 9.40215 15.5355 8.46447C14.5979 7.52678 13.3261 7 12 7ZM12 9C12.7956 9 13.5587 9.31607 14.1213 9.87868C14.6839 10.4413 15 11.2044 15 12C15 12.7956 14.6839 13.5587 14.1213 14.1213C13.5587 14.6839 12.7956 15 12 15C11.2044 15 10.4413 14.6839 9.87868 14.1213C9.31607 13.5587 9 12.7956 9 12C9 11.2044 9.31607 10.4413 9.87868 9.87868C10.4413 9.31607 11.2044 9 12 9Z"
                                        fill="#2e333e"
                                    />
                                </svg>
                            </a>
                        )}

                        <img
                            loading="lazy"
                            className="c-item-01"
                            src={
                                profileUserInfo?.photo ? profileUserInfo.photo : 'https://khicindekocvar.blob.core.windows.net/prod/koc_icon_user.svg'
                            }
                        />
                        <div className="c-item-02">
                            <div className="c-item-02-A">
                                <div className="c-item-02-A-01">
                                    <div className="c-unit-01">
                                        <svg width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M24.5264 9.81641C24.7168 9.66504 25 9.80664 25 10.0459V20.0312C25 21.3252 23.9502 22.375 22.6562 22.375H2.34375C1.0498 22.375 0 21.3252 0 20.0312V10.0508C0 9.80664 0.27832 9.66992 0.473633 9.82129C1.56738 10.6709 3.01758 11.75 7.99805 15.3682C9.02832 16.1201 10.7666 17.7021 12.5 17.6924C14.2432 17.707 16.0156 16.0908 17.0068 15.3682C21.9873 11.75 23.4326 10.666 24.5264 9.81641ZM12.5 16.125C13.6328 16.1445 15.2637 14.6992 16.084 14.1035C22.5635 9.40137 23.0566 8.99121 24.5508 7.81934C24.834 7.59961 25 7.25781 25 6.89648V5.96875C25 4.6748 23.9502 3.625 22.6562 3.625H2.34375C1.0498 3.625 0 4.6748 0 5.96875V6.89648C0 7.25781 0.166016 7.59473 0.449219 7.81934C1.94336 8.98633 2.43652 9.40137 8.91602 14.1035C9.73633 14.6992 11.3672 16.1445 12.5 16.125Z"
                                                fill="#2e333e"
                                            />
                                        </svg>
                                        <p>{profileUserInfo?.email ? profileUserInfo?.email : '-'}</p>
                                    </div>
                                    <div className="c-unit-01">
                                        <svg width="17" height="26" viewBox="0 0 17 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M13.9688 0.5H3.03125C1.7373 0.5 0.6875 1.5498 0.6875 2.84375V23.1562C0.6875 24.4502 1.7373 25.5 3.03125 25.5H13.9688C15.2627 25.5 16.3125 24.4502 16.3125 23.1562V2.84375C16.3125 1.5498 15.2627 0.5 13.9688 0.5ZM8.5 23.9375C7.63574 23.9375 6.9375 23.2393 6.9375 22.375C6.9375 21.5107 7.63574 20.8125 8.5 20.8125C9.36426 20.8125 10.0625 21.5107 10.0625 22.375C10.0625 23.2393 9.36426 23.9375 8.5 23.9375ZM13.9688 18.6641C13.9688 18.9863 13.7051 19.25 13.3828 19.25H3.61719C3.29492 19.25 3.03125 18.9863 3.03125 18.6641V3.42969C3.03125 3.10742 3.29492 2.84375 3.61719 2.84375H13.3828C13.7051 2.84375 13.9688 3.10742 13.9688 3.42969V18.6641Z"
                                                fill="#2e333e"
                                            />
                                        </svg>
                                        <p>{profileUserInfo.mobilePhoneNumber ? profileUserInfo.mobilePhoneNumber : '-'}</p>
                                    </div>
                                </div>
                                <div className="c-item-02-A-02">
                                    <div className="c-unit-01">
                                        <div className="svg-div-1">
                                            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M15 5C13.9062 5 13 5.90625 13 7V8H4V26H28V8H19V7C19 5.90625 18.0938 5 17 5H15ZM15 7H17V9H15V7ZM6 10H13V11H19V10H26V24H6V10ZM16 12C13.8008 12 12 13.8008 12 16C12 17.1172 12.4687 18.1289 13.2148 18.8555C11.8789 19.7578 11 21.2812 11 23H13C13 21.332 14.332 20 16 20C17.668 20 19 21.332 19 23H21C21 21.2812 20.1211 19.7578 18.7852 18.8555C19.5313 18.1289 20 17.1172 20 16C20 13.8008 18.1992 12 16 12ZM16 14C17.1172 14 18 14.8828 18 16C18 17.1172 17.1172 18 16 18C14.8828 18 14 17.1172 14 16C14 14.8828 14.8828 14 16 14Z"
                                                    fill="#2e333e"
                                                />
                                            </svg>
                                        </div>
                                        <div className="c-unit-01-A">
                                            <span className="c-micro-content-01"> {profileUserInfo?.position ? profileUserInfo?.position : '-'}</span>
                                            <span className="c-micro-content-02">
                                                {' '}
                                                {' / '} {profileUserInfo?.departmentName ? profileUserInfo?.departmentName : '-'}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="c-unit-01">
                                        <div className="svg-div-2">
                                            <svg width="23" height="26" viewBox="0 0 23 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M22.4375 19.25C21.0693 19.25 20.9111 17.6875 18.7998 17.6875C16.6792 17.6875 16.5134 19.25 15.1499 19.25C13.7976 19.25 13.6141 17.6875 11.5 17.6875C9.40811 17.6875 9.19443 19.25 7.8623 19.25C6.48789 19.25 6.33877 17.6875 4.2124 17.6875C2.08608 17.6875 1.93442 19.25 0.5625 19.25V15.3438C0.5625 14.0498 1.6123 13 2.90625 13H3.6875V5.96875H6.8125V13H9.9375V5.96875H13.0625V13H16.1875V5.96875H19.3125V13H20.0938C21.3877 13 22.4375 14.0498 22.4375 15.3438V19.25ZM22.4375 25.5H0.5625V20.8125C2.67949 20.8125 2.84605 19.25 4.2124 19.25C5.5772 19.25 5.73843 20.8125 7.8623 20.8125C9.95425 20.8125 10.1678 19.25 11.5 19.25C12.8744 19.25 13.0235 20.8125 15.1499 20.8125C17.2669 20.8125 17.4334 19.25 18.7998 19.25C20.142 19.25 20.3258 20.8125 22.4375 20.8125V25.5ZM5.25 5.1875C4.3833 5.1875 3.6875 4.4917 3.6875 3.625C3.6875 2.11133 5.25 2.50195 5.25 0.5C5.83594 0.5 6.8125 1.94043 6.8125 3.23438C6.8125 4.52832 6.1167 5.1875 5.25 5.1875ZM11.5 5.1875C10.6333 5.1875 9.9375 4.4917 9.9375 3.625C9.9375 2.11133 11.5 2.50195 11.5 0.5C12.0859 0.5 13.0625 1.94043 13.0625 3.23438C13.0625 4.52832 12.3667 5.1875 11.5 5.1875ZM17.75 5.1875C16.8833 5.1875 16.1875 4.4917 16.1875 3.625C16.1875 2.11133 17.75 2.50195 17.75 0.5C18.3359 0.5 19.3125 1.94043 19.3125 3.23438C19.3125 4.52832 18.6167 5.1875 17.75 5.1875Z"
                                                    fill="#2e333e"
                                                />
                                            </svg>
                                        </div>

                                        <p>{profileUserInfo?.birthDate ? new Date(profileUserInfo?.birthDate).toLocaleDateString('tr-TR') : '-'}</p>
                                    </div>
                                </div>
                            </div>
                            <ProgressBaar percent={fullnessRatio} />
                        </div>
                        <MailModal
                            userId={userId}
                            message={`${profileUserInfo?.name} ${profileUserInfo?.surname} ${words && words['WEB.sendMailToApprove']}`}
                            open={mailShowModal}
                            setOpen={setMailShowModal}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default Header;
